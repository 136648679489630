import { render } from "./Glossary.vue?vue&type=template&id=6a24478a"
import script from "./Glossary.vue?vue&type=script&lang=js"
export * from "./Glossary.vue?vue&type=script&lang=js"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QSeparator,QBanner});
