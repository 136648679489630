<template>
  <div class="q-mb-lg">
    <q-banner v-if="error" class="text-white bg-red q-mb-md" rounded>
      {{ error.message }}
    </q-banner>

    <q-table
      v-model:pagination="pagination"
      :columns="tableColumns"
      :rows="appointments"
      :rows-per-page-options="[5, 10, 20, 50]"
      :loading="isLoading"
      :loading-label="$t('components.patientAppointments.loading')"
      :no-data-label="$t('components.patientAppointments.noItems')"
      row-key="id"
      flat
      @request="onRequest"
    >
      <template v-slot:top>
        <div class="col">
          <h6 class="q-ma-none">
            {{ $t('components.patientAppointments.title') }}
          </h6>
          {{ $t('components.patientAppointments.description') }}
        </div>
        <div class="col text-right">
          <template v-for="button of buttons" :key="button">
            <q-btn
              :color="button.color"
              :label="button.label"
              :outline="button.isOutline"
              :to="button.to"
              class="q-mb-md q-ml-sm"
              @click="button.onClick"
            />
          </template>
        </div>
      </template>

      <template v-slot:body-cell-actions="props">
        <q-td :props="props" @click.stop="">
          <q-btn icon="more_vert" flat round size="sm">
            <q-menu>
              <q-list style="min-width: 150px">
                <q-item
                  v-if="$isAllowed('doctor/appointments/update')"
                  clickable
                  v-close-popup
                  @click="openModalForEditingAppointment(props.row)"
                >
                  <q-item-section>
                    {{ $t('pages.appointments.edit') }}
                  </q-item-section>
                </q-item>

                <q-item
                  v-if="
                    $isAllowed('doctor/appointments/delete') &&
                    patient.folder.accessLevel === 'owner'
                  "
                  clickable
                  v-close-popup
                  @click="confirmAppointmentDeletion(props.row)"
                >
                  <q-item-section>
                    {{ $t('pages.appointments.delete') }}
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-td>
      </template>
    </q-table>

    <appointment-modal
      ref="appointment-modal"
      :patient-id="+$route.params.patientId"
      @appointment-added="onAppointmentAdded"
      @appointment-updated="onAppointmentUpdated"
    />
  </div>
</template>

<script>
import {
  getAppointmentsAndCount,
  deleteAppointmentById,
} from '@/api/appointments';
import AppointmentModal from '@/components/AppointmentModal.vue';

export default {
  components: {
    AppointmentModal,
  },
  props: {
    patient: {
      type: Object,
      reqyured: true,
    },
    patientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      pagination: {
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0,
      },
      appointments: [],
      isLoading: false,
    };
  },
  computed: {
    tableColumns() {
      const columns = [
        {
          name: 'appointedFor',
          field: 'appointedFor',
          format: (value) => this.$dayjs(value).format('L LT'),
          label: this.$t(
            'components.patientAppointments.tableColumns.appointedFor'
          ),
          align: 'left',
        },
      ];

      if (this.patient && this.patient.folder.accessLevel !== 'read-only') {
        columns.push({
          name: 'actions',
          label: this.$t('components.patientAppointments.tableColumns.actions'),
          align: 'right',
        });
      }

      return columns;
    },
    buttons() {
      if (this.patient && this.patient.folder.accessLevel !== 'read-only') {
        return [
          {
            isAllowed: true,
            color: 'primary',
            label: this.$t('components.patientAppointments.addButtonTitle'),
            isOutline: true,
            onClick: this.openModalForAddingAppointment,
          },
        ];
      }

      return [];
    },
  },
  created() {
    this.onRequest({ pagination: this.pagination });
  },
  watch: {
    '$route.params.patientId'(value) {
      if (value) {
        this.onRequest({
          pagination: { page: 1, rowsPerPage: this.pagination.rowsPerPage },
        });
      }
    },
  },
  methods: {
    async onRequest({ pagination: { page, rowsPerPage } }) {
      try {
        this.isLoading = true;
        this.error = null;

        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;

        const { appointments, count } = await getAppointmentsAndCount({
          filters: {
            userId: this.patientId,
          },
          offset: (page - 1) * rowsPerPage,
          limit: rowsPerPage,
        });

        this.appointments = appointments;
        this.pagination.rowsNumber = count;
      } catch (error) {
        console.log(error);
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
    openModalForAddingAppointment() {
      this.$refs['appointment-modal'].open();
    },
    onAppointmentAdded(appointment) {
      this.appointments.unshift(appointment);
      this.pagination.rowsNumber += 1;
    },
    openModalForEditingAppointment(appointment) {
      this.$refs['appointment-modal'].open(appointment);
    },
    onAppointmentUpdated(appointment) {
      const index = this.appointments.findIndex(
        (res) => res.id === appointment.id
      );

      this.appointments.splice(index, 1, appointment);
    },
    confirmAppointmentDeletion(appointment) {
      this.$q
        .dialog({
          title: this.$t('components.deleteAppointmentModal.title'),
          message: this.$t('components.deleteAppointmentModal.message', {
            appointedFor: appointment.appointedFor,
          }),
          ok: this.$t('components.deleteAppointmentModal.okButtonTitle'),
          cancel: this.$t(
            'components.deleteAppointmentModal.cancelButtonTitle'
          ),
        })
        .onOk(async () => {
          try {
            await deleteAppointmentById(appointment.id);

            const index = this.appointments.findIndex(
              (res) => res.id === appointment.id
            );

            this.appointments.splice(index, 1);
          } catch (error) {
            this.error = error;
          }
        });
    },
  },
};
</script>
