<template>
  <q-card style="width: 400px; max-width: 80vw" flat bordered>
    <q-form
      autocorrect="off"
      autocapitalize="off"
      autocomplete="off"
      spellcheck="false"
      @submit="onSubmit"
    >
      <q-card-section>
        <span class="text-h5">
          {{ $t('pages.resetPassword.title') }}
        </span>
      </q-card-section>

      <q-card-section v-if="error" class="q-py-none">
        <q-banner class="text-white bg-red" rounded>
          {{ error.message }}
        </q-banner>
      </q-card-section>

      <q-card-section>
        <div class="row q-col-gutter-md">
          <div class="col-12">
            <q-input
              v-model.trim="password"
              :label="$t('pages.resetPassword.labels.password')"
              :error="v$.password.$error"
              :error-message="$t('pages.resetPassword.errors.password')"
              type="password"
              hide-bottom-space
              autofocus
            />
          </div>

          <div class="col-12">
            <q-input
              v-model.trim="rePassword"
              :label="$t('pages.resetPassword.labels.rePassword')"
              :error="v$.rePassword.$error"
              :error-message="$t('pages.resetPassword.errors.rePassword')"
              type="password"
              hide-bottom-space
            />
          </div>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          :loading="isLoading"
          :label="$t('pages.resetPassword.changeButtonTitle')"
          type="submit"
          color="primary"
          flat
        />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script>
import useValidate from '@vuelidate/core';
import { required, sameAs } from '@vuelidate/validators';

import { resetPassword } from '@/api/account';

export default {
  meta() {
    return {
      title: this.$t('pages.resetPassword.documentTitle'),
      hideAppName: true,
    };
  },
  data() {
    return {
      v$: useValidate(),
      isLoading: false,
      error: null,
      password: null,
      rePassword: null,
    };
  },
  validations() {
    return {
      password: {
        required,
      },
      rePassword: {
        required,
        sameAs: sameAs(this.password),
      },
    };
  },
  methods: {
    async onSubmit() {
      this.error = null;
      this.v$.$touch();

      if (!this.v$.$invalid) {
        try {
          this.isLoading = true;

          const role = await resetPassword({
            token: this.$route.query.token,
            password: this.password,
          });

          if (role !== 'patient') {
            this.$router.push(this.$getLocalizedPath({ name: 'SignIn' }));

            this.$q.notify({
              icon: 'person',
              type: 'positive',
              position: 'center',
              message: this.$t(
                'pages.resetPassword.passwordChangedSuccessfully'
              ),
            });
          } else {
            this.$router.push(
              this.$getLocalizedPath({ name: 'ResetSuccessfully' })
            );
          }
        } catch (error) {
          this.error = error;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
