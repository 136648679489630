import axios from '@/helpers/axios';

export function getAppointmentsAndCount(options) {
  return axios.post('/doctor/appointments/list', options).then(({ data }) => {
    return {
      appointments: data.results,
      count: data.count,
    };
  });
}

export function getAppointmentById(id) {
  return axios
    .post('/doctor/appointments/get', {
      id,
    })
    .then(({ data }) => {
      return data.appointment;
    });
}

export function addAppointment(appointment) {
  return axios
    .post('/doctor/appointments/create', {
      appointment,
    })
    .then(({ data }) => {
      return data.appointment;
    });
}

export function updateAppointment(appointment) {
  return axios
    .post('/doctor/appointments/update', {
      id: appointment.id,
      appointment,
    })
    .then(({ data }) => {
      return data.appointment;
    });
}

export function deleteAppointmentById(id) {
  return axios.post('/doctor/appointments/delete', {
    id,
  });
}
