<template>
  <div
    :class="{
      bordered: !isImage,
      'cursor-pointer': !readonly,
    }"
    :style="{
      backgroundImage: isImage ? `url('${modelValue}')` : 'none',
    }"
    class="
      column
      items-center
      justify-center
      text-center
      full-width full-height
    "
    style="
      background-position: center top;
      background-size: contain;
      background-repeat: no-repeat;
    "
    @click="selectImage"
  >
    <span v-if="!isImage">
      {{
        readonly
          ? $t('components.appImageSelector.noImage')
          : $t('components.appImageSelector.selectImage')
      }}
    </span>

    <input
      ref="image-selector-input"
      type="file"
      accept="image/*"
      class="absolute hidden"
      @change="onSelectImage"
    />
  </div>
</template>

<script>
export default {
  props: ['modelValue', 'readonly'],
  emits: ['update:modelValue'],
  computed: {
    isImage() {
      return typeof this.modelValue === 'string';
    },
  },
  methods: {
    selectImage() {
      if (!this.readonly) {
        this.$refs['image-selector-input'].click();
      }
    },
    onSelectImage(event) {
      if (event.target.files?.length > 0) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.$emit('update:modelValue', e.target.result);
        };

        reader.readAsDataURL(event.target.files[0]);
      }
    },
  },
};
</script>
