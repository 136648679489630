import axios from 'axios';

import store from '@/store';
import router from '@/router';
import getLocalizedPath from '@/helpers/getLocalizedPath';
import dayjs from '@/helpers/dayjs';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

instance.interceptors.request.use(
  (config) => {
    config.data = {
      accessToken: store.getters['account/accessToken'],
      ...config.data,
      meta: {
        timezone: dayjs.tz.guess(),
        ...config.data?.meta,
      },
    };

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch('account/signOut');
    }

    if (error?.response?.status === 402) {
      router.push(getLocalizedPath({ name: 'Contracts' }));
    }

    if (error?.response?.data?.exception) {
      return Promise.reject(error.response.data.exception);
    }

    return Promise.reject(error);
  }
);

export default instance;
