import { render } from "./PatientExams.vue?vue&type=template&id=6c18a556"
import script from "./PatientExams.vue?vue&type=script&lang=js"
export * from "./PatientExams.vue?vue&type=script&lang=js"
script.render = render

export default script
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBanner,QTable,QTabs,QTab,QBtn,QBadge,QTr,QTd,QSkeleton,QIcon,QTooltip});
