<template>
  <q-dialog v-model="isVisible">
    <q-card style="width: 400px; max-width: 80vw">
      <q-form
        autocorrect="off"
        autocapitalize="off"
        autocomplete="off"
        spellcheck="false"
        @submit="onSubmit"
      >
        <q-card-section
          class="row items-center justify-between bg-primary text-white"
        >
          <span class="text-h6">
            {{ $t('components.restorePasswordModal.title') }}
          </span>

          <q-btn
            :disable="isLoading"
            icon="close"
            flat
            round
            dense
            v-close-popup
          />
        </q-card-section>

        <q-separator />

        <q-card-section v-if="error" class="q-pb-none">
          <q-banner class="text-white bg-red" rounded>
            {{ error.message }}
          </q-banner>
        </q-card-section>

        <q-card-section v-if="isSuccessfullySent" class="q-pb-none">
          <q-banner class="text-white bg-green" rounded>
            {{ $t('components.restorePasswordModal.success') }}
          </q-banner>
        </q-card-section>

        <q-card-section>
          <div>
            {{ $t('components.restorePasswordModal.description') }}
          </div>

          <div class="row q-col-gutter-md">
            <div class="col-12">
              <q-input
                v-model.trim="email"
                :label="$t('components.restorePasswordModal.labels.email')"
                :error="v$.email.$error"
                :error-message="
                  $t('components.restorePasswordModal.errors.email')
                "
                hide-bottom-space
                autofocus
              />
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn :disable="isLoading" color="primary" flat @click="close">
            {{ $t('components.restorePasswordModal.cancelButtonTitle') }}
          </q-btn>

          <q-btn
            :loading="isLoading"
            :label="$t('components.restorePasswordModal.restoreButtonTitle')"
            type="submit"
            color="primary"
            flat
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import useValidate from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';

import { restorePassword } from '@/api/account';

export default {
  data() {
    return {
      v$: useValidate({ $stopPropagation: true }),
      isVisible: false,
      isLoading: false,
      isSuccessfullySent: false,
      error: null,
      email: null,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    open(email) {
      this.isSuccessfullySent = false;
      this.error = null;
      this.email = email;

      this.v$.$reset();

      this.isLoading = false;
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    async onSubmit() {
      this.isSuccessfullySent = false;
      this.error = null;

      this.v$.$touch();

      if (!this.v$.$invalid) {
        try {
          this.isLoading = true;

          await restorePassword({
            email: this.email,
          });

          this.isSuccessfullySent = true;
        } catch (error) {
          this.error = error;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
