<template>
  <breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" />

  <q-card flat bordered>
    <q-card-section class="row items-center justify-between wrap q-pb-none">
      <span class="text-h5 q-mb-md">{{ $t('pages.folders.title') }}</span>

      <div class="row items-center wrap">
        <q-btn
          v-if="$isAllowed('doctor/folders/create')"
          :label="$t('pages.folders.addButtonTitle')"
          color="primary"
          class="q-mb-md"
          outline
          @click="openModalForAddingFolder"
        />
      </div>
    </q-card-section>

    <q-card-section v-if="error">
      <q-banner class="text-white bg-red q-mb-md" rounded>
        {{ error.message }}
      </q-banner>
    </q-card-section>

    <q-separator />

    <q-card-section>
      <div class="row q-col-gutter-md">
        <div
          v-for="folder of folders"
          :key="folder.id"
          class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
        >
          <q-card
            style="cursor: pointer"
            flat
            bordered
            @click="openFolderPage(folder)"
          >
            <q-card-section
              class="column justify-between"
              style="min-height: 150px"
            >
              <div class="row items-start justify-between no-wrap">
                <div class="col">
                  <div class="row items-center">
                    <div class="text-h6" style="word-break: break-word">
                      {{ folder.title }}
                    </div>

                    <q-icon
                      v-if="folder.isShared && folder.accessLevel === 'owner'"
                      name="group"
                      size="20px"
                      class="q-ml-sm"
                    />

                    <q-icon
                      v-if="folder.accessLevel === 'full'"
                      name="lock_open"
                      size="20px"
                      class="q-ml-sm"
                    />

                    <q-icon
                      v-if="folder.accessLevel === 'read-only'"
                      name="lock"
                      size="20px"
                      class="q-ml-sm"
                    />
                  </div>

                  <div class="text-subtitle2">
                    {{ this.$dayjs(folder.createdAt).format('L LT') }}
                  </div>

                  <div v-if="folder.doctorId !== accountInfo.userId">
                    Shared by {{ folder.owner.fullName }}
                  </div>
                </div>

                <q-btn
                  icon="more_vert"
                  flat
                  dense
                  round
                  class="q-pa-none q-ma-none"
                  size="sm"
                  @click.stop
                >
                  <q-menu anchor="bottom right" self="top right">
                    <q-list style="min-width: 150px">
                      <q-item
                        v-if="
                          $isAllowed('doctor/folders/update') &&
                          folder.accessLevel !== 'read-only'
                        "
                        clickable
                        v-close-popup
                        @click="openModalForEditingFolder(folder)"
                      >
                        <q-item-section>
                          {{ $t('pages.folders.edit') }}
                        </q-item-section>
                      </q-item>

                      <q-item
                        v-if="
                          $isAllowed('doctor/accesses/list') &&
                          folder.accessLevel === 'owner'
                        "
                        clickable
                        v-close-popup
                        @click="openModalForSharingFolder(folder)"
                      >
                        <q-item-section>
                          {{ $t('pages.folders.share') }}
                        </q-item-section>
                      </q-item>

                      <q-item
                        v-if="
                          $isAllowed('doctor/accesses/reject') &&
                          folder.accessLevel !== 'owner'
                        "
                        clickable
                        v-close-popup
                        @click="confirmFolderRejection(folder)"
                      >
                        <q-item-section>
                          {{ $t('pages.folders.reject') }}
                        </q-item-section>
                      </q-item>

                      <q-item
                        v-if="
                          $isAllowed('doctor/folders/delete') &&
                          folder.accessLevel === 'owner'
                        "
                        clickable
                        v-close-popup
                        @click="confirmFolderDeletion(folder)"
                      >
                        <q-item-section>
                          {{ $t('pages.folders.delete') }}
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu></q-btn
                >
              </div>

              <div>
                <span>
                  {{
                    $t('pages.folders.patientsCount', {
                      count: folder.patientsCount,
                    })
                  }}
                </span>
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </q-card-section>
  </q-card>

  <folder-modal
    ref="folder-modal"
    @folder-added="getFolders"
    @folder-updated="getFolders"
  />

  <share-modal ref="share-modal" @done="getFolders" />
</template>

<script>
import { mapGetters } from 'vuex';

import { getFoldersAndCount, deleteFolderById } from '@/api/folders';
import { reject } from '@/api/accesses';
import FolderModal from '@/components/FolderModal.vue';
import ShareModal from '@/components/ShareModal.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';

let initialData = {};

export default {
  components: {
    FolderModal,
    ShareModal,
    Breadcrumbs,
  },
  meta() {
    return {
      title: this.$t('pages.folders.documentTitle'),
    };
  },
  data() {
    return {
      error: initialData.error,
      folders: initialData.folders,
      foldersCount: initialData.foldersCount,
    };
  },
  computed: {
    ...mapGetters({
      accountInfo: 'account/info',
    }),
    breadcrumbs() {
      return [
        {
          to: this.$getLocalizedPath({ name: 'Dashboard' }),
          label: this.$t('breadcrumbs.dashboard'),
        },
        {
          to: null,
          label: this.$t('pages.folders.documentTitle'),
        },
      ];
    },
  },
  async beforeRouteEnter(to, from, next) {
    if (!to.query.page || !to.query.limit) {
      return next({
        ...to,
        query: {
          ...to.query,
          page: 1,
          limit: -1,
        },
      });
    }

    try {
      const { folders, count } = await getFoldersAndCount({
        offset: (+to.query.page - 1) * +to.query.limit,
        limit: +to.query.limit,
      });

      initialData.folders = folders;
      initialData.foldersCount = count;
    } catch (error) {
      initialData.error = error;
    } finally {
      next();
    }
  },
  mounted() {
    if (this.error.status == 402) {
      this.error = null;
    }
  },
  methods: {
    async getFolders() {
      try {
        const { folders, count } = await getFoldersAndCount({
          offset: (+this.$route.query.page - 1) * +this.$route.query.limit,
          limit: +this.$route.query.limit,
        });

        this.folders = folders;
        this.foldersCount = count;
      } catch (error) {
        this.error = error;
      }
    },
    openFolderPage(folder) {
      if (this.$isAllowed('doctor/folders/get')) {
        this.$router.push(
          this.$getLocalizedPath({
            name: 'PatientsIndex',
            params: {
              folderId: folder.id,
            },
          })
        );
      }
    },
    openModalForAddingFolder() {
      this.$refs['folder-modal'].open();
    },
    openModalForEditingFolder(folder) {
      this.$refs['folder-modal'].open(folder);
    },
    openModalForSharingFolder(folder) {
      this.$refs['share-modal'].open(folder);
    },
    confirmFolderDeletion(folder) {
      this.$q
        .dialog({
          title: this.$t('components.deleteFolderModal.title'),
          message: this.$t('components.deleteFolderModal.message', {
            title: folder.title,
          }),
          ok: this.$t('components.deleteFolderModal.okButtonTitle'),
          cancel: this.$t('components.deleteFolderModal.cancelButtonTitle'),
        })
        .onOk(async () => {
          try {
            await deleteFolderById(folder.id);

            const index = this.folders.findIndex((res) => res.id === folder.id);

            this.folders.splice(index, 1);
          } catch (error) {
            this.error = error;
          }
        });
    },
    confirmFolderRejection(folder) {
      this.$q
        .dialog({
          title: this.$t('components.rejectFolderModal.title'),
          message: this.$t('components.rejectFolderModal.message', {
            title: folder.title,
          }),
          ok: this.$t('components.rejectFolderModal.okButtonTitle'),
          cancel: this.$t('components.rejectFolderModal.cancelButtonTitle'),
        })
        .onOk(async () => {
          try {
            await reject(folder.id);

            const index = this.folders.findIndex((res) => res.id === folder.id);

            this.folders.splice(index, 1);
          } catch (error) {
            this.error = error;
          }
        });
    },
  },
};
</script>
