<template>
  <q-dialog v-model="isVisible" persistent>
    <q-card style="width: 400px; max-width: 80vw">
      <q-card-section>
        <h6 class="q-ma-none">
          {{ $t('components.importPatientsModal.title') }}
        </h6>
      </q-card-section>
      <q-card-section class="q-pt-none" style="white-space: pre-wrap">
        <p>
          {{ $t('components.importPatientsModal.message') }}
        </p>

        <div>
          <a
            :href="downloadUrl"
            class="text-weight-bold text-indigo-14"
            style="text-decoration: none"
          >
            {{ $t('components.importPatientsModal.fileName') }}
          </a>
        </div>
      </q-card-section>
      <q-card-actions class="q-pt-none row justify-end">
        <q-btn
          :label="$t('components.importPatientsModal.cancelBtn')"
          flat
          color="primary"
          @click="close"
        />
        <q-btn
          :label="$t('components.importPatientsModal.importBtn')"
          flat
          color="primary"
          @click="onPickFile"
        />

        <input
          type="file"
          style="visibility: hidden; position: absolute"
          ref="fileInput"
          accept=".csv"
          @change="onFilePicked"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  emits: ['file-picked'],
  data() {
    return {
      file: null,
      isVisible: false,
    };
  },
  computed: {
    downloadUrl() {
      return `${process.env.VUE_APP_API_STATIC_URL}/patients.csv.zip`;
    },
  },
  methods: {
    open() {
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async onFilePicked(event) {
      const file = event.target.files[0];
      const data = await this.toBase64(file);

      this.$emit('file-picked', data.split(',')[1]);

      this.close();
    },
  },
};
</script>
