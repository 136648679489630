<template>
  <q-dialog v-model="isVisible">
    <q-card flat style="width: 90vw; max-width: 1240px">
      <q-card-section
        class="row items-center justify-between bg-primary q-pa-lg text-white"
      >
        <span class="text-h5">
          {{ $t('pages.legalAndPrivacy.modal.title') }}
        </span>

        <q-btn icon="close" flat round dense v-close-popup />
      </q-card-section>

      <q-card-section class="q-pa-lg">
        <h4 class="text-h5 q-mt-none q-mb-md">
          {{ agreement.title }}
        </h4>

        <div v-html="agreement.content"></div>

        <q-btn color="primary" class="q-mt-md center" outline v-close-popup>
          {{ $t('pages.legalAndPrivacy.modal.okBtn') }}
        </q-btn>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
      agreement: {
        title: null,
        content: null,
      },
    };
  },
  methods: {
    openAgreement(agreement) {
      this.isVisible = true;

      this.agreement = agreement;
    },
  },
};
</script>

<style lang="scss" scoped></style>
