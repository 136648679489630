<template>
  <q-table
    :columns="tableColumns"
    :rows="estimates"
    :pagination="{
      rowsPerPage: 0,
    }"
    hide-bottom
    flat
    bordered
  >
    <template v-slot:body-cell-references="props">
      <q-td :props="props" class="gt-xs">
        <parameter-references
          v-if="props.row.ranges"
          :current-value="+props.row.value"
          :ranges="props.row.ranges"
        />
      </q-td>
    </template>
  </q-table>
</template>

<script>
import ParameterReferences from '@/components/ParameterReferences.vue';

export default {
  components: {
    ParameterReferences,
  },
  props: {
    exam: {
      type: Object,
      required: true,
    },
  },
  computed: {
    units() {
      return this.exam.units;
    },
    tableColumns() {
      return [
        {
          name: 'parameter',
          field: 'parameter',
          format: (value) =>
            this.$t(`components.estimates.labels.${value}`, {
              unit: this.units[value],
            }),
          label: this.$t('components.estimates.tableColumns.parameter'),
          align: 'left',
        },
        {
          name: 'value',
          field: 'value',
          format: (value, row) =>
            `${this.$numeral(value).format(row.format)}${
              row.parameter.includes('Pct') ? '%' : ''
            }`,
          label: this.$t('components.estimates.tableColumns.value'),
          align: 'center',
        },
        {
          name: 'references',
          label: this.$t('components.estimates.tableColumns.references'),
          align: 'left',
          headerClasses: 'full-width gt-xs',
        },
      ];
    },
    estimates() {
      return [
        { label: 'fm' },
        { label: 'ffm' },
        { label: 'abdominalFm' },
        { label: 'fmPct' },
        { label: 'ffmPct' },
        { label: 'fmi' },
        { label: 'ffmi' },
        { label: 'bmi' },
        { label: 'bmr', format: '0,0' },
        { label: 'tbw' },
      ].map((parameter) => {
        return {
          parameter: parameter.label,
          format: parameter.format ?? '0,0.0',
          value: this.exam.params[parameter.label],
          ranges: this.exam.estimates[parameter.label]?.ranges,
        };
      });
    },
  },
};
</script>
