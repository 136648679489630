<template>
  <q-input
    v-model.trim="search"
    :placeholder="$t('components.searchBox.placeholder')"
    debounce="300"
    style="width: 250px"
    filled
    square
  >
    <template #prepend>
      <q-icon name="search" />
    </template>

    <q-menu no-focus fit square>
      <q-list>
        <q-item v-show="isLoading">
          <q-item-section>
            {{ $t('components.searchBox.loading') }}
          </q-item-section>
        </q-item>

        <q-item v-show="patients?.length === 0 && !isLoading">
          <q-item-section>
            {{ $t('components.searchBox.noItems') }}
          </q-item-section>
        </q-item>

        <template v-if="!isLoading">
          <q-item
            v-for="patient of patients"
            :key="patient.id"
            :to="{
              name: 'PatientsShow',
              params: {
                folderId: patient.folderId,
                patientId: patient.userId,
              },
            }"
            clickable
            v-close-popup
          >
            <q-item-section avatar top>
              <q-avatar
                text-color="white"
                :style="{ backgroundColor: patient.user.avatar.color }"
              >
                {{ patient.user.avatar.abbr }}
              </q-avatar>
            </q-item-section>

            <q-item-section top>
              <q-item-label lines="1">
                {{ patient.user.fullName }}
              </q-item-label>

              <q-item-label lines="1">
                {{ patient.folder?.title }}
              </q-item-label>
            </q-item-section>

            <q-item-section side top>
              <q-item-label lines="1">
                {{ $dayjs(patient.dateOfBirth).format('YYYY') }}
              </q-item-label>

              <q-item-label lines="1">
                {{ $t('components.searchBox.examsCount') }}
                <q-badge color="primary" :label="patient.examsCount" />
              </q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </q-list>
    </q-menu>
  </q-input>
</template>

<script>
import { searchPatients } from '@/api/patients';

export default {
  data() {
    return {
      search: '',
      isLoading: false,
      patients: null,
    };
  },
  watch: {
    search(value) {
      if (value?.length >= 3) {
        this.searchPatients();
      } else {
        this.patients = null;
      }
    },
  },
  methods: {
    async searchPatients() {
      try {
        this.isLoading = true;

        this.patients = await searchPatients(this.search);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
