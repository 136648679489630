<template>
  <breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" />

  <q-card flat bordered>
    <q-card-section class="row items-center justify-between wrap q-pb-none">
      <span class="text-h5 q-mb-md">{{ $t('pages.appointments.title') }}</span>

      <div class="row items-center wrap">
        <q-btn
          v-if="$isAllowed('admin/appointments/create')"
          color="primary"
          class="q-mb-md"
          outline
          @click="openModalForAddingAppointment"
        >
          {{ $t('pages.appointments.add') }}
        </q-btn>
      </div>
    </q-card-section>

    <q-card-section v-if="error">
      <q-banner class="text-white bg-red q-mb-md" rounded>
        {{ error.message }}
      </q-banner>
    </q-card-section>

    <q-table
      v-model:pagination="pagination"
      :columns="tableColumns"
      :rows="appointments"
      :rows-per-page-options="[10, 20, 50, 100]"
      :loading="isLoading"
      :loading-label="$t('pages.appointments.loading')"
      :no-data-label="$t('pages.appointments.noItems')"
      row-key="id"
      class="q-pa-md"
      bordered
      @request="onRequest"
      @row-click="onRowClick"
    >
      <template v-slot:body-cell-actions="props">
        <q-td :props="props" @click.stop="">
          <q-btn icon="more_vert" flat round size="sm">
            <q-menu>
              <q-list style="min-width: 150px">
                <q-item
                  v-if="$isAllowed('doctor/appointments/update')"
                  clickable
                  v-close-popup
                  @click="openModalForEditingAppointment(props.row)"
                >
                  <q-item-section>
                    {{ $t('pages.appointments.edit') }}
                  </q-item-section>
                </q-item>

                <q-item
                  v-if="$isAllowed('doctor/appointments/delete')"
                  clickable
                  v-close-popup
                  @click="confirmAppointmentDeletion(props.row)"
                >
                  <q-item-section>
                    {{ $t('pages.appointments.delete') }}
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-td>
      </template>
    </q-table>
  </q-card>

  <appointment-modal
    ref="appointment-modal"
    @appointment-updated="onAppointmentUpdated"
  />
</template>

<script>
import {
  getAppointmentsAndCount,
  deleteAppointmentById,
} from '@/api/appointments';

import AppointmentModal from '@/components/AppointmentModal.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
let initialData = {};

export default {
  components: {
    AppointmentModal,
    Breadcrumbs,
  },
  meta() {
    return {
      title: this.$t('pages.appointments.documentTitle'),
    };
  },
  data() {
    return {
      error: initialData.error,
      appointments: initialData.appointments,
      appointmentsCount: initialData.appointmentsCount,
      isLoading: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    if (!to.query.page || !to.query.limit) {
      return next({
        ...to,
        query: {
          ...to.query,
          page: 1,
          limit: 20,
        },
      });
    }

    try {
      const { appointments, count } = await getAppointmentsAndCount({
        offset: (+to.query.page - 1) * +to.query.limit,
        limit: +to.query.limit,
      });

      initialData.appointments = appointments;
      initialData.appointmentsCount = count;
    } catch (error) {
      initialData.error = error;
    } finally {
      next();
    }
  },
  async beforeRouteUpdate(to, from, next) {
    if (!to.query.page || !to.query.limit) {
      return next({
        ...to,
        query: {
          ...to.query,
          page: 1,
          limit: 20,
        },
      });
    }

    try {
      this.error = null;
      this.isLoading = true;

      const { appointments, count } = await getAppointmentsAndCount({
        offset: (+to.query.page - 1) * +to.query.limit,
        limit: +to.query.limit,
      });

      this.appointments = appointments;
      this.appointmentsCount = count;
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
      next();
    }
  },
  mounted() {
    if (this.error.status === 402) {
      this.error = null;
    }
  },
  computed: {
    tableColumns() {
      return [
        {
          name: 'fullName',
          field: (row) => row.patient.user.fullName,
          label: this.$t('pages.appointments.tableColumns.fullName'),
          align: 'left',
        },
        {
          name: 'email',
          field: (row) => row.patient.user.email,
          label: this.$t('pages.appointments.tableColumns.email'),
          align: 'left',
        },
        {
          name: 'phone',
          field: (row) => row.patient.user.contactPhone,
          label: this.$t('pages.appointments.tableColumns.phone'),
          align: 'left',
        },
        {
          name: 'appointedFor',
          field: 'appointedFor',
          format: (value) => this.$dayjs(value).format('L LT'),
          label: this.$t('pages.appointments.tableColumns.appointedFor'),
          align: 'left',
        },
        {
          name: 'actions',
          label: this.$t('pages.appointments.tableColumns.actions'),
          align: 'right',
        },
      ];
    },
    breadcrumbs() {
      return [
        {
          to: this.$getLocalizedPath({ name: 'Dashboard' }),
          label: this.$t('breadcrumbs.dashboard'),
        },
        {
          to: this.$getLocalizedPath({ name: 'AppointmentsIndex' }),
          label: this.$t('breadcrumbs.appointments'),
        },
      ];
    },
    pagination: {
      get() {
        return {
          page: +this.$route.query.page,
          rowsPerPage: +this.$route.query.limit,
          rowsNumber: this.appointmentsCount,
        };
      },
      set({ page, rowsPerPage }) {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            page,
            limit: rowsPerPage,
          },
        });
      },
    },
  },
  methods: {
    onRequest({ pagination: { page, rowsPerPage } }) {
      this.pagination = {
        page,
        rowsPerPage,
      };
    },
    onRowClick(event, appointment) {
      this.openModalForEditingAppointment(appointment);
    },
    openModalForEditingAppointment(appointment) {
      this.$refs['appointment-modal'].open(appointment);
    },
    onAppointmentUpdated(appointment) {
      const index = this.appointments.findIndex(
        (res) => res.id === appointment.id
      );

      this.appointments.splice(index, 1, appointment);
    },
    confirmAppointmentDeletion(appointment) {
      this.$q
        .dialog({
          title: this.$t('components.deleteAppointmentModal.title'),
          message: this.$t('components.deleteAppointmentModal.message', {
            appointedFor: appointment.appointedFor,
          }),
          ok: this.$t('components.deleteAppointmentModal.okButtonTitle'),
          cancel: this.$t(
            'components.deleteAppointmentModal.cancelButtonTitle'
          ),
        })
        .onOk(async () => {
          try {
            await deleteAppointmentById(appointment.id);

            const index = this.appointments.findIndex(
              (res) => res.id === appointment.id
            );

            this.appointments.splice(index, 1);
          } catch (error) {
            this.error = error;
          }
        });
    },
  },
};
</script>
