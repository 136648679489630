<template>
  <q-dialog v-model="isVisible">
    <q-card style="width: 600px; max-width: 80vw">
      <q-form
        autocorrect="off"
        autocapitalize="off"
        autocomplete="off"
        spellcheck="false"
        @submit="onSubmit"
      >
        <q-card-section
          class="row items-center justify-between bg-primary text-white"
        >
          <span class="text-h6">
            {{
              isEditing
                ? $t('components.patientModal.updateTitle')
                : $t('components.patientModal.addTitle')
            }}
          </span>

          <q-btn
            :disable="isLoading"
            icon="close"
            flat
            round
            dense
            v-close-popup
          />
        </q-card-section>

        <q-separator />

        <q-card-section v-if="error">
          <q-banner class="text-white bg-red" rounded>
            {{ error.message }}
          </q-banner>
        </q-card-section>

        <q-card-section v-if="isFormulaChanged">
          <q-banner class="text-white bg-warning" rounded>
            <q-icon name="warning" size="sm" class="q-mr-sm" />
            {{ $t('components.patientModal.changingFormulaWarning') }}
          </q-banner>
        </q-card-section>

        <q-card-section>
          <div class="text-h6 q-mb-sm">
            {{ $t('components.patientModal.folders.personal') }}
          </div>

          <div class="row q-col-gutter-md">
            <div class="col-12 col-md-6">
              <q-input
                v-model.trim="patient.user.fullName"
                :label="$t('components.patientModal.labels.fullName')"
                :error="v$.patient.user.fullName.$error"
                :error-message="v$.patient.user.fullName.$errors[0]?.$message"
                hide-bottom-space
                autofocus
              />
            </div>

            <div class="col-12 col-md-3">
              <q-input
                v-model.trim="patient.code"
                :label="$t('components.patientModal.labels.code')"
                :error="v$.patient.code.$error"
                :error-message="v$.patient.code.$errors[0]?.$message"
                hide-bottom-space
              />
            </div>

            <div class="col-12 col-md-3">
              <q-select
                v-model="patient.formula"
                readonly
                :options="formulas"
                :disable="isLoading"
                :label="$t('components.patientModal.labels.formula')"
                :error="v$.patient.formula.$error"
                :error-message="v$.patient.formula.$errors[0]?.$message"
                emit-value
                map-options
                hide-bottom-space
              />
            </div>

            <div class="col-12 col-md-6">
              <q-select
                v-model="patient.gender"
                :options="[
                  {
                    value: 'male',
                    label: $t('components.patientModal.genders.male'),
                  },
                  {
                    value: 'female',
                    label: $t('components.patientModal.genders.female'),
                  },
                ]"
                :label="$t('components.patientModal.labels.gender')"
                :error="v$.patient.gender.$error"
                :error-message="v$.patient.gender.$errors[0]?.$message"
                emit-value
                map-options
                hide-bottom-space
              />
            </div>

            <div class="col-12 col-md-6">
              <q-input
                v-model.trim="patient.dateOfBirth"
                :label="$t('components.patientModal.labels.dateOfBirth')"
                :error="v$.patient.dateOfBirth.$error"
                :error-message="v$.patient.dateOfBirth.$errors[0]?.$message"
                mask="####"
                fill-mask
                hide-bottom-space
                readonly
                class="date-field"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer" />
                </template>
              </q-input>

              <custom-date-picker
                locale="$dayjs.locale()"
                v-model.trim="patient.dateOfBirth"
                type="year"
                custom-input=".date-field"
              />
            </div>

            <div class="col-12 col-md-6">
              <q-input
                v-model.trim="patient.user.email"
                :label="$t('components.patientModal.labels.email')"
                :error="v$.patient.user.email.$error"
                :error-message="v$.patient.user.email.$errors[0]?.$message"
                hide-bottom-space
              />
            </div>

            <div class="col-12 col-md-6">
              <q-input
                v-if="accountInfo?.user.measurement !== 'imperial'"
                v-model.number="patient.height"
                :label="
                  $t('components.patientModal.labels.height', {
                    unit: units.height,
                  })
                "
                :error="v$.patient.height.$error"
                :error-message="v$.patient.height.$errors[0]?.$message"
                hide-bottom-space
              />

              <imperial-height-input
                v-if="accountInfo?.user.measurement === 'imperial'"
                v-model="patient.height"
              />
            </div>
          </div>
        </q-card-section>

        <q-card-section>
          <div class="text-h6 q-mb-sm">
            {{ $t('components.patientModal.folders.contact') }}
          </div>

          <div class="row q-col-gutter-md">
            <div class="col-12 col-md-6">
              <q-select
                v-model="patient.user.country"
                :options="doctorCountries"
                :label="$t('components.patientModal.labels.country')"
                :error="v$.patient.user.country.$error"
                :error-message="v$.patient.user.country.$errors[0]?.$message"
                hide-bottom-space
              />
            </div>

            <div class="col-12 col-md-6">
              <q-input
                v-model.trim="patient.user.address"
                :label="$t('components.patientModal.labels.address')"
                :error="v$.patient.user.address.$error"
                :error-message="v$.patient.user.address.$errors[0]?.$message"
                hide-bottom-space
              />
            </div>

            <div class="col-12 col-md-6">
              <div class="row q-col-gutter-md">
                <div class="col-8">
                  <q-input
                    v-model.trim="patient.user.city"
                    :label="$t('components.patientModal.labels.city')"
                    :error="v$.patient.user.city.$error"
                    :error-message="$t('components.patientModal.errors.city')"
                    hide-bottom-space
                  />
                </div>

                <div class="col-4">
                  <q-input
                    v-model.trim="patient.user.postalCode"
                    :label="$t('components.patientModal.labels.postalCode')"
                    :error="v$.patient.user.postalCode.$error"
                    :error-message="
                      $t('components.patientModal.errors.postalCode')
                    "
                    hide-bottom-space
                  />
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <q-input
                v-model.trim="patient.user.contactPhone"
                :label="$t('components.patientModal.labels.contactPhone')"
                :error="v$.patient.user.contactPhone.$error"
                :error-message="
                  v$.patient.user.contactPhone.$errors[0]?.$message
                "
                hide-bottom-space
              >
                <template v-slot:prepend>
                  <q-icon name="fa fa-phone" size="xs"></q-icon>
                </template>
              </q-input>
            </div>
          </div>

          <div class="col-12 col-md-6 q-mt-md">
            <q-checkbox
              v-model="patient.user.isActive"
              :label="$t('components.patientModal.labels.status')"
            />
          </div>

          <div v-if="isEditing" class="col-12 col-md-6 q-mt-md">
            <q-checkbox
              v-model="resetPassword"
              :label="$t('components.patientModal.labels.resetPassword')"
              :disable="!patient.user.isActive"
            />
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn :disable="isLoading" flat @click="close">
            {{ $t('components.patientModal.cancelButtonTitle') }}
          </q-btn>

          <q-btn
            :loading="isLoading"
            :label="
              isEditing
                ? $t('components.patientModal.updateButtonTitle')
                : $t('components.patientModal.addButtonTitle')
            "
            type="submit"
            color="primary"
            flat
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import useValidate from '@vuelidate/core';
import { email, required, requiredIf } from '@vuelidate/validators';
import { helpers } from '@vuelidate/validators';

import { addPatient, updatePatient } from '@/api/patients';
import ImperialHeightInput from '@/components/inputs/ImperialHeightInput.vue';
import { formulas } from '@/api/misc';

export default {
  components: {
    ImperialHeightInput,
  },
  props: {
    folderId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
      formulas: [],
      isVisible: false,
      isLoading: false,
      error: null,
      initialHeight: null,
      initialGender: null,
      initialFormula: 1,
      patient: {
        code: null,
        gender: null,
        dateOfBirth: null,
        height: '123',
        formula: 1,
        user: {
          email: null,
          fullName: null,
          country: null,
          city: null,
          postalCode: null,
          address: null,
          contactPhone: null,
          isActive: true,
        },
      },
      resetPassword: false,
    };
  },
  validations() {
    return {
      patient: {
        code: {
          required,
        },
        gender: {
          required,
        },
        dateOfBirth: {
          required,
          maxValue: helpers.withMessage(
            this.$t('components.patientModal.errors.ageLimit'),
            (value) => value <= new Date().getFullYear() - 10
          ),
        },
        height: {
          required,
        },
        formula: {
          required,
        },
        user: {
          email: {
            required: requiredIf(this.patient.user.isActive),
            email,
          },
          fullName: {
            required,
          },
          country: {},
          city: {},
          postalCode: {},
          address: {},
          contactPhone: {},
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      doctorCountries: 'account/countries',
      units: 'account/units',
      accountInfo: 'account/info',
    }),
    isEditing() {
      return typeof this.patient?.userId === 'number'; // TODO: why not to compare with null or undefined?
    },
    isHeightChanged() {
      return this.patient.height !== this.initialHeight;
    },
    isGenderChanged() {
      return this.patient.gender !== this.initialGender;
    },
    isFormulaChanged() {
      return this.patient.formula !== this.initialFormula;
    },
  },
  watch: {
    'patient.user.isActive'(value) {
      if (!value) {
        this.resetPassword = false;
      }
    },
  },
  async created() {
    this.formulas = await formulas();
  },
  methods: {
    open(patient) {
      this.error = null;

      if (patient) {
        this.patient = _.cloneDeep(patient);
        this.patient.dateOfBirth = this.$dayjs(this.patient.dateOfBirth).format(
          'YYYY'
        );
      } else {
        this.clearPatient();
      }

      this.v$.$reset();

      this.resetPassword = false;

      this.initialHeight = this.patient.height;
      this.initialGender = this.patient.gender;
      this.initialFormula = this.patient.formula;
      this.isLoading = false;
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    clearPatient() {
      // It a full duplicate of the patient stucture above
      this.patient = {
        code: null,
        gender: null,
        dateOfBirth: null,
        formula: 1,
        height: null,
        user: {
          email: null,
          fullName: null,
          country: null,
          city: null,
          postalCode: null,
          address: null,
          contactPhone: null,
          isActive: true,
        },
      };
    },
    confirmChangingParam(param) {
      return new Promise((resolve) =>
        this.$q
          .dialog({
            title: this.$t('components.patientModal.changeParam.title'),
            message: this.$t('components.patientModal.changeParam.message', {
              param: this.$t('components.patientModal.changeParam.' + param),
            }),
            ok: {
              flat: true,
              label: this.$t(
                'components.patientModal.changeParam.okButtonTitle'
              ),
            },
            cancel: {
              flat: true,
              color: 'default',
            },
            persistent: true,
          })
          .onOk(() => {
            resolve(true);
          })
          .onCancel(() => {
            resolve(false);
          })
      );
    },
    async onSubmit() {
      this.error = null;

      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }

      try {
        this.isLoading = true;

        if (this.isEditing) {
          const heightChanged =
            !this.isHeightChanged ||
            (this.isHeightChanged &&
              (await this.confirmChangingParam('height')));

          const genderChanged =
            !this.isGenderChanged ||
            (this.isGenderChanged &&
              (await this.confirmChangingParam('gender')));

          if (heightChanged && genderChanged) {
            const patient = await updatePatient(
              {
                ...this.patient,
                dateOfBirth: this.$dayjs(
                  `01/01/${this.patient.dateOfBirth}`,
                  'DD/MM/YYYY'
                ).format('YYYY-MM-DD'),
                folderId: this.folderId,
              },
              this.resetPassword
            );

            this.$emit('patient-updated', patient);
            this.close();
          }
        } else {
          const patient = await addPatient({
            ...this.patient,
            dateOfBirth: this.$dayjs(
              `01/01/${this.patient.dateOfBirth}`,
              'DD/MM/YYYY'
            ).format('YYYY-MM-DD'),
            folderId: this.folderId,
          });

          this.$emit('patient-added', patient);
          this.close();
        }
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.q-field--standard.q-field--readonly.date-field
    .q-field__control:before) {
  border-bottom-style: solid;
}
</style>
