import { createI18n } from 'vue-i18n/index';

import en from '@/locales/en.json';
import it from '@/locales/it.json';
import es from '@/locales/es.json';
import de from '@/locales/de.json';

const defaultLocale = 'en';

export default createI18n({
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: {
    en,
    it,
    es,
    de,
  },
});
