import { render } from "./FoldersIndex.vue?vue&type=template&id=5a134b23"
import script from "./FoldersIndex.vue?vue&type=script&lang=js"
export * from "./FoldersIndex.vue?vue&type=script&lang=js"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QBtn,QBanner,QSeparator,QIcon,QMenu,QList,QItem,QItemSection});qInstall(script, 'directives', {ClosePopup});
