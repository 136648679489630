import { render } from "./ExamsShow.vue?vue&type=template&id=48b4a914"
import script from "./ExamsShow.vue?vue&type=script&lang=js"
export * from "./ExamsShow.vue?vue&type=script&lang=js"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QBtn,QBadge,QBanner,QSeparator,QIcon});
