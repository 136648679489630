<template>
  <div class="row q-pa-md">
    <div class="col">
      <h6 class="q-ma-none">
        {{ $t('components.patientDetails.title') }}
      </h6>
      {{ $t('components.patientDetails.description') }}
    </div>
    <div class="col text-right">
      <template v-for="button of buttons" :key="button">
        <q-btn
          :color="button.color"
          :label="button.label"
          :outline="button.isOutline"
          :to="button.to"
          class="q-mb-md q-ml-sm"
          @click="button.onClick"
        />
      </template>
    </div>
  </div>

  <div v-if="patient" class="column q-px-md q-pb-md">
    <div class="col">
      <div class="row q-col-gutter-md">
        <div class="col-12 col-md-6">
          <q-input
            v-model.trim="patient.user.fullName"
            :label="$t('components.patientDetails.labels.fullName')"
            hide-bottom-space
            readonly
          />
        </div>

        <div class="col-12 col-md-4">
          <q-input
            v-model.trim="patient.code"
            :label="$t('components.patientDetails.labels.code')"
            hide-bottom-space
            readonly
          />
        </div>

        <div class="col-12 col-md-2">
          <q-input
            v-model.trim="patient.formulaName"
            :label="$t('components.patientDetails.labels.formula')"
            hide-bottom-space
            readonly
          />
        </div>

        <div class="col-12 col-md-6">
          <q-input
            :model-value="gender"
            :label="$t('components.patientDetails.labels.gender')"
            hide-bottom-space
            readonly
          />
        </div>

        <div class="col-12 col-md-6">
          <q-input
            :model-value="this.$dayjs(patient.dateOfBirth).format('YYYY')"
            :label="$t('components.patientDetails.labels.dateOfBirth')"
            hide-bottom-space
            readonly
          />
        </div>

        <div class="col-12 col-md-6">
          <q-input
            v-model.trim="patient.user.email"
            :label="$t('components.patientDetails.labels.email')"
            hide-bottom-space
            readonly
          />
        </div>

        <div class="col-12 col-md-6">
          <q-input
            v-model.trim="patient.height"
            :label="
              $t('components.patientDetails.labels.height', {
                unit: units.height,
              })
            "
            hide-bottom-space
            readonly
          />
        </div>

        <div class="col-12 col-md-6"></div>
      </div>
    </div>

    <div class="col q-mt-md">
      <div class="text-h6 q-mb-sm">
        {{ $t('components.patientDetails.folders.contact') }}
      </div>

      <div class="row q-col-gutter-md">
        <div class="col-12 col-md-6">
          <q-input
            v-model.trim="patient.user.country"
            :label="$t('components.patientDetails.labels.country')"
            hide-bottom-space
            readonly
          />
        </div>

        <div class="col-12 col-md-6">
          <div class="row q-col-gutter-md">
            <div class="col-8">
              <q-input
                v-model.trim="patient.user.city"
                :label="$t('components.patientDetails.labels.city')"
                hide-bottom-space
                readonly
              />
            </div>

            <div class="col-4">
              <q-input
                v-model.trim="patient.user.postalCode"
                :label="$t('components.patientDetails.labels.postalCode')"
                hide-bottom-space
                readonly
              />
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6">
          <q-input
            v-model.trim="patient.user.address"
            :label="$t('components.patientDetails.labels.address')"
            hide-bottom-space
            readonly
          />
        </div>

        <div class="col-12 col-md-6">
          <q-input
            v-model.trim="patient.user.contactPhone"
            :label="$t('components.patientDetails.labels.contactPhone')"
            hide-bottom-space
            readonly
          >
            <template v-slot:prepend>
              <q-icon name="fa fa-phone" size="xs"></q-icon>
            </template>
          </q-input>
        </div>
      </div>
    </div>

    <div class="row q-col-gutter-md q-mt-sm">
      <div class="col-12 col-md-6">
        <q-checkbox
          v-model="patient.user.isActive"
          :label="$t('components.patientDetails.labels.status')"
          disable
        />
      </div>
    </div>
  </div>

  <patient-modal
    ref="modal"
    :folder-id="+$route.params.folderId"
    @patient-updated="onPatientUpdated"
  />
</template>

<script>
import { mapGetters } from 'vuex';

import _ from 'lodash';
import PatientModal from '@/components/PatientModal';
import { getPatientById } from '@/api/patients';

export default {
  components: {
    PatientModal,
  },
  props: {
    patientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      patient: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      units: 'account/units',
    }),
    buttons() {
      if (this.patient && this.patient.folder.accessLevel !== 'read-only') {
        return [
          {
            color: 'primary',
            label: this.$t('components.patientDetails.editButtonTitle'),
            isOutline: true,
            onClick: this.openPatientModal,
          },
        ];
      }

      return [];
    },
    gender() {
      return _.startCase(this.patient?.gender);
    },
  },
  created() {
    this.getPatient();
  },
  watch: {
    '$route.params.patientId'(value) {
      if (value) {
        this.getPatient();
      }
    },
  },
  methods: {
    async getPatient() {
      try {
        this.error = null;
        this.isLoading = true;

        const { patient } = await getPatientById(+this.$route.params.patientId);

        this.patient = patient;
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
    openPatientModal() {
      this.$refs.modal.open(this.patient);
    },
    onPatientUpdated(patient) {
      this.patient = patient;
      // TODO: emit
    },
  },
};
</script>
