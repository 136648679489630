<template>
  <div class="q-mb-lg">
    <q-breadcrumbs separator="/">
      <q-breadcrumbs-el
        v-for="(item, index) of items"
        :key="index"
        :to="item.to"
        :icon="item.icon"
      >
        <template v-slot:default>
          <span v-if="item.label">{{ item.label }}</span>
          <q-spinner-dots
            v-if="!item.label"
            size="xs"
            indeterminate
          ></q-spinner-dots>
        </template>
      </q-breadcrumbs-el>
    </q-breadcrumbs>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    items: Array,
  },
};
</script>

<style lang="sass" scoped></style>
