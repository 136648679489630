import axios from '@/helpers/axios';

export function getDashboard() {
  return axios.post('/doctor/dashboard/index', {}).then(({ data }) => {
    return {
      statisticalData: {
        patients: data.summary.patients,
        exams: data.summary.exams,
        appointments: data.summary.appointments,
        balance: data.summary.balance,
      },
      recentFolders: data.recent.folders ? data.recent.folders : [],
      recentPatients: data.recent.patients ? data.recent.patients : [],
    };
  });
}

export function getStats() {
  return axios.post('/doctor/dashboard/stats', {}).then(({ data }) => data);
}
