<template>
  <breadcrumbs v-if="!isPrintPage && breadcrumbs" :items="breadcrumbs" />

  <q-card flat bordered>
    <q-card-section
      class="
        q-card__section q-card__section--vert
        row
        items-center
        wrap
        q-pb-none
        justify-between
      "
    >
      <custom-report-header />

      <q-space />

      <div class="row items-center wrap">
        <q-btn
          v-if="!isPrintPage"
          :to="
            $getLocalizedPath(
              `/folders/${$route.params.folderId}/patients/${$route.params.patientId}/reports/follow-up/print`
            )
          "
          target="_blank"
          color="primary"
          class="q-mb-md"
          outline
        >
          {{ $t('pages.followUpReport.printButtonTitle') }}
        </q-btn>

        <q-btn
          v-if="isPrintPage"
          color="primary"
          class="q-mb-md print-hide"
          outline
          @click="printPage"
        >
          {{ $t('pages.followUpReport.printButtonTitle') }}
        </q-btn>
      </div>
    </q-card-section>

    <q-card-section v-if="error">
      <q-banner className="text-white bg-red q-mb-md" rounded>
        {{ error.message }}
      </q-banner>
    </q-card-section>

    <q-separator />

    <q-card-section class="text-h6 q-px-md q-py-lg">
      <span>
        {{ $t('pages.followUpReport.title') }}
      </span>
    </q-card-section>

    <q-card-section>
      <div class="row q-mb-lg">
        <div class="col-4 text-center q-pa-md bordered">
          {{
            $t('pages.followUpReport.gender', {
              gender: $t(`pages.followUpReport.genders.${patient.gender}`),
            })
          }}
        </div>
        <div class="col-4 text-center q-pa-md bordered">
          {{
            $t('pages.followUpReport.birthDate', {
              date: $dayjs(patient.dateOfBirth, 'YYYY').format('YYYY'),
            })
          }}
        </div>
        <div class="col-4 text-center q-pa-md bordered">
          {{ $t('pages.followUpReport.age', { age: patient.age }) }}
        </div>
      </div>

      <div class="q-mb-lg">
        {{ $t('pages.followUpReport.description') }}
      </div>

      <app-chart
        v-for="chart of charts"
        :key="chart"
        :data="chart.data"
        :options="chart.options"
        :plugins="plugins"
        :type="chart.type"
        height="300px"
        class="q-my-xl"
      />
    </q-card-section>
  </q-card>

  <q-card flat bordered class="q-mt-md" v-if="accountInfo.reportFooter">
    <q-card-section v-html="accountInfo.reportFooter" />
  </q-card>
</template>

<script>
import { mapGetters } from 'vuex';

import _ from 'lodash';
import { colors } from 'quasar';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { getFollowUpReportByPatientId } from '@/api/reports';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import CustomReportHeader from '@/components/CustomReportHeader.vue';
import AppChart from '@/components/AppChart.vue';

let initialData = {};

export default {
  components: {
    Breadcrumbs,
    CustomReportHeader,
    AppChart,
  },
  meta() {
    return {
      title: this.$t('pages.followUpReport.documentTitle'),
    };
  },
  data() {
    return {
      error: initialData.error,
      patient: initialData.patient,
      exams: initialData.exams,
      plugins: [ChartDataLabels],
    };
  },
  computed: {
    ...mapGetters({
      units: 'account/units',
      accountInfo: 'account/info',
    }),
    breadcrumbs() {
      return [
        {
          to: this.$getLocalizedPath({ name: 'Dashboard' }),
          label: this.$t('breadcrumbs.dashboard'),
        },
        {
          to: this.$getLocalizedPath({ name: 'FoldersIndex' }),
          label: this.$t('breadcrumbs.folders'),
        },
        {
          to: this.$getLocalizedPath({
            name: 'PatientsIndex',
            params: { ':folderId': this.patient?.folderId },
          }),
          label: this.patient?.folder.title,
        },
        {
          to: this.$getLocalizedPath({
            name: 'PatientsShow',
            params: {
              ':folderId': this.patient?.folderId,
              ':patientId': this.patient?.userId,
            },
          }),
          label: this.patient?.user.fullName,
        },
        {
          label: this.$t('breadcrumbs.followUpReport'),
        },
      ];
    },
    parameters() {
      return [
        {
          title: this.$t('pages.followUpReport.charts.weight', {
            unit: this.units.weight,
          }),
          parameter: 'params.weight',
          color: [colors.getPaletteColor('red')],
        },
        {
          title: this.$t('pages.followUpReport.charts.fm', {
            unit: this.units.fm,
          }),
          parameter: 'params.fm',
          color: [colors.getPaletteColor('pink')],
        },
        {
          title: this.$t('pages.followUpReport.charts.ffm', {
            unit: this.units.ffm,
          }),
          parameter: 'params.ffm',
          color: [colors.getPaletteColor('purple')],
        },
        {
          title: this.$t('pages.followUpReport.charts.abdominalFm', {
            unit: this.units.abdominalFm,
          }),
          parameter: 'params.abdominalFm',
          color: [colors.getPaletteColor('deep-purple')],
        },
        {
          title: this.$t('pages.followUpReport.charts.fmPct', {
            unit: this.units.fmPct,
          }),
          parameter: 'params.fmPct',
          color: [colors.getPaletteColor('indigo')],
        },
        {
          title: this.$t('pages.followUpReport.charts.ffmPct', {
            unit: this.units.ffmPct,
          }),
          parameter: 'params.ffmPct',
          color: [colors.getPaletteColor('blue')],
        },
        {
          title: this.$t('pages.followUpReport.charts.fmi', {
            unit: this.units.fmi,
          }),
          parameter: 'params.fmi',
          color: [colors.getPaletteColor('light-blue')],
        },
        {
          title: this.$t('pages.followUpReport.charts.ffmi', {
            unit: this.units.ffmi,
          }),
          parameter: 'params.ffmi',
          color: [colors.getPaletteColor('cyan')],
        },
        {
          title: this.$t('pages.followUpReport.charts.bmi', {
            unit: this.units.bmi,
          }),
          parameter: 'params.bmi',
          color: [colors.getPaletteColor('teal')],
        },
        {
          title: this.$t('pages.followUpReport.charts.bmr', {
            unit: this.units.bmr,
          }),
          parameter: 'params.bmr',
          color: [colors.getPaletteColor('green')],
        },
        {
          title: this.$t('pages.followUpReport.charts.tbw', {
            unit: this.units.tbw,
          }),
          parameter: 'params.tbw',
          color: [colors.getPaletteColor('light-green')],
        },
        {
          title: this.$t('pages.followUpReport.charts.chest', {
            unit: this.units.chest,
          }),
          parameter: 'params.chest',
          color: [colors.getPaletteColor('lime')],
        },
        {
          title: this.$t('pages.followUpReport.charts.belly', {
            unit: this.units.belly,
          }),
          parameter: 'params.belly',
          color: [colors.getPaletteColor('yellow')],
        },
        {
          title: this.$t('pages.followUpReport.charts.hip', {
            unit: this.units.hip,
          }),
          parameter: 'params.hip',
          color: [colors.getPaletteColor('amber')],
        },
        {
          title: this.$t('pages.followUpReport.charts.thigh', {
            unit: this.units.thigh,
          }),
          parameter: 'params.thigh',
          color: [colors.getPaletteColor('orange')],
        },
        {
          title: this.$t('pages.followUpReport.charts.calf', {
            unit: this.units.calf,
          }),
          parameter: 'params.calf',
          color: [colors.getPaletteColor('deep-orange')],
        },
        {
          title: this.$t('pages.followUpReport.charts.waistToHeight', {
            unit: this.units.waistToHeight,
          }),
          parameter: 'params.waistToHeight',
          color: [colors.getPaletteColor('brown')],
        },
      ];
    },
    charts() {
      return this.parameters.map((chart) => {
        return {
          data: {
            labels: this.exams.map((exam) => {
              return this.$dayjs(exam.createdAt).format('L');
            }),
            datasets: [
              {
                data: this.exams.map((exam) => {
                  return _.get(exam, chart.parameter);
                }),
                backgroundColor: chart.color,
                borderColor: chart.color,
                fill: false,
                stepped: true,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            layout: {
              padding: {
                right: 60,
              },
            },
            scales: {
              xAxes: {},
              yAxes: {
                offset: true,
              },
            },
            plugins: {
              title: {
                display: true,
                position: 'left',
                font: {
                  family: 'Roboto',
                  size: 24,
                },
                color: colors.getPaletteColor('grey-14'),
                text: chart.title,
              },
              legend: {
                display: false,
              },
              datalabels: {
                color: colors.getPaletteColor('grey-14'),
                align: -45,
              },
            },
          },
          type: 'line',
        };
      });
    },
    isPrintPage() {
      return this.$route.meta?.layoutName === 'Print';
    },
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const { patient, exams } = await getFollowUpReportByPatientId(
        +to.params.patientId
      );

      initialData.patient = patient;
      initialData.exams = exams;
    } catch (error) {
      initialData.error = error;
    } finally {
      next();
    }
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      this.error = null;

      const { patient, exams } = await getFollowUpReportByPatientId(
        +to.params.patientId
      );

      this.patient = patient;
      this.exams = exams;
    } catch (error) {
      this.error = error;
    } finally {
      next();
    }
  },
  created() {
    Chart.register(ChartDataLabels);
  },
  methods: {
    printPage() {
      window.print();
    },
  },
};
</script>
