import { render } from "./Default.vue?vue&type=template&id=31cfc1d2"
import script from "./Default.vue?vue&type=script&lang=js"
export * from "./Default.vue?vue&type=script&lang=js"
script.render = render

export default script
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QToolbar,QTabs,QRouteTab,QTab,QBadge,QSpace,QBtn,QAvatar,QIcon,QMenu,QList,QItem,QItemSection,QSeparator,QDrawer,QItemLabel,QPageContainer,QPage});qInstall(script, 'directives', {ClosePopup});
