<template>
  <breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" />

  <q-card flat bordered>
    <q-card-section class="row items-center justify-between wrap q-pb-none">
      <span class="text-h5 q-mb-md">
        {{
          $t('pages.exam.title', {
            no: exam.no,
            patientName: patient.user.fullName,
          })
        }}
      </span>

      <div class="row items-center wrap">
        <q-btn
          v-if="$isAllowed('doctor/exams/compare')"
          :label="$t('pages.exam.compareButtonTitle')"
          :disable="isComparisonDisabled"
          icon="compare_arrows"
          color="primary"
          class="q-mb-md"
          outline
          @click="compareExams"
        >
          <q-badge
            v-if="selectedExams.length"
            color="red"
            floating
            rounded
            class="q-pl-sm"
          >
            {{ selectedExams.length }}
          </q-badge>
        </q-btn>

        <q-btn
          v-if="$isAllowed('doctor/reports/composition')"
          :label="$t('pages.exam.compositionReportButtonTitle')"
          icon="las la-chart-pie"
          :to="
            $getLocalizedPath(
              `/folders/${$route.params.folderId}/patients/${$route.params.patientId}/exams/${$route.params.examId}/reports/composition`
            )
          "
          color="primary"
          class="q-mb-md q-ml-md"
          outline
        />

        <q-btn
          v-if="
            $isAllowed('doctor/exams/delete') &&
            patient.folder.accessLevel === 'owner'
          "
          icon="las la-trash-alt"
          color="negative"
          class="q-mb-md q-ml-md"
          outline
          @click="confirmDeletionOfExam"
        />
      </div>
    </q-card-section>

    <q-card-section v-if="error">
      <q-banner className="text-white bg-red q-mb-md" rounded>
        {{ error.message }}
      </q-banner>
    </q-card-section>

    <q-separator />

    <q-card-section>
      <template v-if="exams?.length > 1">
        <exams-carousel
          v-model="selectedExams"
          :exams="exams"
          :current-exam-id="+$route.params.examId"
        />

        <q-separator />
      </template>

      <div v-if="exam.warnings" class="col-12 col-md-4 q-my-md">
        <q-banner
          v-for="(warning, index) of exam.warnings"
          :key="index"
          class="text-white bg-warning q-my-md"
          rounded
          inline-actions
        >
          <q-icon name="las la-exclamation-triangle" size="sm" />
          {{ warning }}

          <template v-slot:action>
            <q-btn
              outline
              :label="$t('pages.exam.getHelpButtonTitle')"
              @click="onGetHelp"
            />
          </template>
        </q-banner>
      </div>

      <exam-info :exam="exam" :patient="patient" class="q-my-md" />

      <div class="row q-col-gutter-x-md">
        <div class="col-12 col-md-4 q-mb-md" style="min-width: 376px">
          <div class="text-h6 q-mb-md">
            {{ $t('pages.exam.sections.silhouette.title') }}
          </div>

          <silhouette :exam="exam" />
        </div>

        <div class="col-12 col-md-8 q-mb-md" style="flex: 1">
          <div class="text-h6 q-mb-md">
            {{ $t('pages.exam.sections.estimates.title') }}
          </div>

          <estimates :exam="exam" class="q-mb-md" />

          <div class="text-h6 q-mb-md">
            {{ $t('pages.exam.sections.energyLevels.title') }}
          </div>

          <energy-levels :exam="exam" class="q-mb-lg" />

          <exam-notes
            :can-be-edited="patient.folder.accessLevel !== 'read-only'"
            :exam="exam"
          />
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import { getPatientById } from '@/api/patients';
import { getExamById, getExamsAndCount, deleteExamById } from '@/api/exams';
import ExamInfo from '@/components/ExamInfo.vue';
import Estimates from '@/components/Estimates.vue';
import ExamsCarousel from '@/components/ExamsCarousel.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import EnergyLevels from '@/components/EnergyLevels';
import ExamNotes from '@/components/ExamNotes.vue';
import Silhouette from '@/components/Silhouette.vue';

let initialData = {};

export default {
  components: {
    EnergyLevels,
    ExamInfo,
    Estimates,
    ExamsCarousel,
    Breadcrumbs,
    ExamNotes,
    Silhouette,
  },
  meta() {
    return {
      title: this.$t('pages.exam.documentTitle', {
        no: this.exam?.no,
        patientName: this.patient?.user.fullName,
      }),
    };
  },
  data() {
    return {
      error: initialData.error,
      patient: initialData.patient,
      exams: initialData.exams,
      exam: initialData.exam,
      selectedExams: [],
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          to: this.$getLocalizedPath({ name: 'Dashboard' }),
          label: this.$t('breadcrumbs.dashboard'),
        },
        {
          to: this.$getLocalizedPath({ name: 'FoldersIndex' }),
          label: this.$t('breadcrumbs.folders'),
        },
        {
          to: this.$getLocalizedPath({
            name: 'PatientsIndex',
            params: { ':folderId': this.patient?.folderId },
          }),
          label: this.patient?.folder.title,
        },
        {
          to: this.$getLocalizedPath({
            name: 'PatientsShow',
            params: {
              ':folderId': this.patient?.folderId,
              ':patientId': this.patient?.userId,
            },
          }),
          label: this.patient?.user.fullName,
        },
        {
          label: this.$t('breadcrumbs.exam', {
            no: this.exam.no,
          }),
        },
      ];
    },
    isComparisonDisabled() {
      return this.selectedExams.length !== 2;
    },
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const { patient } = await getPatientById(+to.params.patientId);
      const { exams } = await getExamsAndCount({
        patientId: +to.params.patientId,
      });
      const exam = await getExamById(+to.params.examId);

      initialData.patient = patient;
      initialData.exams = exams;
      initialData.exam = exam;
    } catch (error) {
      initialData.error = error;
    } finally {
      next();
    }
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      this.error = null;

      const { patient } = await getPatientById(+to.params.patientId);
      const { exams } = await getExamsAndCount({
        patientId: +to.params.patientId,
      });
      const exam = await getExamById(+to.params.examId);

      this.patient = patient;
      this.exams = exams;
      this.exam = exam;
    } catch (error) {
      this.error = error;
    } finally {
      next();
    }
  },
  methods: {
    onGetHelp() {
      window.Intercom('showSpace', 'help');
    },
    compareExams() {
      const exams = this.selectedExams.sort((a, b) =>
        this.$dayjs(a.createdAt).diff(this.$dayjs(b.createdAt), 'second', true)
      );

      this.$router.push(
        this.$getLocalizedPath(
          `/folders/${this.$route.params.folderId}/patients/${this.$route.params.patientId}/exams/compare?first=${exams[0].id}&second=${exams[1].id}`
        )
      );
    },
    confirmDeletionOfExam() {
      this.$q
        .dialog({
          title: this.$t('components.deleteExamModal.title'),
          message: this.$t('components.deleteExamModal.message'),
          ok: this.$t('components.deleteExamModal.okButtonTitle'),
          cancel: this.$t('components.deleteExamModal.cancelButtonTitle'),
        })
        .onOk(async () => {
          try {
            await deleteExamById(this.exam.id);

            const index = this.exams.findIndex(
              (res) => res.id === this.exam.id
            );

            this.exams.splice(index, 1);

            if (this.exams.length > 0) {
              this.$router.push(
                this.$getLocalizedPath(
                  `/folders/${this.$route.params.folderId}/patients/${this.$route.params.patientId}/exams/${this.exams[0].id}`
                )
              );
            } else {
              this.$router.push(
                this.$getLocalizedPath(
                  `/folders/${this.$route.params.folderId}/patients/${this.$route.params.patientId}`
                )
              );
            }
          } catch (error) {
            this.error = error;
          }
        });
    },
  },
};
</script>
