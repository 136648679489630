import { render } from "./Percentiles.vue?vue&type=template&id=2cb7eda9&scoped=true"
import script from "./Percentiles.vue?vue&type=script&lang=js"
export * from "./Percentiles.vue?vue&type=script&lang=js"

import "./Percentiles.vue?vue&type=style&index=0&id=2cb7eda9&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-2cb7eda9"

export default script
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QMarkupTable});
