<template>
  <q-card flat bordered>
    <q-card-section class="row items-center justify-between wrap q-pb-none">
      <h2 class="text-h5 q-mb-md">
        {{ $t('pages.legalAndPrivacy.title') }}
      </h2>
    </q-card-section>

    <q-table
      :columns="tableColumns"
      :rows="signatures"
      :rows-per-page-options="[0]"
      bordered
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td
            v-for="col in props.cols"
            :key="col.name"
            :props="props"
            @click="openModal(props.row.agreement)"
          >
            {{ col.value }}
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </q-card>

  <legal-and-privacy-modal ref="modal" />
</template>

<script>
import { getSignatures } from '@/api/signatures';
import LegalAndPrivacyModal from '@/components/LegalAndPrivacyModal.vue';

let initialData = {};

export default {
  components: {
    LegalAndPrivacyModal,
  },
  data() {
    return {
      signatures: initialData.signatures,
    };
  },
  async beforeRouteEnter() {
    try {
      const signatures = await getSignatures();

      initialData.signatures = signatures;

      initialData.signatures.forEach((item) => {
        item.title = item.agreement.title;
        item.createdAt = item.agreement.createdAt;
      });
    } catch (error) {
      this.$q.notify({
        type: 'negative',
        message: error.message,
        position: 'center',
      });
    }
  },
  computed: {
    tableColumns() {
      return [
        {
          name: 'title',
          field: 'title',
          label: this.$t('pages.legalAndPrivacy.tableColumns.title'),
          align: 'left',
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          format: (value) => this.$dayjs(value).format('L LT'),
          label: this.$t('pages.legalAndPrivacy.tableColumns.createdAt'),
          align: 'left',
        },
        {
          name: 'confirmedAt',
          field: 'confirmedAt',
          format: (value) => this.$dayjs(value).format('L LT'),
          label: this.$t('pages.legalAndPrivacy.tableColumns.confirmedAt'),
          align: 'left',
        },
      ];
    },
  },

  methods: {
    openModal(agreement) {
      this.$refs.modal.openAgreement(agreement);
    },
  },
};
</script>
