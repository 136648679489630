<template>
  <q-card style="width: 400px; max-width: 80vw" flat bordered>
    <q-form
      autocorrect="off"
      autocapitalize="off"
      autocomplete="off"
      spellcheck="false"
      @submit="onSubmit"
    >
      <q-card-section class="row items-center text-center">
        <q-img src="@/assets/logo-full.png" alt=""></q-img>
        <span class="text-h5">
          {{ $t('pages.signIn.title') }}
        </span>
      </q-card-section>

      <q-card-section v-if="Array.isArray(error?.message)">
        <q-banner class="text-white bg-red" rounded>
          {{ error.message[0] }}
          <a :href="error.message[1]" class="text-white">
            {{ error.message[1] }}
          </a>
        </q-banner>
      </q-card-section>

      <q-card-section v-else-if="error">
        <q-banner class="text-white bg-red" rounded>
          {{ error.message }}
        </q-banner>
      </q-card-section>

      <q-card-section>
        <div class="row q-col-gutter-md">
          <div class="col-12">
            <q-input
              v-model.trim="email"
              :label="$t('pages.signIn.labels.email')"
              :error="v$.email.$error"
              :error-message="$t('pages.signIn.errors.email')"
              hide-bottom-space
            />
          </div>

          <div class="col-12">
            <toggle-input
              v-model="password"
              :label="$t('pages.signIn.labels.password')"
              :error="v$.password.$error"
              :error-message="$t('pages.signIn.errors.password')"
            >
              <template v-slot:hint>
                <div class="text-right">
                  <span
                    class="text-primary cursor-pointer"
                    @click.prevent="restorePassword"
                  >
                    {{ $t('pages.signIn.restorePassword') }}
                  </span>
                </div>
              </template>
            </toggle-input>
          </div>
        </div>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn
          :loading="isLoading"
          :label="$t('pages.signIn.signInButtonTitle')"
          type="submit"
          color="primary"
          flat
        />
      </q-card-actions>
    </q-form>

    <restore-password-modal ref="restore-password-modal" />
  </q-card>
</template>

<script>
import { mapActions } from 'vuex';
import useValidate from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import ToggleInput from '@/components/inputs/ToggleInput.vue';
import RestorePasswordModal from '@/components/RestorePasswordModal.vue';

export default {
  components: {
    ToggleInput,
    RestorePasswordModal,
  },
  meta() {
    return {
      title: this.$t('pages.signIn.documentTitle'),
    };
  },
  data() {
    return {
      v$: useValidate(),
      isLoading: false,
      error: null,
      email: null,
      password: null,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  methods: {
    ...mapActions({
      signIn: 'account/signIn',
    }),
    async onSubmit() {
      this.error = null;
      this.v$.$touch();

      if (!this.v$.$invalid) {
        try {
          this.isLoading = true;

          await this.signIn({
            email: this.email,
            password: this.password,
          });
        } catch (error) {
          this.error = error;

          if (error.message.includes('[')) {
            this.error.message = error.message.split('[');
            this.error.message[1] = this.error.message[1].replace(']', '');
          }
        } finally {
          this.isLoading = false;
        }
      }
    },
    restorePassword() {
      this.$refs['restore-password-modal'].open(this.email);
    },
  },
};
</script>
