<template>
  <div class="row q-mb-md">
    <div class="col">
      <h6 class="q-ma-none">
        {{ $t('components.accountProfileTab.title') }}
      </h6>
      {{ $t('components.accountProfileTab.description') }}
    </div>
    <div class="col text-right">
      <template v-for="button of buttons" :key="button">
        <q-btn
          :color="button.color"
          :label="button.label"
          :outline="button.isOutline"
          :to="button.to"
          class="q-mb-md q-ml-sm"
          @click="button.onClick"
        />
      </template>
    </div>
  </div>

  <div class="row q-col-gutter-md">
    <div class="col-12">
      <q-banner rounded class="full-width bg-negative text-white" v-if="error">
        {{ error }}
      </q-banner>
    </div>

    <div class="col-12 col-md-6">
      <q-input
        v-model.trim="localAccount.user.fullName"
        :label="$t('components.accountProfileTab.labels.fullName')"
        :error="v$.localAccount.user.fullName.$error"
        :error-message="v$.localAccount.user.fullName.$errors[0]?.$message"
        :readonly="!isEdit"
        hide-bottom-space
      />
    </div>

    <div class="col-12 col-md-6">
      <q-input
        v-model.trim="localAccount.user.email"
        :label="$t('components.accountProfileTab.labels.email')"
        :error="v$.localAccount.user.email.$error"
        :error-message="v$.localAccount.user.email.$errors[0]?.$message"
        :readonly="!isEdit"
        hide-bottom-space
      />
    </div>

    <div class="col-12 col-md-6">
      <q-input
        v-model.trim="localAccount.user.password"
        :label="$t('components.accountProfileTab.labels.password')"
        :error="v$.localAccount.user.password.$error"
        :error-message="v$.localAccount.user.password.$errors[0]?.$message"
        :readonly="!isEdit"
        type="password"
        hide-bottom-space
      />
    </div>
  </div>

  <div class="text-h6 q-mt-md">
    {{ $t('components.accountProfileTab.sections.contacts') }}
  </div>

  <div class="row q-col-gutter-md">
    <div class="col-12 col-md-6">
      <q-select
        v-model="localAccount.user.country"
        :label="$t('components.accountProfileTab.labels.country')"
        :error="v$.localAccount.user.country.$error"
        :error-message="v$.localAccount.user.country.$errors[0]?.$message"
        readonly
        hide-bottom-space
      />
    </div>

    <div class="col-12 col-md-6">
      <div class="row q-col-gutter-md">
        <div class="col-8">
          <q-input
            v-model.trim="localAccount.user.city"
            :label="$t('components.accountProfileTab.labels.city')"
            :error="v$.localAccount.user.city.$error"
            :error-message="v$.localAccount.user.city.$errors[0]?.$message"
            :readonly="!isEdit"
            hide-bottom-space
          />
        </div>

        <div class="col-4">
          <q-input
            v-model.trim="localAccount.user.postalCode"
            :label="$t('components.accountProfileTab.labels.postalCode')"
            :error="v$.localAccount.user.postalCode.$error"
            :error-message="
              v$.localAccount.user.postalCode.$errors[0]?.$message
            "
            :readonly="!isEdit"
            hide-bottom-space
          />
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6">
      <q-input
        v-model.trim="localAccount.user.address"
        :label="$t('components.accountProfileTab.labels.address')"
        :error="v$.localAccount.user.address.$error"
        :error-message="v$.localAccount.user.address.$errors[0]?.$message"
        :readonly="!isEdit"
        hide-bottom-space
      />
    </div>

    <div class="col-12 col-md-6">
      <q-input
        v-model.trim="localAccount.user.contactPhone"
        :label="$t('components.accountProfileTab.labels.contactPhone')"
        :error="v$.localAccount.user.contactPhone.$error"
        :error-message="v$.localAccount.user.contactPhone.$errors[0]?.$message"
        :readonly="!isEdit"
        hide-bottom-space
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import useValidate from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';

import { updateAccount } from '@/api/account';

export default {
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
      isEdit: false,
      localAccount: _.cloneDeep(this.account),
      error: null,
    };
  },
  validations: {
    localAccount: {
      user: {
        fullName: {
          $autoDirty: true,
          required,
        },
        email: {
          required,
          email,
        },
        country: {},
        city: {},
        postalCode: {},
        address: {},
        password: {},
        contactPhone: {},
        notes: {},
      },
    },
  },
  computed: {
    buttons() {
      return [
        {
          isAllowed: this.$isAllowed('doctor/account/update'),
          color: this.isEdit ? 'secondary' : 'primary',
          label: this.isEdit
            ? this.$t('components.accountProfileTab.saveButtonTitle')
            : this.$t('components.accountProfileTab.editButtonTitle'),
          isOutline: !this.isEdit,
          onClick: this.isEdit ? this.save : this.edit,
        },
      ];
    },
  },
  created() {
    this.updateTitle();
  },
  watch: {},
  methods: {
    updateTitle() {
      this.$emit('update:title', this.localAccount.user.fullName);
    },
    edit() {
      this.isEdit = true;
    },
    async save() {
      this.error = null;

      this.v$.$touch();

      if (!this.v$.$invalid) {
        try {
          const account = await updateAccount(this.localAccount);

          this.$emit('update:account', account);
          this.updateTitle();

          this.isEdit = false;
        } catch (error) {
          this.error = error.message;
        }
      }
    },
  },
};
</script>
