<template>
  <q-dialog v-model="visible" @hide="cleanUp">
    <q-card class="my-card" style="min-width: 400px">
      <q-card-section
        class="row items-center justify-between bg-primary text-white"
      >
        <span class="text-h6">
          {{ $t('components.uploadPhotoModal.title') }}
        </span>
      </q-card-section>

      <q-card-section v-if="error">
        <q-banner class="text-white bg-red" rounded>
          {{ error.message }}
        </q-banner>
      </q-card-section>

      <q-card-section class="q-pa-md q-gutter-md">
        <div class="row q-gutter-x-md q-mx-none">
          <div class="col">
            <q-input
              v-model="photo.weight"
              :label="$t('components.uploadPhotoModal.labels.weight')"
              flat
              autofocus
              :readonly="isLoading"
              :error="v$.photo.weight.$error"
              :error-message="
                v$.photo.weight.$errors.map((item) => item.$message).join(', ')
              "
            />
          </div>

          <div class="col">
            <q-input
              v-model="photo.height"
              :label="$t('components.uploadPhotoModal.labels.height')"
              flat
              :error="v$.photo.height.$error"
              :error-message="
                v$.photo.height.$errors.map((item) => item.$message).join(', ')
              "
              readonly
            />
          </div>
        </div>
        <div class="row">
          <q-file
            bottom-slots
            v-model="image"
            :label="$t('components.uploadPhotoModal.labels.photo')"
            counter
            class="col"
            :readonly="isLoading"
          >
            <template v-slot:prepend>
              <q-icon name="cloud_upload" @click.stop />
            </template>
            <template v-slot:append>
              <q-btn
                icon="close"
                flat
                @click.stop="image = null"
                class="cursor-pointer"
                :disable="isLoading"
              />
            </template>

            <template v-slot:hint>
              {{ $t('components.uploadPhotoModal.hints.photo') }}
            </template>
          </q-file>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn
          :label="$t('components.uploadPhotoModal.cancelButtonTitle')"
          v-close-popup
          flat
        />
        <q-btn
          :disable="!image"
          :label="$t('components.uploadPhotoModal.okButtonTitle')"
          flat
          color="primary"
          @click="onUploading"
          :loading="isLoading"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { uploadPhoto } from '@/api/photos';
import useValidate from '@vuelidate/core';
import { maxValue, minValue, required } from '@vuelidate/validators';

export default {
  components: {},
  props: {
    patientId: {
      type: Number,
      required: true,
    },
    height: {
      type: [Number, String],
      required: true,
    },
  },
  emits: ['succeeded'],
  data() {
    return {
      v$: useValidate(),
      error: null,
      isLoading: false,
      visible: false,
      image: null,
      photo: {
        height: this.height,
        weight: null,
      },
    };
  },
  validations() {
    return {
      photo: {
        weight: {
          $autoDirty: true,
          required,
          minValue: minValue(25),
          maxValue: maxValue(400),
        },
        height: {
          $autoDirty: true,
          required,
        },
      },
    };
  },
  computed: {},
  created() {},
  methods: {
    open() {
      this.visible = true;
    },
    async onUploading() {
      this.v$.$touch();

      if (!this.v$.$invalid) {
        await this.upload();
      }
    },
    async upload() {
      try {
        this.isLoading = true;
        const toBase64 = (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });

        await toBase64(this.image);
        const exam = await uploadPhoto({
          patientId: this.patientId,
          file: await toBase64(this.image),
          photo: {
            weight: this.photo.weight,
            height: this.photo.height,
          },
        });

        this.$emit('succeeded', exam);
        this.visible = false;
      } catch (e) {
        this.error = e;
      } finally {
        this.isLoading = false;
      }
    },
    cleanUp() {
      this.photo.weight = 0;
      this.image = null;
      this.error = null;
      this.isLoading = false;
      this.v$.$reset();
    },
  },
};
</script>

<style lang="sass" scoped></style>
