<template>
  <q-dialog v-model="isVisible">
    <q-card style="width: 400px; max-width: 80vw">
      <q-form
        autocorrect="off"
        autocapitalize="off"
        autocomplete="off"
        spellcheck="false"
        @submit="onSubmit"
      >
        <q-card-section
          class="row items-center justify-between bg-primary text-white"
        >
          <span class="text-h6">
            {{
              isEditing
                ? $t('components.folderModal.updateTitle')
                : $t('components.folderModal.addTitle')
            }}
          </span>

          <q-btn
            :disable="isLoading"
            icon="close"
            flat
            round
            dense
            v-close-popup
          />
        </q-card-section>

        <q-separator />

        <q-card-section v-if="error" class="q-pt-none">
          <q-banner class="text-white bg-red" rounded>
            {{ error.message }}
          </q-banner>
        </q-card-section>

        <q-card-section class="q-pt-none q-mt-sm">
          <div class="row q-col-gutter-md">
            <div class="col-12">
              <q-input
                v-model.trim="folder.title"
                :label="$t('components.folderModal.labels.title')"
                :error="v$.folder.title.$error"
                :error-message="$t('components.folderModal.errors.title')"
                hide-bottom-space
                autofocus
              />
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn :disable="isLoading" flat @click="close">
            {{ $t('components.folderModal.cancelButtonTitle') }}
          </q-btn>

          <q-btn
            :loading="isLoading"
            :label="
              isEditing
                ? $t('components.folderModal.updateButtonTitle')
                : $t('components.folderModal.addButtonTitle')
            "
            type="submit"
            color="primary"
            flat
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import _ from 'lodash';
import useValidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import { addFolder, updateFolder } from '@/api/folders';

export default {
  data() {
    return {
      v$: useValidate(),
      isVisible: false,
      isLoading: false,
      error: null,
      folder: {
        title: null,
      },
    };
  },
  validations: {
    folder: {
      title: { required },
    },
  },
  computed: {
    isEditing() {
      return typeof this.folder?.id === 'number';
    },
  },
  methods: {
    open(folder) {
      this.error = null;

      if (folder) {
        this.folder = _.cloneDeep(folder);
      } else {
        this.clearFolder();
      }

      this.v$.$reset();

      this.isLoading = false;
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    clearFolder() {
      this.folder = {
        title: null,
      };
    },
    async onSubmit() {
      this.error = null;

      this.v$.$touch();

      if (!this.v$.$invalid) {
        try {
          this.isLoading = true;

          if (this.isEditing) {
            await updateFolder(this.folder);

            this.$emit('folder-updated');
          } else {
            await addFolder(this.folder);

            this.$emit('folder-added');
          }

          this.close();
        } catch (error) {
          this.error = error;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
