<template>
  <div class="row q-col-gutter-md">
    <div class="col-12 col-md-6">
      <div class="text-h6 q-mb-md">
        {{ $t('components.accountBillingTab.sections.creditsHistory') }}
      </div>

      <account-credits-history
        ref="account-credits-history"
        :user-id="account.userId"
        class="col-12 col-md-6"
      />
    </div>
    <div class="col-12 col-md-6">
      <div class="row full-height text-center flex-center">
        <div class="column">
          <h5 class="q-ma-md text-primary">
            {{ $t('components.accountBillingTab.contactUs') }}
          </h5>
          <div class="col">
            <contact-us-btn />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountCreditsHistory from '@/components/AccountCreditsHistory';
import ContactUsBtn from '@/components/inputs/ContactUsBtn';

export default {
  components: {
    ContactUsBtn,
    AccountCreditsHistory,
  },
  props: {
    account: {
      type: Object,
      requried: true,
    },
  },
  created() {
    this.updateTitle();
    this.updateButtons();
  },
  methods: {
    updateTitle() {
      this.$emit(
        'update:title',
        this.$t('components.accountBillingTab.credits', {
          credits: this.$numeral(this.account.balance).format('0,0'),
        })
      );
    },
    updateButtons() {
      this.$emit('update:buttons', null);
    },
  },
};
</script>
