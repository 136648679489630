<template>
  <q-table
    dense
    bordered
    flat
    :title="$t('pages.patients.importTable.title')"
    :columns="columns"
    :rows="rows"
    :rows-per-page-options="[0]"
    hide-pagination
  >
    <template v-slot:body-cell-succeeded="props">
      <q-td class="text-center">
        <q-icon
          v-if="props.row.succeeded"
          name="check_circle"
          size="xs"
          color="positive"
        />
        <q-icon v-else name="error" size="xs" color="negative" />
      </q-td>
    </template>
  </q-table>
</template>

<script>
export default {
  props: {
    rows: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      log: null,
      columns: [
        {
          name: 'rowNumber',
          label: this.$t('pages.patients.importTable.columns.row'),
          align: 'left',
          field: 'rowNumber',
          style: 'width: 50px',
          headerStyle: 'width: 50px',
        },
        {
          name: 'succeeded',
          label: this.$t('pages.patients.importTable.columns.succeeded'),
          align: 'center',
          field: 'succeeded',
          style: 'width: 120px',
          headerStyle: 'width: 120px',
        },
        {
          name: 'message',
          label: this.$t('pages.patients.importTable.columns.message'),
          align: 'left',
          field: 'message',
        },
      ],
    };
  },
};
</script>

<style></style>
