<template>
  <div class="row items-center justify-between wrap">
    <span class="text-h6">
      {{ $t('components.examNotes.title') }}
    </span>

    <div class="row items-center wrap">
      <q-btn
        v-if="$isAllowed('doctor/exams/update') && canBeEdited"
        :label="
          isEdit
            ? $t('components.examNotes.saveButtonTitle')
            : $t('components.examNotes.editButtonTitle')
        "
        :loading="isLoading"
        :color="isEdit ? 'secondary' : 'primary'"
        :outline="!isEdit"
        @click="isEdit ? save() : edit()"
      />
    </div>
  </div>

  <q-input
    ref="input"
    v-model.trim="notes"
    :readonly="!isEdit"
    type="textarea"
    hide-bottom-space
  />
</template>

<script>
import { updateExam } from '@/api/exams';

export default {
  props: {
    exam: {
      required: true,
      type: Object,
    },
    canBeEdited: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      notes: this.exam.notes,
      isEdit: false,
      isLoading: false,
    };
  },
  methods: {
    async save() {
      try {
        this.isLoading = true;

        await updateExam({
          id: this.exam.id,
          notes: this.notes,
        });

        this.isEdit = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    edit() {
      this.isEdit = true;

      this.$refs.input.focus();
    },
  },
};
</script>
