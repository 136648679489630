import { createStore } from 'vuex';

import account from '@/store/modules/account';
import { chat } from '@/store/modules/chat';
import { stats } from '@/store/modules/stats';

export default createStore({
  modules: {
    account,
    chat,
    stats,
  },
});
