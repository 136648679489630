import axios from '@/helpers/axios';

export function getFoldersAndCount(options) {
  return axios.post('/doctor/folders/list', options).then(({ data }) => {
    return {
      folders: data.results,
      count: data.count,
    };
  });
}

export function getFolderById(id) {
  return axios
    .post('/doctor/folders/get', {
      id,
    })
    .then(({ data }) => {
      return data.folder;
    });
}

export function addFolder(folder) {
  return axios
    .post('/doctor/folders/create', {
      folder: folder,
    })
    .then(({ data }) => {
      return data.folder;
    });
}

export function updateFolder(folder) {
  return axios
    .post('/doctor/folders/update', {
      id: folder.id,
      folder: folder,
    })
    .then(({ data }) => {
      return data.folder;
    });
}

export function deleteFolderById(id) {
  return axios.post('/doctor/folders/delete', {
    id,
  });
}

export function exportFolder(id, includePersonalInfo) {
  return axios
    .post('/doctor/folders/export', {
      id,
      includePersonalInfo,
    })
    .then(({ data }) => {
      return data;
    });
}
