<template>
  <q-table
    :columns="tableColumns"
    :rows="folders"
    :no-data-label="$t('components.recentFolders.noItems')"
    :pagination="{ rowsPerPage: 0 }"
    row-key="id"
    hide-bottom
    @row-click="onRowClick"
  />
</template>

<script>
export default {
  props: {
    folders: {
      type: Array,
      required: true,
    },
  },
  computed: {
    tableColumns() {
      return [
        {
          name: 'title',
          field: 'title',
          label: this.$t('components.recentFolders.tableColumns.title'),
          align: 'left',
        },
        {
          name: 'lastSeenAt',
          field: 'viewedAt',
          format: (value) => this.$dayjs(value).format('L LT'),
          label: this.$t('components.recentFolders.tableColumns.lastSeenAt'),
          align: 'right',
        },
      ];
    },
  },
  methods: {
    onRowClick(event, folder) {
      this.openFolderPage(folder);
    },
    openFolderPage(folder) {
      if (this.$isAllowed('doctor/folders/get')) {
        this.$router.push(
          this.$getLocalizedPath(`/folders/${folder.id}/patients`)
        );
      }
    },
  },
};
</script>
