<template>
  <q-dialog v-model="isVisible">
    <q-card style="width: auto; max-width: 80vw">
      <q-form
        autocorrect="off"
        autocapitalize="off"
        autocomplete="off"
        spellcheck="false"
        @submit="onSubmit"
      >
        <q-card-section
          class="row items-center justify-between bg-primary text-white"
        >
          <span class="text-h6">
            {{ $t('components.approvePhotoModal.title') }}
          </span>

          <q-btn
            :disable="isLoading"
            icon="close"
            flat
            round
            dense
            v-close-popup
          />
        </q-card-section>

        <q-separator />

        <q-card-section v-if="error" class="q-pt-none">
          <q-banner class="text-white bg-red" rounded>
            {{ error.message }}
          </q-banner>
        </q-card-section>

        <q-card-section v-if="isAccountBalanceZero" class="q-pt-none">
          <q-banner class="text-white bg-orange" rounded>
            {{
              $t('components.approvePhotoModal.lowAccountBalanceWarning', {
                credits: accountBalance?.credits,
              })
            }}
          </q-banner>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="row justify-center self-start">
            <img
              :src="photo.file.original"
              alt=""
              style="
                width: auto;
                height: auto;
                max-width: calc(80vw - 32px);
                max-height: calc(70vh - 32px);
              "
            />
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn :disable="isLoading" color="primary" flat @click="close">
            {{ $t('components.approvePhotoModal.cancelButtonTitle') }}
          </q-btn>

          <q-btn
            :disable="isAccountBalanceZero"
            :loading="isLoading"
            :label="$t('components.approvePhotoModal.approveButtonTitle')"
            type="submit"
            color="primary"
            flat
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import { approvePhotoById } from '@/api/photos';

export default {
  props: {
    accountBalance: {
      type: Object,
    },
    photo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isVisible: false,
      isLoading: false,
      error: null,
    };
  },
  computed: {
    isAccountBalanceZero() {
      return this?.accountBalance?.credits === 0;
    },
  },
  methods: {
    open() {
      this.error = null;

      this.isLoading = false;
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    async onSubmit() {
      this.error = null;

      try {
        this.isLoading = true;

        await approvePhotoById(this.photo.id);

        this.$emit('approved');

        this.close();
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
