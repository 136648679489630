<template>
  <div class="row q-col-gutter-lg q-mb-lg">
    <div
      v-for="(data, index) of statisticalCards"
      :key="index"
      class="col-6 col-md-3"
    >
      <q-card class="text-grey-6" flat bordered>
        <q-card-section class="column items-center q-pt-lg">
          <q-icon :name="data.icon" size="lg" class="q-mb-xs" />
          <span class="text-h5 text-grey-7">
            {{ $numeral(data.value).format('0,0') }}
          </span>
          <span>{{ data.label }}</span>
        </q-card-section>
      </q-card>
    </div>
  </div>

  <div class="row q-col-gutter-lg q-mb-lg">
    <div class="col-12 col-md-6">
      <q-card flat bordered>
        <q-card-section class="q-pb-none">
          <div class="text-h6">
            {{ $t('pages.dashboard.recentFolders') }}
          </div>
        </q-card-section>

        <recent-folders :folders="recentFolders" />
      </q-card>

      <q-card class="q-mt-lg" flat bordered>
        <q-card-section>
          <div class="text-h6">
            {{ $t('pages.dashboard.mobileApps.title') }}
          </div>

          <div class="row q-col-gutter-sm">
            <div class="col-12 col-sm col-md-12 col-lg" style="font-size: 13px">
              {{ $t('pages.dashboard.mobileApps.description') }}
            </div>

            <div class="col-12 col-sm-auto col-md-12 col-lg-auto row">
              <a
                href="https://play.google.com/store/apps/details?id=com.pixelcando.fityouroutfit"
                target="blank"
              >
                <img
                  :src="require(`@/assets/google-play-${$i18n.locale}.svg`)"
                  alt=""
                  height="60"
                  style="margin-top: -10px; margin-left: -10px"
                />
              </a>

              <a
                href="https://apps.apple.com/it/app/fit-your-outfit/id1588038108"
                target="blank"
              >
                <img
                  :src="require(`@/assets/app-store-${$i18n.locale}.svg`)"
                  alt=""
                  height="40"
                />
              </a>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>

    <div class="col-12 col-md-6">
      <q-card flat bordered>
        <q-card-section class="q-pb-none">
          <div class="text-h6">
            {{ $t('pages.dashboard.recentPatients') }}
          </div>
        </q-card-section>

        <recent-patients :patients="recentPatients" />
      </q-card>
    </div>
  </div>
</template>

<script>
import { getDashboard } from '@/api/dashboard';
import RecentFolders from '@/components/RecentFolders.vue';
import RecentPatients from '@/components/RecentPatients.vue';

let initialData = {};

export default {
  components: {
    RecentFolders,
    RecentPatients,
  },
  meta() {
    return {
      title: this.$t('pages.dashboard.documentTitle'),
    };
  },
  data() {
    return {
      error: initialData.error,
      statisticalData: initialData.statisticalData,
      recentFolders: initialData.recentFolders,
      recentPatients: initialData.recentPatients,
    };
  },
  computed: {
    statisticalCards() {
      return [
        {
          icon: 'fas fa-diagnoses',
          value: this.statisticalData.patients,
          label: this.$t('pages.dashboard.stats.patients'),
        },
        {
          icon: 'fas fa-weight',
          value: this.statisticalData.exams,
          label: this.$t('pages.dashboard.stats.exams'),
        },
        {
          icon: 'far fa-calendar-alt',
          value: this.statisticalData.appointments,
          label: this.$t('pages.dashboard.stats.appointments'),
        },
        {
          icon: 'fas fa-coins',
          value: this.statisticalData.balance,
          label: this.$t('pages.dashboard.stats.credits'),
        },
      ];
    },
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const { statisticalData, recentFolders, recentPatients } =
        await getDashboard();

      initialData.statisticalData = statisticalData;
      initialData.recentFolders = recentFolders;
      initialData.recentPatients = recentPatients;
    } catch (error) {
      initialData.error = error;
    } finally {
      next();
    }
  },
};
</script>
