<template>
  <breadcrumbs v-if="!isPrintPage && breadcrumbs" :items="breadcrumbs" />

  <div
    v-if="error"
    style="
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      width: 100vw;
    "
  >
    <q-icon name="error" size="7vw" color="negative" />
    <h2 class="text-h4 text-negative text-center">
      {{ error.message }}
    </h2>
  </div>

  <q-card flat bordered v-else>
    <div
      class="absolute bg-white loading-item row items-center justify-center"
      v-if="isLoading"
    >
      Loading...
    </div>

    <q-card-section class="row items-center wrap q-pb-none justify-between">
      <custom-report-header :appearance="appearance" />

      <q-space />

      <div class="row items-center wrap">
        <q-btn
          v-if="!$route.params.token && !isPrintPage && isSendReportAllowed"
          @click="onSendReport"
          color="primary"
          class="q-mb-md q-mr-sm"
          outline
        >
          {{ $t('pages.compositionReport.sendReportButtonTitle') }}
        </q-btn>

        <q-btn
          v-if="!isPrintPage"
          :to="{
            name: 'CompositionReportPrint',
            params: $route.params,
            query: { locale: this.$i18n.locale },
          }"
          target="_blank"
          color="primary"
          class="q-mb-md q-mr-sm"
          outline
        >
          {{ $t('pages.compositionReport.printButtonTitle') }}
        </q-btn>

        <q-btn
          v-if="!isPrintPage"
          :to="{
            name: 'CompositionReportPrint',
            params: $route.params,
            query: { short: true },
          }"
          target="_blank"
          color="default"
          class="q-mb-md q-mr-sm"
          outline
        >
          {{ $t('pages.compositionReport.printNoGlossaryButtonTitle') }}
        </q-btn>

        <q-btn
          v-if="isPrintPage"
          :loading="!isReportLoaded"
          color="primary"
          class="q-mb-md print-hide q-mr-sm"
          outline
          @click="printPage"
        >
          {{ $t('pages.compositionReport.printButtonTitle') }}
        </q-btn>

        <q-btn-dropdown
          color="primary"
          outline
          :label="$t('components.accountPreferencesTab.labels.locale')"
          class="q-mb-md print-hide"
          v-if="$route.params?.token"
        >
          <q-list>
            <q-item
              clickable
              v-close-popup
              @click="onChangeLocale('en')"
              :active="$i18n.locale === 'en'"
            >
              <q-item-section>
                <q-item-label>
                  {{ $t('components.accountPreferencesTab.locales.en') }}
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item
              clickable
              v-close-popup
              @click="onChangeLocale('it')"
              :active="$i18n.locale === 'it'"
            >
              <q-item-section>
                <q-item-label>
                  {{ $t('components.accountPreferencesTab.locales.it') }}
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item
              clickable
              v-close-popup
              @click="onChangeLocale('es')"
              :active="$i18n.locale === 'es'"
            >
              <q-item-section>
                <q-item-label>
                  {{ $t('components.accountPreferencesTab.locales.es') }}
                </q-item-label>
              </q-item-section>
            </q-item>

            <q-item
              clickable
              v-close-popup
              @click="onChangeLocale('de')"
              :active="$i18n.locale === 'de'"
            >
              <q-item-section>
                <q-item-label>
                  {{ $t('components.accountPreferencesTab.locales.de') }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </div>
    </q-card-section>

    <q-separator />

    <div class="text-h6 q-mb-md q-px-md q-py-lg">
      {{
        $t('pages.compositionReport.title', {
          name: patient?.user.fullName,
          code: patient?.code,
        })
      }}
    </div>

    <q-card-section class="q-py-none">
      <exam-info :exam="exam" :patient="patient" />
    </q-card-section>

    <q-card-section>
      <div class="text-h6 q-mb-md">
        {{ $t('pages.compositionReport.estimates') }}
      </div>

      <estimates :exam="exam" />
    </q-card-section>

    <q-card-section>
      <div class="row justify-around q-col-gutter-x-md">
        <div class="col-md-6 col-12 q-mb-md column items-center">
          <app-chart
            :data="estimatedValuesChartData"
            :options="{
              plugins: {
                title: {
                  display: true,
                  font: {
                    family: 'Roboto',
                    size: 24,
                  },
                  color: '#616161',
                  text: $t('pages.compositionReport.pieCharts.estimatedValues'),
                },
                legend: {
                  position: 'bottom',
                },
                datalabels: {
                  color: '#ffffff',
                },
              },
            }"
            :plugins="plugins"
            type="pie"
            height="300px"
            width="300px"
          />
        </div>

        <div class="col-md-6 col-12 q-mb-md column items-center">
          {{ exam.gender }}
          <app-chart
            :data="meanValuesChartData"
            :options="{
              plugins: {
                title: {
                  display: true,
                  font: {
                    family: 'Roboto',
                    size: 24,
                  },
                  color: '#616161',
                  text: $t('pages.compositionReport.pieCharts.meanValues'),
                },
                legend: {
                  position: 'bottom',
                },
                datalabels: {
                  color: '#ffffff',
                },
              },
            }"
            :plugins="plugins"
            type="pie"
            height="300px"
            width="300px"
          />
        </div>
      </div>
      <div class="text-weight-medium">
        <div class="text-weight-bold text-dark">
          {{ $t('pages.compositionReport.pieCharts.legend.title') }}
        </div>
        <span class="text-red q-mr-sm">
          {{ $t('pages.compositionReport.pieCharts.legend.fm') }}
        </span>
        <span class="text-green">
          {{ $t('pages.compositionReport.pieCharts.legend.ffm') }}
        </span>
      </div>
    </q-card-section>

    <q-card-section>
      <div
        v-html="
          $t('pages.compositionReport.pieCharts.description', {
            icw: exam.params.icw,
            ecw: exam.params.ecw,
          })
        "
      ></div>
    </q-card-section>

    <q-card-section v-if="exam?.notes && exam?.notes.trim().length > 0">
      <div class="text-h6">
        {{ $t('components.examNotes.title') }}
      </div>
      <q-input
        v-model.trim="exam.notes"
        type="textarea"
        readonly
        hide-bottom-space
      />
    </q-card-section>

    <q-card-section>
      <div class="text-h6 q-mb-md">
        {{ $t('pages.compositionReport.compare') }}
      </div>
      <div class="row q-col-gutter-x-md">
        <div v-if="baselineExam" class="col-4 q-mb-md gt-xs">
          <div class="q-mb-sm">
            <div class="text-weight-bold">
              {{ $t('pages.compositionReport.silhouettes.baseline.title') }}
            </div>
            <div>
              {{ $dayjs(baselineExam.createdAt).format('L LT') }}
            </div>
            <div>
              {{
                $t('pages.compositionReport.silhouettes.baseline.abdominalFm', {
                  value: $numeral(baselineExam.params.abdominalFm).format(
                    '0,0.0'
                  ),
                  unit: units.abdominalFm,
                })
              }}
            </div>
            <div>
              {{
                $t(
                  'pages.compositionReport.silhouettes.baseline.waistToHeight',
                  {
                    value: $numeral(baselineExam.params.waistToHeight).format(
                      '0,0.0'
                    ),
                    unit: units.waistToHeight,
                  }
                )
              }}
            </div>
          </div>

          <img :src="baselineExam.silhouette" class="silhouette-img" />
        </div>

        <div class="col-4 q-mb-md gt-xs">
          <div class="q-mb-sm">
            <div class="text-weight-bold">
              {{ $t('pages.compositionReport.silhouettes.current.title') }}
            </div>
            <div>
              {{ $dayjs(exam.createdAt).format('L LT') }}
            </div>
            <div>
              {{
                $t('pages.compositionReport.silhouettes.current.abdominalFm', {
                  value: $numeral(exam.params.abdominalFm).format('0,0.0'),
                  unit: units.abdominalFm,
                })
              }}
            </div>
            <div>
              {{
                $t(
                  'pages.compositionReport.silhouettes.current.waistToHeight',
                  {
                    value: $numeral(exam.params.waistToHeight).format('0,0.0'),
                    unit: units.waistToHeight,
                  }
                )
              }}
            </div>
          </div>

          <img :src="exam.silhouette" class="silhouette-img" />
        </div>

        <div v-if="comparisonImage" class="col-12 col-sm-4 q-mb-md">
          <div class="q-mb-sm">
            <div class="text-weight-bold">
              {{ $t('pages.compositionReport.silhouettes.comparative.title') }}
            </div>

            <div class="row">
              <div class="col-6">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="12" height="12" rx="4" fill="#A0A0A0" />
                </svg>

                {{ $t('pages.compositionReport.silhouettes.before') }}
              </div>

              <div class="col-6">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="12" height="12" rx="4" fill="#00DC00" />
                </svg>

                {{ $t('pages.compositionReport.silhouettes.after') }}
              </div>
            </div>

            <div>
              {{
                $t('pages.compositionReport.silhouettes.current.abdominalFm', {
                  value: $numeral(
                    exam.params.abdominalFm - baselineExam.params.abdominalFm
                  ).format('0,0.0'),
                })
              }}
            </div>

            <div>
              {{
                $t(
                  'pages.compositionReport.silhouettes.current.waistToHeight',
                  {
                    value: $numeral(
                      exam.params.waistToHeight -
                        baselineExam.params.waistToHeight
                    ).format('0,0.0'),
                  }
                )
              }}
            </div>
          </div>

          <img :src="comparisonImage" class="silhouette-img" />
        </div>
      </div>
    </q-card-section>

    <q-card-section v-if="!$route.query.short">
      <div class="text-h6 q-mb-md">
        {{ $t('pages.glossary.title') }}
      </div>

      <div v-for="term of glossary" :key="term.id" class="q-mb-md">
        <div class="text-subtitle2 q-mb-sm text-grey-10">{{ term.term }}</div>

        <div class="text-body2">{{ term.meaning }}</div>
      </div>
    </q-card-section>

    <q-card-section v-if="!$route.query.short">
      <percentiles :units="units" />
    </q-card-section>

    <send-report-modal ref="reportModal" />
  </q-card>

  <q-card flat bordered class="q-mt-md" v-if="appearance?.footer">
    <q-card-section v-html="appearance.footer" />
  </q-card>
</template>

<script>
import { colors } from 'quasar';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { getPatientById } from '@/api/patients';
import {
  getCompositionReportByExamId,
  getPublicCompositionReport,
} from '@/api/reports';
import { getFolderById } from '@/api/folders';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import CustomReportHeader from '@/components/CustomReportHeader.vue';
import Estimates from '@/components/Estimates.vue';
import AppChart from '@/components/AppChart.vue';
import Percentiles from '@/components/Percentiles.vue';
import ExamInfo from '@/components/ExamInfo';
import SendReportModal from '@/components/SendReportModal.vue';

export default {
  components: {
    ExamInfo,
    Breadcrumbs,
    CustomReportHeader,
    Estimates,
    AppChart,
    Percentiles,
    SendReportModal,
  },
  meta() {
    return {
      title: this.$t('pages.compositionReport.documentTitle'),
    };
  },
  data() {
    return {
      error: null,
      isReportLoaded: false,
      patient: null,
      exam: null,
      baselineExam: null,
      meanValues: null,
      comparisonImage: null,
      glossary: null,
      plugins: [ChartDataLabels],
      appearance: null,
      accessLevel: null,
      locale: null,
      isLoading: false,
    };
  },
  computed: {
    units() {
      return this.exam.units;
    },
    breadcrumbs() {
      if (this.$route.query?.token) return [];

      return [
        {
          to: this.$getLocalizedPath({ name: 'Dashboard' }),
          label: this.$t('breadcrumbs.dashboard'),
        },
        {
          to: this.$getLocalizedPath({ name: 'FoldersIndex' }),
          label: this.$t('breadcrumbs.folders'),
        },
        {
          to: this.$getLocalizedPath({
            name: 'PatientsIndex',
            params: { ':folderId': this.patient?.folderId },
          }),
          label: this.patient?.folder.title,
        },
        {
          to: this.$getLocalizedPath({
            name: 'PatientsShow',
            params: {
              ':folderId': this.patient?.folderId,
              ':patientId': this.patient?.userId,
            },
          }),
          label: this.patient?.user.fullName,
        },
        {
          to: this.$getLocalizedPath({
            name: 'ExamsShow',
            params: {
              ':folderId': this.patient?.folderId,
              ':patientId': this.patient?.userId,
              ':examId': this.exam?.id,
            },
          }),
          label: this.$t('breadcrumbs.exam', {
            no: this.exam?.no,
          }),
        },
        {
          label: this.$t('breadcrumbs.compositionReport'),
        },
      ];
    },
    estimatedValuesChartData() {
      return {
        labels: [
          this.$t('pages.compositionReport.pieCharts.fm'),
          this.$t('pages.compositionReport.pieCharts.ffm'),
        ],
        datasets: [
          {
            data: [this.exam.params.fmPct, this.exam.params.ffmPct],
            backgroundColor: [
              colors.getPaletteColor('red'),
              colors.getPaletteColor('green'),
            ],
          },
        ],
      };
    },
    meanValuesChartData() {
      return {
        labels: [
          this.$t('pages.compositionReport.pieCharts.fm'),
          this.$t('pages.compositionReport.pieCharts.ffm'),
        ],
        datasets: [
          {
            data: [this.meanValues?.fm, this.meanValues?.ffm],
            backgroundColor: [
              colors.getPaletteColor('red'),
              colors.getPaletteColor('green'),
            ],
          },
        ],
      };
    },
    isPrintPage() {
      return this.$route.meta?.layoutName === 'Print';
    },
    isSendReportAllowed() {
      return this.accessLevel !== 'read-only';
    },
  },
  created() {
    if (this.$route.query.locale) {
      this.$i18n.locale = this.$route.query.locale;
    }

    this.getReport();
    Chart.register(ChartDataLabels);
  },
  mounted() {
    this.isReportLoaded = true;
  },
  methods: {
    async getReport() {
      try {
        this.error = null;
        this.isLoading = true;

        if (this.$route.params.token) {
          const {
            exam,
            baseline,
            meanValues,
            appearance,
            comparisonImage,
            language,
            glossary,
          } = await getPublicCompositionReport(
            this.$route.params.token,
            this.$route.query?.locale && this.$i18n.locale !== this.locale
              ? this.$i18n.locale
              : null
          );

          this.exam = exam;
          this.patient = exam.photo.patient;
          this.baselineExam = baseline;
          this.meanValues = meanValues;
          this.appearance = appearance;
          this.comparisonImage = comparisonImage;
          this.locale = language;
          this.glossary = glossary;
        } else {
          const { patient } = await getPatientById(
            +this.$route.params.patientId
          );
          this.patient = patient;

          const { accessLevel } = await getFolderById(
            +this.$route.params.folderId
          );
          this.accessLevel = accessLevel;

          const {
            exam,
            baseline,
            meanValues,
            appearance,
            comparisonImage,
            language,
            glossary,
          } = await getCompositionReportByExamId(
            +this.$route.params.examId,
            this.$route.query?.locale && this.$i18n.locale !== this.locale
              ? this.$i18n.locale
              : null
          );

          this.exam = exam;
          this.baselineExam = baseline;
          this.meanValues = meanValues;
          this.appearance = appearance;
          this.comparisonImage = comparisonImage;
          this.locale = language;
          this.glossary = glossary;
        }

        if (!this.$route.query.locale) {
          this.$i18n.locale = this.locale;
        }

        document.title = this.$t('pages.compositionReport.dynamicTitle', {
          name: this.patient.user.fullName,
          date: this.$dayjs().format('DD/MM/YYYY'),
        });
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
    onSendReport() {
      this.$refs.reportModal.open();
    },
    printPage() {
      window.print();
    },
    async onChangeLocale(locale) {
      if (locale === this.$i18n.locale) return;

      await this.$router.replace({
        query: {
          locale,
        },
      });

      location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-item {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
</style>
