<template>
  <breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" />

  <q-card flat bordered>
    <q-card-section class="row items-center justify-between wrap q-pb-none">
      <div class="row items-center q-mb-md">
        <div class="text-h5">
          {{ title }}
        </div>

        <q-icon
          v-if="folder.isShared && folder.accessLevel === 'owner'"
          name="group"
          size="20px"
          class="q-ml-sm"
        />

        <q-icon
          v-if="folder.accessLevel === 'full'"
          name="lock_open"
          size="20px"
          class="q-ml-sm"
        />

        <q-icon
          v-if="folder.accessLevel === 'read-only'"
          name="lock"
          size="20px"
          class="q-ml-sm"
        />
      </div>

      <div class="row items-center wrap">
        <import-patients-btn
          v-if="
            $isAllowed('doctor/patients/import') &&
            folder.accessLevel !== 'read-only'
          "
          class="q-mr-md"
          @file-picked="onFilePicked"
        />

        <q-btn
          v-if="
            $isAllowed('doctor/folders/export') &&
            folder.accessLevel !== 'read-only'
          "
          :label="$t('pages.patients.exportButtonTitle')"
          color="primary"
          class="q-mb-md q-mr-md"
          outline
          @click="onExportFolder"
        />

        <q-btn
          v-if="
            $isAllowed('doctor/accesses/list') && folder.accessLevel === 'owner'
          "
          :label="$t('pages.patients.shareButtonTitle')"
          color="primary"
          class="q-mb-md"
          outline
          @click="openModalForSharingFolder"
        />

        <q-btn
          v-if="
            $isAllowed('doctor/accesses/reject') &&
            folder.accessLevel !== 'owner'
          "
          :label="$t('pages.patients.rejectButtonTitle')"
          color="primary"
          class="q-mb-md"
          outline
          @click="confirmFolderRejection"
        />

        <q-btn
          v-if="
            $isAllowed('doctor/patients/create') &&
            folder.accessLevel !== 'read-only'
          "
          :label="$t('pages.patients.addButtonTitle')"
          color="primary"
          class="q-mb-md q-ml-md"
          outline
          @click="openModalForAddingPatient"
        />
      </div>
    </q-card-section>

    <q-card-section v-if="error">
      <q-banner class="text-white bg-red q-mb-md" rounded>
        {{ error.message }}
      </q-banner>
    </q-card-section>

    <q-card-section v-if="log">
      <import-patients-log-table :rows="log" />
    </q-card-section>

    <q-table
      v-model:pagination="pagination"
      :columns="tableColumns"
      :rows="patients"
      :rows-per-page-options="[10, 20, 50, 100]"
      :loading="isLoading"
      :loading-label="$t('pages.patients.loading')"
      :no-data-label="$t('pages.patients.noItems')"
      row-key="userId"
      binary-state-sort
      bordered
      @request="onRequest"
      @row-click="onRowClick"
      class="q-pa-md"
    >
      <!--suppress HtmlUnknownAttribute -->
      <template v-slot:body v-if="isLoading">
        <q-tr>
          <q-td v-for="column in tableColumns" :key="column.field">
            <q-skeleton type="text" style="min-width: 20px" />
          </q-td>
        </q-tr>
      </template>

      <template v-slot:body-cell-actions="props">
        <q-td :props="props" @click.stop="">
          <q-btn flat round icon="more_vert" size="sm">
            <q-menu>
              <q-list style="min-width: 150px">
                <q-item
                  v-if="$isAllowed('doctor/patients/update')"
                  clickable
                  v-close-popup
                  @click="openModalForEditingPatient(props.row)"
                >
                  <q-item-section>
                    {{ $t('pages.patients.edit') }}
                  </q-item-section>
                </q-item>

                <q-item
                  v-if="
                    $isAllowed('doctor/patients/update') &&
                    props.row.folder.accessLevel === 'owner'
                  "
                  clickable
                  v-close-popup
                  @click="openModalForMovingPatient(props.row)"
                >
                  <q-item-section>
                    {{ $t('pages.patients.move') }}
                  </q-item-section>
                </q-item>

                <q-item
                  v-if="
                    $isAllowed('doctor/patients/delete') &&
                    props.row.folder.accessLevel === 'owner'
                  "
                  clickable
                  v-close-popup
                  @click="confirmPatientDeletion(props.row)"
                >
                  <q-item-section>
                    {{ $t('pages.patients.delete') }}
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-td>
      </template>
    </q-table>
  </q-card>

  <patient-modal
    ref="patient-modal"
    :folder-id="+$route.params.folderId"
    @patient-added="getPatients"
    @patient-updated="getPatients"
  />

  <move-modal ref="move-modal" @moved="onPatientMoved" />

  <share-modal ref="share-modal" />

  <export-folder-modal ref="export-modal" />
</template>

<script>
import { getFolderById } from '@/api/folders';
import { getPatientsAndCount, deletePatientById } from '@/api/patients';
import { reject } from '@/api/accesses';
import { importPatients } from '@/api/patients';
import PatientModal from '@/components/PatientModal.vue';
import MoveModal from '@/components/MoveModal.vue';
import ShareModal from '@/components/ShareModal.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ImportPatientsBtn from '@/components/inputs/ImportPatientsBtn.vue';
import ImportPatientsLogTable from '@/components/ImportPatientsLogTable.vue';
import ExportFolderModal from '@/components/ExportFolderModal.vue';

let initialData = {};

export default {
  components: {
    PatientModal,
    MoveModal,
    ShareModal,
    Breadcrumbs,
    ImportPatientsBtn,
    ImportPatientsLogTable,
    ExportFolderModal,
  },
  meta() {
    return {
      title: this.$t('pages.patients.documentTitle', {
        folder: this.title,
      }),
    };
  },
  data() {
    return {
      error: initialData.error,
      folder: initialData.folder,
      patients: initialData.patients,
      patientsCount: initialData.patientsCount,
      isLoading: false,
      log: null,
    };
  },
  async beforeRouteEnter(to, from, next) {
    if (
      !to.query.page ||
      !to.query.limit ||
      !to.query.sortBy ||
      !to.query.sortOrder
    ) {
      return next({
        ...to,
        query: {
          ...to.query,
          sortBy: 'full-name',
          sortOrder: 'asc',
          page: 1,
          limit: 20,
        },
      });
    }

    try {
      const folder = await getFolderById(+to.params.folderId);

      const { patients, count } = await getPatientsAndCount({
        filters: {
          query: null,
          folderId: +to.params.folderId,
        },
        sort: `${to.query.sortBy}-${to.query.sortOrder}`,
        offset: (+to.query.page - 1) * +to.query.limit,
        limit: +to.query.limit,
      });

      initialData.folder = folder;
      initialData.patients = patients;
      initialData.patientsCount = count;
    } catch (error) {
      initialData.error = error;
    } finally {
      next();
    }
  },
  async beforeRouteUpdate(to, from, next) {
    if (
      !to.query.page ||
      !to.query.limit ||
      !to.query.sortBy ||
      !to.query.sortOrder
    ) {
      return next({
        ...to,
        query: {
          ...to.query,
          sortBy: 'full-name',
          sortOrder: 'asc',
          page: 1,
          limit: 20,
        },
      });
    }

    try {
      this.error = null;
      this.isLoading = true;

      const { patients, count } = await getPatientsAndCount({
        filters: {
          query: null,
          folderId: +to.params.folderId,
        },
        sort: `${to.query.sortBy}-${to.query.sortOrder}`,
        offset: (+to.query.page - 1) * +to.query.limit,
        limit: +to.query.limit,
      });

      this.patients = patients;
      this.patientsCount = count;
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
      next();
    }
  },
  computed: {
    tableColumns() {
      const columns = [
        {
          name: 'full-name',
          field: (row) => row.user.fullName,
          label: this.$t('pages.patients.tableColumns.name'),
          align: 'left',
          sortable: true,
        },
        {
          name: 'code',
          field: 'code',
          label: this.$t('pages.patients.tableColumns.code'),
          align: 'left',
          sortable: true,
        },
        {
          name: 'date-of-birth',
          field: 'dateOfBirth',
          format: (value) => this.$dayjs(value).format('YYYY'),
          label: this.$t('pages.patients.tableColumns.dateOfBirth'),
          align: 'left',
          sortable: true,
        },
        {
          name: 'exams',
          field: 'examsCount',
          format: (value) => this.$numeral(value).format('0,0'),
          label: this.$t('pages.patients.tableColumns.examsCount'),
          align: 'center',
          sortable: true,
        },
        {
          name: 'last-exam',
          field: 'lastExamAt',
          format: (value) => value && this.$dayjs(value).format('L LT'),
          label: this.$t('pages.patients.tableColumns.lastExamAt'),
          align: 'left',
          sortable: true,
        },
        {
          name: 'is-active',
          field: (row) => row.user.isActive,
          format: (value) =>
            value
              ? this.$t('pages.patients.statuses.enabled')
              : this.$t('pages.patients.statuses.disabled'),
          label: this.$t('pages.patients.tableColumns.status'),
          align: 'left',
          sortable: true,
        },
        {
          name: 'formula',
          field: (row) => row.formulaName,
          label: this.$t('pages.patients.tableColumns.formula'),
          align: 'left',
          sortable: true,
        },
      ];

      if (this.folder.accessLevel !== 'read-only') {
        columns.push({
          name: 'actions',
          label: this.$t('pages.patients.tableColumns.actions'),
          align: 'right',
        });
      }

      return columns;
    },
    breadcrumbs() {
      return [
        {
          to: this.$getLocalizedPath({ name: 'Dashboard' }),
          label: this.$t('breadcrumbs.dashboard'),
        },
        {
          to: this.$getLocalizedPath({ name: 'FoldersIndex' }),
          label: this.$t('breadcrumbs.folders'),
        },
        {
          to: null,
          label: this.folder.title,
        },
      ];
    },
    title() {
      return this.folder?.title
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
        .join(' ');
    },
    pagination: {
      get() {
        return {
          sortBy: this.$route.query.sortBy,
          descending: this.$route.query.sortOrder === 'desc',
          page: +this.$route.query.page,
          rowsPerPage: +this.$route.query.limit,
          rowsNumber: this.patientsCount,
        };
      },
      set({ sortBy, descending, page, rowsPerPage }) {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            sortBy,
            sortOrder: descending ? 'desc' : 'asc',
            page,
            limit: rowsPerPage,
          },
        });
      },
    },
  },
  created() {},
  methods: {
    async getPatients() {
      try {
        this.error = null;
        this.isLoading = true;

        const { patients, count } = await getPatientsAndCount({
          filters: {
            query: null,
            folderId: +this.$route.params.folderId,
          },
          sort: `${this.$route.query.sortBy}-${this.$route.query.sortOrder}`,
          offset: (+this.$route.query.page - 1) * +this.$route.query.limit,
          limit: +this.$route.query.limit,
        });

        this.patients = patients;
        this.patientsCount = count;
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
    openModalForSharingFolder() {
      this.$refs['share-modal'].open(this.folder);
    },
    onRequest({ pagination: { sortBy, descending, page, rowsPerPage } }) {
      this.pagination = {
        sortBy,
        descending,
        page: page,
        rowsPerPage: rowsPerPage,
      };
    },
    onRowClick(event, patient) {
      this.openPatientExamsPage(patient);
    },
    openPatientExamsPage(patient) {
      if (
        this.$isAllowed('doctor/patients/get') &&
        this.$isAllowed('doctor/exams/list')
      ) {
        this.$router.push(
          this.$getLocalizedPath(
            `/folders/${this.$route.params.folderId}/patients/${patient.userId}`
          )
        );
      }
    },
    openModalForAddingPatient() {
      this.$refs['patient-modal'].open();
    },
    openModalForEditingPatient(patient) {
      this.$refs['patient-modal'].open(patient);
    },
    openModalForMovingPatient(patient) {
      this.$refs['move-modal'].open(patient);
    },
    onPatientMoved(patient) {
      const index = this.patients.findIndex(
        (res) => res.userId === patient.userId
      );

      this.patients.splice(index, 1);
    },
    confirmPatientDeletion(patient) {
      this.$q
        .dialog({
          title: this.$t('components.deletePatientModal.title'),
          message: this.$t('components.deletePatientModal.message', {
            title: patient.user.fullName,
          }),
          ok: this.$t('components.deletePatientModal.okButtonTitle'),
          cancel: this.$t('components.deletePatientModal.cancelButtonTitle'),
        })
        .onOk(async () => {
          try {
            await deletePatientById(patient.userId);

            const index = this.patients.findIndex(
              (res) => res.userId === patient.userId
            );

            this.patients.splice(index, 1);
          } catch (error) {
            this.error = error;
          }
        });
    },
    confirmFolderRejection() {
      this.$q
        .dialog({
          title: this.$t('components.rejectFolderModal.title'),
          message: this.$t('components.rejectFolderModal.message', {
            title: this.folder.title,
          }),
          ok: this.$t('components.rejectFolderModal.okButtonTitle'),
          cancel: this.$t('components.rejectFolderModal.cancelButtonTitle'),
        })
        .onOk(async () => {
          try {
            await reject(this.folder.id);

            this.$router.push({ name: 'FoldersIndex' });
          } catch (error) {
            this.error = error;
          }
        });
    },
    async onExportFolder() {
      this.$refs['export-modal'].open();
    },
    async onFilePicked(file) {
      try {
        this.log = null;
        this.isLoading = true;

        this.log = await importPatients(file, +this.$route.params.folderId);
        this.getPatients();
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
