<template>
  <div class="row q-col-gutter-sm">
    <q-select
      v-model="feet"
      :options="['0', '1', '2', '3', '4', '5', '6', '7']"
      :label="$t('components.imperialHeightInput.labels.feet')"
      :error="v$.feet.$error"
      :error-message="v$.feet.$errors[0]?.$message"
      class="col-4"
      hide-bottom-space
    />

    <q-select
      v-model="inches"
      :options="['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11']"
      :label="$t('components.imperialHeightInput.labels.inches')"
      :error="v$.inches.$error"
      :error-message="v$.inches.$errors[0]?.$message"
      class="col-4"
      hide-bottom-space
    />

    <q-select
      v-model="quarters"
      :options="[
        { value: '', label: '0' },
        { value: '⅛', label: '⅛' },
        { value: '¼', label: '¼' },
        { value: '⅜', label: '⅜' },
        { value: '½', label: '½' },
        { value: '⅝', label: '⅝' },
        { value: '¾', label: '¾' },
        { value: '⅞', label: '⅞' },
      ]"
      :label="$t('components.imperialHeightInput.labels.quarters')"
      :error="v$.quarters.$error"
      :error-message="v$.quarters.$errors[0]?.$message"
      class="col-4"
      emit-value
      hide-bottom-space
    />
  </div>
</template>

<script>
import useValidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  props: ['modelValue'],
  emits: ['update:modelValue'],
  data() {
    return {
      v$: useValidate(),
      feet: null,
      inches: null,
      quarters: '',
    };
  },
  validations() {
    return {
      feet: {
        required,
      },
      inches: {
        required,
      },
      quarters: {},
    };
  },
  created() {
    this.parseModelValue();
  },
  watch: {
    modelValue() {
      this.parseModelValue();
    },
    feet() {
      this.updateModelValue();
    },
    inches() {
      this.updateModelValue();
    },
    quarters() {
      this.updateModelValue();
    },
  },
  methods: {
    parseModelValue() {
      if (
        typeof this.modelValue === 'string' &&
        this.modelValue.length >= 4 &&
        this.modelValue.includes("'") &&
        this.modelValue.endsWith('"')
      ) {
        const parts = this.modelValue
          .substring(0, this.modelValue.length - 1)
          .split("'");
        this.feet = parts[0];
        this.inches = String(Number.parseInt(parts[1]));
        this.quarters = parts[1].substring(this.inches.length, parts[1].length);
      } else {
        this.feet = null;
        this.inches = null;
        this.quarters = '';
      }
    },
    updateModelValue() {
      if (this.feet && this.inches) {
        this.$emit(
          'update:modelValue',
          `${this.feet}'${this.inches}${this.quarters}"`
        );
      } else {
        this.$emit('update:modelValue', null);
      }
    },
  },
};
</script>
