<template>
  <q-card class="q-pa-lg" style="width: 400px; max-width: 80vw" flat bordered>
    <q-card-section class="text-center">
      <q-icon name="fas fa-check" size="48px" class="text-positive q-mb-md" />

      <div class="text-h5 q-mb-sm">
        {{ $t('pages.resetSuccessfully.title') }}
      </div>

      <div>
        {{ $t('pages.resetSuccessfully.description') }}
      </div>
    </q-card-section>
  </q-card>
</template>
