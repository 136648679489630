<template>
  <div class="row q-col-gutter-md">
    <div class="col-md-4 col-12">
      <div class="text-h6">
        {{ $t('components.examInfo.sections.basicTitle') }}
      </div>

      <div
        v-for="(parameter, index) of firstColumnParameters"
        :key="index"
        class="row q-col-gutter-x-md items-center q-mt-sm"
      >
        <div class="col-6">
          {{ parameter.label }}
        </div>

        <div class="col-6 text-right">
          {{ parameter.value }}
        </div>
      </div>
    </div>

    <div class="col-md-8 col-12">
      <div class="text-h6">
        {{ $t('components.examInfo.sections.measuresTitle') }}
      </div>

      <div class="row q-col-gutter-x-md">
        <div class="col-sm-6 col-12">
          <div
            v-for="(parameter, index) of secondColumnParameters"
            :key="index"
            class="row q-col-gutter-x-md items-center q-mt-sm"
          >
            <div class="col-9">
              {{ parameter.label }}
            </div>

            <div class="col-3 text-right">
              {{ parameter.value }}
            </div>
          </div>
        </div>

        <div class="col-sm-6 col-12">
          <div
            v-for="(parameter, index) of thirdColumnParameters"
            :key="index"
            class="row q-col-gutter-x-md items-center q-mt-sm"
          >
            <div class="col-9">
              {{ parameter.label }}
            </div>

            <div class="col-3 text-right">
              {{ parameter.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    exam: {
      type: Object,
      required: true,
    },
    patient: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatNumber: function (value, format = '0.00,0') {
      return typeof value === 'string'
        ? value
        : this.$numeral(value).format(format);
    },
  },
  computed: {
    firstColumnParameters() {
      return [
        {
          label: this.$t('components.examInfo.sections.labels.gender'),
          value: this.$t(`components.examInfo.genders.${this.patient.gender}`),
        },
        {
          label: this.$t('components.examInfo.sections.labels.age'),
          value: this.patient.age,
        },
        {
          label: this.$t('components.examInfo.sections.labels.dateOfBirth'),
          value: this.$dayjs(this.patient.dateOfBirth).format('YYYY'),
        },
        {
          label: this.$t('components.examInfo.sections.labels.date'),
          value: this.$dayjs(this.exam.createdAt).format('L'),
        },
      ];
    },
    secondColumnParameters() {
      return [
        {
          label: this.$t('components.examInfo.sections.labels.height', {
            unit: this.exam.units.height,
          }),
          value: this.formatNumber(this.exam.params.height, '0,0'),
        },
        {
          label: this.$t('components.examInfo.sections.labels.weight', {
            unit: this.exam.units.weight,
          }),
          value: this.$numeral(this.exam.params.weight).format('0,0.0'),
        },
        {
          label: this.$t('components.examInfo.sections.labels.waistToHeight'),
          value: `${this.$numeral(this.exam.params.waistToHeight).format(
            '0,0.0'
          )}%`,
        },
        {
          label: this.$t('components.examInfo.sections.labels.chest', {
            unit: this.exam.units.chest,
          }),
          value: this.formatNumber(this.exam.params.chest),
        },
      ];
    },
    thirdColumnParameters() {
      return [
        {
          label: this.$t('components.examInfo.sections.labels.belly', {
            unit: this.exam.units.belly,
          }),
          value: this.formatNumber(this.exam.params.belly),
        },
        {
          label: this.$t('components.examInfo.sections.labels.hip', {
            unit: this.exam.units.hip,
          }),
          value: this.formatNumber(this.exam.params.hip),
        },
        {
          label: this.$t('components.examInfo.sections.labels.thigh', {
            unit: this.exam.units.thigh,
          }),
          value: this.formatNumber(this.exam.params.thigh),
        },
        {
          label: this.$t('components.examInfo.sections.labels.calf', {
            unit: this.exam.units.calf,
          }),
          value: this.formatNumber(this.exam.params.calf),
        },
      ];
    },
  },
};
</script>
