import { render } from "./PatientDetails.vue?vue&type=template&id=46ea46f0"
import script from "./PatientDetails.vue?vue&type=script&lang=js"
export * from "./PatientDetails.vue?vue&type=script&lang=js"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QInput,QIcon,QCheckbox});
