<template>
  <q-dialog v-model="isVisible" persistent>
    <q-card style="width: 400px; max-width: 80vw">
      <q-card-section>
        <h6 class="q-ma-none">
          {{ $t('components.exportFolderModal.title') }}
        </h6>
      </q-card-section>
      <q-card-section class="q-pt-none" style="white-space: pre-wrap">
        <p>
          {{ $t('components.exportFolderModal.message') }}
        </p>
      </q-card-section>
      <q-card-actions class="q-pt-none row justify-end">
        <q-btn
          :label="$t('components.exportFolderModal.cancelButtonTitle')"
          flat
          color="primary"
          @click="close"
        />
        <q-btn
          :label="$t('components.exportFolderModal.declineButtonTitle')"
          flat
          color="primary"
          @click="onSubmit(false)"
        />
        <q-btn
          :label="$t('components.exportFolderModal.okButtonTitle')"
          flat
          color="primary"
          @click="onSubmit(true)"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { save } from 'save-file';
import { exportFolder } from '@/api/folders';

export default {
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    open() {
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async onSubmit(includePersonalInfo) {
      try {
        this.isLoading = true;

        const json = await exportFolder(
          this.$route.params.folderId,
          includePersonalInfo
        );

        save(json, 'folder.csv');

        this.close();
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          message: error.message,
          position: 'center',
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
