<template>
  <q-dialog v-model="isVisible" persistent>
    <q-card style="width: 400px; max-width: 80vw">
      <q-card-section>
        <h6 class="q-ma-none">
          {{ $t('components.sendReportModal.title') }}
        </h6>
      </q-card-section>
      <q-card-section class="q-pt-none" style="white-space: pre-wrap">
        <p class="q-mb-none">
          {{ $t('components.sendReportModal.message') }}
        </p>
      </q-card-section>
      <q-card-actions class="q-pt-none row justify-end">
        <q-btn
          :label="$t('components.sendReportModal.cancelBtn')"
          flat
          color="primary"
          @click="close"
        />
        <q-btn
          :label="$t('components.sendReportModal.sendToMeBtn')"
          flat
          color="primary"
          @click="onSendReport('me')"
        />
        <q-btn
          :label="$t('components.sendReportModal.sendToSubjectBtn')"
          flat
          color="primary"
          @click="onSendReport('subject')"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { publishExam } from '@/api/exams';

export default {
  emits: ['file-picked'],
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    open() {
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    async onSendReport(recipient) {
      try {
        await publishExam(+this.$route.params.examId, recipient);

        this.$q.notify({
          type: 'positive',
          message: this.$t('pages.compositionReport.sendReportSuccessMessage'),
          position: 'center',
        });

        this.close();
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          message: error.message,
          position: 'center',
        });
      }
    },
  },
};
</script>
