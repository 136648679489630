<template>
  <q-dialog v-model="isVisible">
    <q-card style="width: 400px; max-width: 80vw">
      <q-form
        autocorrect="off"
        autocapitalize="off"
        autocomplete="off"
        spellcheck="false"
        @submit="onSubmit"
      >
        <q-card-section
          class="row items-center justify-between bg-primary text-white"
        >
          <span class="text-h6">
            {{ $t('components.rejectPhotoModal.title') }}
          </span>

          <q-btn
            :disable="isLoading"
            icon="close"
            flat
            round
            dense
            v-close-popup
          />
        </q-card-section>

        <q-separator />

        <q-card-section v-if="error" class="q-pt-none">
          <q-banner class="text-white bg-red" rounded>
            {{ error.message }}
          </q-banner>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="row q-col-gutter-md">
            <div class="col-12">
              <q-input
                v-model.trim="reason"
                :label="$t('components.rejectPhotoModal.labels.reason')"
                :error="v$.reason.$error"
                :error-message="$t('components.rejectPhotoModal.errors.reason')"
                type="textarea"
                hide-bottom-space
              />
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn :disable="isLoading" color="primary" flat @click="close">
            {{ $t('components.rejectPhotoModal.cancelButtonTitle') }}
          </q-btn>

          <q-btn
            :loading="isLoading"
            :label="$t('components.rejectPhotoModal.rejectButtonTitle')"
            type="submit"
            color="primary"
            flat
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import useValidate from '@vuelidate/core';

import { rejectPhoto } from '@/api/photos';

export default {
  props: {
    photo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
      isVisible: false,
      isLoading: false,
      error: null,
      reason: null,
    };
  },
  validations: {
    reason: {},
  },
  methods: {
    open() {
      this.error = null;
      this.reason = null;

      this.v$.$reset();

      this.isLoading = false;
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    async onSubmit() {
      this.error = null;

      this.v$.$touch();

      if (!this.v$.$invalid) {
        try {
          this.isLoading = true;

          await rejectPhoto({
            id: this.photo.id,
            reason: this.reason,
          });

          this.$emit('rejected');

          this.close();
        } catch (error) {
          this.error = error;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
