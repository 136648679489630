<template>
  <div>
    <q-banner v-if="error" class="text-white bg-red q-mb-md" rounded>
      {{ error.message }}
    </q-banner>

    <q-table
      v-model:pagination="pagination"
      :columns="tableColumns"
      :rows="history"
      :rows-per-page-options="[5, 10, 20, 50]"
      :loading="isLoading"
      :loading-label="$t('components.accountCreditsHistory.loading')"
      :no-data-label="$t('components.accountCreditsHistory.noItems')"
      row-key="id"
      flat
      bordered
      @request="onRequest"
    />
  </div>
</template>

<script>
import { getCreditsHistory } from '@/api/credits';

export default {
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      pagination: {
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 0,
      },
      history: [],
      isLoading: false,
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          name: 'dateTime',
          field: 'createdAt',
          format: (value) => this.$dayjs(value).format('L LT'),
          label: this.$t(
            'components.accountCreditsHistory.tableColumns.dateTime'
          ),
          align: 'left',
        },
        {
          name: 'amount',
          field: 'change',
          format: (value) => this.$numeral(value).format('0,0'),
          label: this.$t(
            'components.accountCreditsHistory.tableColumns.amount'
          ),
          align: 'center',
        },
        {
          name: 'direction',
          label: this.$t(
            'components.accountCreditsHistory.tableColumns.direction'
          ),
          format: (value, row) =>
            `${row.sourceFullName || ''} → ${row.destinationFullName || ''}`,
          align: 'left',
        },
      ];
    },
  },
  created() {
    this.onRequest({ pagination: this.pagination });
  },
  methods: {
    async onRequest({ pagination: { page, rowsPerPage } }) {
      try {
        this.isLoading = true;
        this.error = null;

        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;

        const { history, count } = await getCreditsHistory({
          filters: {
            userId: this.userId,
          },
          offset: (page - 1) * rowsPerPage,
          limit: rowsPerPage,
        });

        this.history = history;
        this.pagination.rowsNumber = count;
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
