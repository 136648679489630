<template>
  <app-chart
    type="bar"
    :data="energyLevels"
    :options="options"
    :plugins="plugins"
  />
</template>

<script>
import _ from 'lodash';
import { colors, getCssVar } from 'quasar';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import AppChart from '@/components/AppChart';

export default {
  components: {
    AppChart,
  },
  props: {
    exam: {
      required: true,
      type: Object,
    },
  },
  computed: {
    energyLevels() {
      let properties = Object.getOwnPropertyNames(this.exam?.params).filter(
        (item) => _.startsWith(item, 'totalEnergy')
      );

      return {
        labels: properties.map((item) =>
          this.$t(`components.energyLevels.${item}`)
        ),
        datasets: [
          {
            label: 'Levels',
            data: _.flatten(
              properties.map(
                (item) => +this.$numeral(this.exam?.params[item]).format('0')
              )
            ),
            backgroundColor: colors.changeAlpha(getCssVar('primary'), 0.3),
          },
        ],
      };
    },
    options() {
      return {
        plugins: {
          legend: {
            display: false,
          },
        },
      };
    },
    plugins() {
      return [ChartDataLabels];
    },
  },
  created() {
    Chart.register(ChartDataLabels);
  },
};
</script>
