<template>
  <div v-if="contracts.length">
    <h2 class="text-h6 text-center text-blue-grey-10">
      {{ $t('pages.contracts.title') }}
    </h2>

    <q-btn
      v-if="isVisibleAcceptButton"
      @click="scrollToElement($refs.contractText.$el)"
      style="position: fixed; left: 2%; bottom: 80px; z-index: 11"
      color="primary"
      unelevated
    >
      <q-icon left size="2em" name="keyboard_double_arrow_down" />
      <div>
        {{ $t('pages.contracts.scrollBtn') }}
      </div>
    </q-btn>

    <q-tabs
      v-model="tab"
      v-if="contracts.length > 1"
      class="text-primary q-mb-lg"
    >
      <q-tab
        v-for="contract in contracts"
        :key="contract.id"
        :name="contract.id"
      >
        {{ contract.title }}
      </q-tab>
    </q-tabs>

    <q-tab-panels v-model="tab" animated>
      <q-tab-panel
        class="q-pa-lg"
        ref="contractText"
        v-for="contract in contracts"
        :key="contract.id"
        :name="contract.id"
      >
        <h2 class="text-h4 q-mt-none">
          {{ contract.title }}
        </h2>

        <div class="text-left q-mb-lg" v-html="contract.content"></div>

        <div class="text-left q-mb-sm">
          {{ $dayjs(contract.createdAt).format('L LT') }}
        </div>

        <q-separator />

        <div class="q-mt-md row justify-end">
          <q-btn
            color="primary"
            unelevated
            @click="confirmAgreementSignIn(contract)"
            ref="acceptBtn"
          >
            {{ $t('pages.contracts.signIn') }}
          </q-btn>
        </div>
      </q-tab-panel>
    </q-tab-panels>
  </div>

  <div class="text-center q-pa-lg" v-else-if="!isLoading">
    <h2 class="text-h5">
      {{ $t('pages.contracts.emptyContractsListMessage') }}
    </h2>
  </div>
</template>

<script>
import { getUnsignedDocs, setDocAsSigned } from '@/api/agreement';
import { Loading } from 'quasar';
import router from '@/router';

export default {
  data() {
    return {
      contracts: [],
      tab: 0,
      isLoading: false,
      isVisibleAcceptButton: true,
    };
  },
  methods: {
    async getDocuments() {
      try {
        this.isLoading = true;
        Loading.show();

        const response = await getUnsignedDocs(
          'U98UNDvGK-hpLlMkAfrV34ldMPoDllgb'
        );

        this.contracts = response;
        this.tab = this.contracts[0].id;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
        Loading.hide();
      }
    },

    async sendAgreement(id) {
      try {
        await setDocAsSigned('Q5Cl_TLQj7H82XU7Ad5n-UWW09-fEsEM', id);

        this.contracts = this.contracts.filter(
          (contract) => contract.id !== id
        );
        this.tab = this.contracts[0].id;
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } catch (error) {
        console.log(error);
      } finally {
        if (!this.contracts.length) {
          router.push('/');
        }
      }
    },

    confirmAgreementSignIn(contract) {
      this.$q
        .dialog({
          title: this.$t('pages.contracts.confirmSignIn.title'),
          message: this.$t('pages.contracts.confirmSignIn.message', {
            title: contract.title,
          }),
          ok: this.$t('pages.contracts.confirmSignIn.ok'),
          cancel: this.$t('pages.contracts.confirmSignIn.cancel'),
        })
        .onOk(async () => {
          try {
            this.sendAgreement(contract.id);
          } catch (error) {
            this.$q.notify({
              type: 'negative',
              position: 'center',
              message: `${error.message}`,
            });
          }
        });
    },
    scrollToElement(el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'end' });
    },
    showScrollBtn(el) {
      el.getBoundingClientRect().y > window.screen.height
        ? (this.isVisibleAcceptButton = true)
        : (this.isVisibleAcceptButton = false);
    },
  },
  mounted() {
    this.getDocuments();

    addEventListener('scroll', () => {
      this.showScrollBtn(this.$refs.acceptBtn.$el);
    });
  },
  updated() {
    this.showScrollBtn(this.$refs.acceptBtn.$el);
  },
};
</script>
