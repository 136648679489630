<template>
  <div class="relative-position q-mt-sm">
    <div class="row">
      <div
        v-for="(interval, index) of colorIntervals"
        :key="index"
        :style="{
          width: interval.width,
          backgroundColor: interval.color,
        }"
        style="
          height: 0.375rem;
          -webkit-print-color-adjust: exact;
          color-adjust: exact;
        "
      ></div>
    </div>

    <div class="absolute-full row">
      <div
        v-for="(interval, index) of scaleIntervals"
        :key="index"
        class="relative-position"
        :style="{
          width: interval.width,
        }"
        style="border-right: 1px solid #ffffff; border-left: 1px solid #ffffff"
      >
        <span
          class="absolute-top-left"
          style="transform: translate(-50%, -100%)"
        >
          {{ interval.label }}
        </span>
      </div>
    </div>

    <div
      class="absolute"
      :style="{
        left: currentValueOffset,
        borderBottom: `0.375rem solid ${primaryColor}`,
      }"
      style="
        bottom: -0.5rem;
        width: 0;
        height: 0;
        border-right: 0.375rem solid transparent;
        border-left: 0.375rem solid transparent;
        transform: translate(-50%, 0);
      "
    ></div>
  </div>
</template>

<script>
import { getCssVar, colors } from 'quasar';

export default {
  props: {
    currentValue: {
      type: Number,
      required: true,
    },
    ranges: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      primaryColor: getCssVar('primary'),
    };
  },
  computed: {
    isAllRangesGray() {
      for (let range of this.ranges) {
        if (typeof range.color === 'string') {
          return false;
        }
      }

      return true;
    },
    firstRangeStartValue() {
      return this.ranges[0].start;
    },
    firstRangeEndValue() {
      return this.ranges[0].end;
    },
    lastRangeStartValue() {
      return this.ranges[this.ranges.length - 1].start;
    },
    lastRangeEndValue() {
      return this.ranges[this.ranges.length - 1].end;
    },
    externalRangesLength() {
      return Math.ceil(
        (this.lastRangeStartValue - this.firstRangeEndValue) / 5
      );
    },
    calculatedFirstRangeStartValue() {
      if (this.firstRangeStartValue) {
        return this.firstRangeStartValue;
      }

      if (this.isAllRangesGray) {
        return Math.min(
          this.firstRangeEndValue - this.externalRangesLength,
          Math.floor(this.currentValue)
        );
      }

      return this.firstRangeEndValue - this.externalRangesLength;
    },
    calculatedLastRangeEndValue() {
      if (this.lastRangeEndValue) {
        return this.lastRangeEndValue;
      }

      if (this.isAllRangesGray) {
        return Math.max(
          this.lastRangeStartValue + this.externalRangesLength,
          Math.ceil(this.currentValue)
        );
      }

      return this.lastRangeStartValue + this.externalRangesLength;
    },
    totalRangesLength() {
      return (
        this.calculatedLastRangeEndValue - this.calculatedFirstRangeStartValue
      );
    },
    colorIntervals() {
      return this.ranges.map((range, index) => {
        if (index === 0) {
          range.start = this.calculatedFirstRangeStartValue;
        }

        if (index === this.ranges.length - 1) {
          range.end = this.calculatedLastRangeEndValue;
        }

        return {
          width: `${
            ((range.end - range.start) / this.totalRangesLength) * 100
          }%`,
          color: this.getColorFromLetter(range.color),
        };
      });
    },
    scaleStep() {
      const scaleStepVariants = [1, 2, 3, 4, 5, 10, 20, 50, 100];

      for (let i = 0; i < scaleStepVariants.length; i++) {
        const numberOfElementsInScale = Math.ceil(
          this.totalRangesLength / scaleStepVariants[i]
        );

        if (numberOfElementsInScale <= 10) {
          return scaleStepVariants[i];
        }
      }

      return 1;
    },
    firstShortPart() {
      return this.calculatedFirstRangeStartValue % this.scaleStep;
    },
    lastShortPart() {
      return this.calculatedLastRangeEndValue % this.scaleStep;
    },
    scaleIntervals() {
      const intervals = [];
      const fullPartsCount =
        (this.calculatedLastRangeEndValue -
          this.lastShortPart -
          this.calculatedFirstRangeStartValue +
          this.firstShortPart) /
        this.scaleStep;
      const totalPartsLength =
        this.firstShortPart +
        fullPartsCount * this.scaleStep +
        this.lastShortPart;

      if (this.firstShortPart !== 0) {
        intervals.push({
          width: `${(this.firstShortPart / totalPartsLength) * 100}%`,
        });
      }

      for (
        let point = this.calculatedFirstRangeStartValue - this.firstShortPart;
        point < this.calculatedLastRangeEndValue - this.lastShortPart;
        point += this.scaleStep
      ) {
        intervals.push({
          width: `${(this.scaleStep / totalPartsLength) * 100}%`,
          label: point !== this.calculatedFirstRangeStartValue ? point : null,
        });
      }

      if (this.lastShortPart !== 0) {
        intervals.push({
          width: `${(this.lastShortPart / totalPartsLength) * 100}%`,
          label: this.calculatedLastRangeEndValue - this.lastShortPart,
        });
      }

      return intervals;
    },
    currentValueOffset() {
      let offset =
        ((this.currentValue -
          (this.calculatedFirstRangeStartValue - this.firstShortPart)) /
          this.totalRangesLength) *
        100;

      offset = Math.min(100, Math.max(0, offset));

      return `${offset}%`;
    },
  },
  methods: {
    getColorFromLetter(letter) {
      const color = {
        G: getCssVar('positive'),
        Y: getCssVar('warning'),
        R: getCssVar('negative'),
      }[letter];

      return color ? color : colors.getPaletteColor('grey-5');
    },
  },
};
</script>
