<template>
  <div class="row q-mb-md">
    <div class="col">
      <h6 class="q-ma-none">
        {{ $t('components.accountImportExportTab.exportTitle') }}
      </h6>
      {{ $t('components.accountImportExportTab.exportDescription') }}
    </div>
    <div class="col text-right">
      <q-btn
        v-if="$isAllowed('doctor/account/export')"
        :label="$t('components.accountImportExportTab.exportButtonTitle')"
        :loading="isExporting"
        color="primary"
        class="q-mb-md"
        outline
        @click="exportToJSON"
      />
    </div>
  </div>
</template>

<script>
import { save } from 'save-file';

import { exportToJSON } from '@/api/account';

export default {
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isExporting: false,
    };
  },
  created() {
    this.updateTitle();
  },
  watch: {},
  methods: {
    updateTitle() {
      this.$emit(
        'update:title',
        this.$t('components.accountImportExportTab.title')
      );
    },
    async exportToJSON() {
      try {
        this.isExporting = true;

        const json = await exportToJSON({});

        await save(
          new Blob([JSON.stringify(json, null, 2)], {
            type: 'application/json',
          }),
          'data.json'
        );
      } catch (error) {
        this.$q.notify({
          type: 'negative',
          message: error.message,
          position: 'center',
        });
      } finally {
        this.isExporting = false;
      }
    },
  },
};
</script>
