<template>
  <q-card-section v-if="appearance" class="q-pa-none q-mb-md">
    <div class="row no-wrap items-center">
      <img
        v-if="appearance.logo"
        :src="appearance.logo"
        class="q-mr-md"
        style="
          max-height: 64px;
          max-width: min(320px, 50%);
          object-fit: contain;
        "
      />

      <div v-html="appearance.header" class="text-md-h4 text-h5" />
    </div>
  </q-card-section>
  <q-card-section
    v-else-if="accountInfo?.logo || accountInfo?.reportHeader"
    class="q-pa-none q-mb-md"
  >
    <div class="row no-wrap items-center">
      <img
        v-if="accountInfo.logo"
        :src="accountInfo.logo"
        class="q-mr-md"
        style="
          max-height: 64px;
          max-width: min(320px, 50%);
          object-fit: contain;
        "
      />

      <div v-html="accountInfo.reportHeader" class="text-h4" />
    </div>
  </q-card-section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    appearance: {
      type: [Object, null],
    },
  },
  computed: {
    ...mapGetters({
      accountInfo: 'account/info',
    }),
  },
};
</script>
