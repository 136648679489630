const stats = {
  namespaced: true,
  state: {
    pendingPhotosCount: 0,
    totalMessagesCount: 0,
    messages: [],
  },
  getters: {
    totalMessagesCount: (state) => state.totalMessagesCount,
    pendingPhotosCount: (state) => state.pendingPhotosCount,
    recent:
      (state) =>
      (id = null) => {
        if (id) {
          return state.messages.find((item) => +item.senderId === +id);
        }
        return state.messages;
      },
  },
  mutations: {
    setRecent(state, payload) {
      state.totalMessagesCount = payload.totalCount;
      state.messages = payload.messages;
    },
    setPendingPhotos(state, photos) {
      state.pendingPhotosCount = photos;
    },
  },
};

export { stats };
