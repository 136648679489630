import { render } from "./RejectPhotoModal.vue?vue&type=template&id=4e16407f"
import script from "./RejectPhotoModal.vue?vue&type=script&lang=js"
export * from "./RejectPhotoModal.vue?vue&type=script&lang=js"
script.render = render

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QForm,QCardSection,QBtn,QSeparator,QBanner,QInput,QCardActions});qInstall(script, 'directives', {ClosePopup});
