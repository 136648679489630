import axios from '@/helpers/axios';

export function getList(folderId) {
  return axios
    .post('/doctor/accesses/list', {
      folderId,
    })
    .then(({ data }) => {
      return data.results;
    });
}

export function grant(options) {
  return axios.post('/doctor/accesses/grant', options).then(({ data }) => {
    return data.access;
  });
}

export function update(options) {
  return axios.post('/doctor/accesses/update', options).then(({ data }) => {
    return data.access;
  });
}

export function remove(id) {
  return axios.post('/doctor/accesses/revoke', {
    id,
  });
}

export function reject(folderId) {
  return axios.post('/doctor/accesses/reject', {
    folderId,
  });
}
