import { render } from "./PatientModal.vue?vue&type=template&id=42f01dbc&scoped=true"
import script from "./PatientModal.vue?vue&type=script&lang=js"
export * from "./PatientModal.vue?vue&type=script&lang=js"

import "./PatientModal.vue?vue&type=style&index=0&id=42f01dbc&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-42f01dbc"

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QField from 'quasar/src/components/field/QField.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QForm,QCardSection,QBtn,QSeparator,QBanner,QIcon,QInput,QSelect,QCheckbox,QCardActions,QField});qInstall(script, 'directives', {ClosePopup});
