import axios from '@/helpers/axios';

export function getExamsAndCount(options) {
  return axios.post('/doctor/exams/list', options).then(({ data }) => {
    return {
      exams: data.results,
      count: data.count,
    };
  });
}

export function getExamById(id) {
  return axios
    .post('/doctor/exams/get', {
      id,
    })
    .then(({ data }) => {
      return data.exam;
    });
}

export function updateExam(exam) {
  return axios
    .post('/doctor/exams/update', {
      id: exam.id,
      exam,
    })
    .then(({ data }) => {
      return data.exam;
    });
}

export function deleteExamById(id) {
  return axios.post('/doctor/exams/delete', {
    id,
  });
}

export function getComparisonImage(...ids) {
  return axios
    .post('/doctor/exams/compare', {
      exams: ids,
    })
    .then(({ data }) => {
      return data.image;
    });
}

export function publishExam(id, recipient) {
  return axios
    .post('/doctor/exams/publish', {
      id,
      recipient,
    })
    .then(({ data }) => {
      return data.token;
    });
}
