import axios from '@/helpers/axios';

export function getCompositionReportByExamId(examId, locale) {
  const data = {
    examId,
  };

  if (locale) {
    data.meta = { locale };
  }

  return axios
    .post('/doctor/reports/composition', data)
    .then(({ data }) => data);
}

export function getFollowUpReportByPatientId(patientId) {
  return axios
    .post('/doctor/reports/follow-up', {
      patientId,
    })
    .then(({ data }) => {
      return {
        patient: data.patient,
        exams: data.exams,
      };
    });
}

export function getPublicCompositionReport(token, locale) {
  const data = {
    token,
  };

  if (locale) {
    data.meta = { locale };
  }

  return axios.post('/public/reports/composition', data).then(({ data }) => {
    return data;
  });
}
