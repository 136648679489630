<template>
  <div>
    <q-btn
      :label="$t('components.accountImportExportTab.importButtonTitle')"
      color="primary"
      class="q-mb-md"
      outline
      @click="onOpenModal"
    />

    <import-patients-modal ref="modal" @file-picked="onFilePicked" />
  </div>
</template>

<script>
import ImportPatientsModal from '../ImportPatientsModal.vue';
export default {
  components: { ImportPatientsModal },
  emits: ['file-picked'],
  methods: {
    onOpenModal() {
      this.$refs.modal.open();
    },
    onFilePicked(file) {
      this.$emit('file-picked', file);
    },
  },
};
</script>

<style></style>
