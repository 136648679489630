import { render } from "./Breadcrumbs.vue?vue&type=template&id=7b7a7bd4"
import script from "./Breadcrumbs.vue?vue&type=script&lang=js"
export * from "./Breadcrumbs.vue?vue&type=script&lang=js"
script.render = render

export default script
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBreadcrumbs,QBreadcrumbsEl,QSpinnerDots});
