import axios from '@/helpers/axios';

export function getUnsignedDocs(token) {
  return axios.post('/doctor/agreements/unsigned', token).then(({ data }) => {
    return data.results;
  });
}

export function setDocAsSigned(token, id) {
  return axios
    .post('/doctor/agreements/sign', { token, id })
    .then(({ data }) => {
      return data;
    });
}
