import { render } from "./FollowUpReport.vue?vue&type=template&id=03af5c94"
import script from "./FollowUpReport.vue?vue&type=script&lang=js"
export * from "./FollowUpReport.vue?vue&type=script&lang=js"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QSpace,QBtn,QBanner,QSeparator});
