import { render } from "./ExamsCompare.vue?vue&type=template&id=767d0ad4"
import script from "./ExamsCompare.vue?vue&type=script&lang=js"
export * from "./ExamsCompare.vue?vue&type=script&lang=js"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QBanner,QSeparator,QImg,QBadge});
