<template>
  <breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" />
  <q-card v-if="patient && patient.photo" flat bordered class="q-mb-lg">
    <q-card-section class="row items-center justify-between no-wrap">
      <div class="row items-center no-wrap">
        <img
          :src="patient.photo.file.sm"
          alt=""
          height="80"
          class="cursor-pointer"
          @click="openModalToApprovePhoto"
        />

        <div class="q-ml-md">
          {{ $t('pages.patient.approveImageMessage') }}
        </div>
      </div>

      <q-card-actions class="no-wrap">
        <q-btn
          :label="$t('pages.patient.approveButtonTitle')"
          color="primary"
          outline
          @click="openModalToApprovePhoto"
        />

        <q-btn
          :label="$t('pages.patient.rejectButtonTitle')"
          color="negative"
          outline
          @click="openModalToRejectPhoto"
        />
      </q-card-actions>
    </q-card-section>
  </q-card>

  <q-card flat bordered>
    <q-card-section class="row items-center justify-between wrap q-pb-none">
      <div class="row items-center q-mb-md">
        <div class="text-h5 q-ml-md q-mt-none">
          {{ patient?.user.fullName }}
          <q-spinner-dots v-if="isLoading" />
        </div>

        <q-icon
          v-if="
            patient &&
            patient.folder.isShared &&
            patient.folder.accessLevel === 'owner'
          "
          name="group"
          size="20px"
          class="q-ml-sm"
        />

        <q-icon
          v-if="patient && patient.folder.accessLevel === 'full'"
          name="lock_open"
          size="20px"
          class="q-ml-sm"
        />

        <q-icon
          v-if="patient && patient.folder.accessLevel === 'read-only'"
          name="lock"
          size="20px"
          class="q-ml-sm"
        />
      </div>

      <div class="row items-center wrap overflow-auto">
        <q-tabs
          v-model="tab"
          class="q-mb-md"
          dense
          narrow-indicator
          stretch
          active-color="primary"
          @update:model-value="onChangeTab"
        >
          <q-tab
            :label="$t('pages.patient.tabs.exams.title')"
            name="exams"
            :disable="isLoading"
          />
          <q-tab
            :label="$t('pages.patient.tabs.details.title')"
            name="details"
            :disable="isLoading"
          />
          <q-tab
            :label="$t('pages.patient.tabs.appointments.title')"
            name="appointments"
            :disable="isLoading"
          />
          <q-tab
            :label="$t('pages.patient.tabs.messages.title')"
            name="messages"
            :disable="isLoading"
          />
        </q-tabs>
      </div>
    </q-card-section>

    <q-separator />

    <q-card-section v-if="error">
      <q-banner class="text-white bg-red q-mb-md" rounded>
        {{ error.message }}
      </q-banner>
    </q-card-section>

    <q-tab-panels v-if="patient" v-model="tab" animated>
      <q-tab-panel name="exams">
        <patient-exams :patient-id="patient.userId" ref="exams" />
      </q-tab-panel>
      <q-tab-panel name="details">
        <patient-details :patient-id="patient.userId" ref="details" />
      </q-tab-panel>
      <q-tab-panel name="appointments">
        <patient-appointments
          :patient="patient"
          :patient-id="patient.userId"
          ref="appointments"
        />
      </q-tab-panel>
      <q-tab-panel name="messages">
        <patient-chat :patient="patient" />
      </q-tab-panel>
    </q-tab-panels>
  </q-card>

  <approve-photo-modal
    ref="approve"
    v-if="patient?.photo"
    :account-balance="accountBalance"
    :photo="patient.photo"
    @approved="onPhotoApproved"
  />

  <reject-photo-modal
    ref="reject"
    v-if="patient?.photo"
    :photo="patient.photo"
    @rejected="onPhotoRejected"
  />
</template>

<script>
import ApprovePhotoModal from '@/components/ApprovePhotoModal';
import RejectPhotoModal from '@/components/RejectPhotoModal';
import { getPatientById } from '@/api/patients';
import PatientAppointments from '@/components/PatientAppointments';
import PatientDetails from '@/components/PatientDetails';
import PatientExams from '@/components/PatientExams';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import PatientChat from '@/components/PatientChat.vue';

export default {
  components: {
    Breadcrumbs,
    PatientExams,
    PatientDetails,
    PatientAppointments,
    ApprovePhotoModal,
    RejectPhotoModal,
    PatientChat,
  },
  meta() {
    return {
      title: this.$t('pages.patient.documentTitle', {
        patientName: this.patient?.user.fullName,
      }),
    };
  },
  async created() {
    await this.getPatient();

    if (this.$route.query.action === 'open-chat') {
      this.$refs['chat-fab'].show();
    }
  },
  data() {
    return {
      tab: 'exams',
      buttons: null,
      error: null,
      accountBalance: null,
      patient: null,
      isLoading: false,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          to: this.$getLocalizedPath({ name: 'Dashboard' }),
          label: this.$t('breadcrumbs.dashboard'),
        },
        {
          to: this.$getLocalizedPath({ name: 'FoldersIndex' }),
          label: this.$t('breadcrumbs.folders'),
        },
        {
          to: this.$getLocalizedPath({
            name: 'PatientsIndex',
            params: { ':folderId': this.patient?.folderId },
          }),
          label: this.patient?.folder.title,
        },
        {
          to: null,
          label: this.patient?.user.fullName,
        },
      ];
    },
  },
  watch: {
    '$route.hash': {
      handler(val) {
        this.tab = val ? val.replace('#', '') : 'exams';
      },
      immediate: true,
    },
    async '$route.params.patientId'(value) {
      if (value) {
        await this.getPatient();
      }
    },
    tab: function () {
      this.refreshButtons();
    },
  },
  methods: {
    onChangeTab(val) {
      this.$router.push({ ...this.$route, hash: `#${val}` });
    },
    async getPatient() {
      try {
        this.error = null;
        this.isLoading = true;

        const { patient, accountBalance } = await getPatientById(
          +this.$route.params.patientId
        );

        this.accountBalance = accountBalance;
        this.patient = patient;

        this.refreshButtons();
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
    openModalToApprovePhoto() {
      this.$refs.approve.open();
    },
    openModalToRejectPhoto() {
      this.$refs.reject.open();
    },
    async onPhotoApproved() {
      this.patient.photo = null;

      this.getPatient();

      if (this.tab === 'exams') {
        this.$refs.exams.getExams();
      }
    },
    onPhotoRejected() {
      this.patient.photo = null;
    },
    refreshButtons() {
      this.$nextTick(() => {
        this.buttons = this.$refs[this.tab].buttons;
      });
    },
  },
};
</script>
