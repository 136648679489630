import { render } from "./PatientAppointments.vue?vue&type=template&id=6ccec944"
import script from "./PatientAppointments.vue?vue&type=script&lang=js"
export * from "./PatientAppointments.vue?vue&type=script&lang=js"
script.render = render

export default script
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBanner,QTable,QBtn,QTd,QMenu,QList,QItem,QItemSection});qInstall(script, 'directives', {ClosePopup});
