import _ from 'lodash';
import axios from '@/helpers/axios';

export function signIn({ email, password }) {
  return axios
    .post('/doctor/account/sign-in', {
      email,
      password,
    })
    .then(({ data }) => {
      return {
        ...data.doctor,
        permissions: data.permissions,
      };
    });
}

export function restorePassword({ email }) {
  return axios.post('/account/password/forgot', {
    email,
  });
}

export function resetPassword({ token, password }) {
  return axios
    .post('/account/password/reset', {
      recoveryToken: token,
      password,
    })
    .then(({ data }) => data.role);
}

export function exportToJSON(options) {
  return axios.post('/doctor/account/export', options).then(({ data }) => {
    return data.data;
  });
}

export function getAccount() {
  return axios.post('/doctor/account/get', {}).then(({ data }) => {
    return data.doctor;
  });
}

export function updateAccount(doctor) {
  const options = {
    doctor: _.cloneDeep(doctor),
  };

  if (!doctor.user.password) {
    delete options.doctor.user.password;
  }

  if (typeof doctor.logo === 'string' && doctor.logo.startsWith('data:image')) {
    options.logo = doctor.logo.replace('data:image/png;base64,', '');
    delete options.doctor.logo;
  }

  return axios.post('/doctor/account/update', options).then(({ data }) => {
    return data.doctor;
  });
}
