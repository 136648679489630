<template>
  <div :style="{ height, width }">
    <canvas ref="chart-canvas"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    plugins: {
      type: Object,
      default() {
        return {};
      },
    },
    height: {
      type: String,
      default: 'auto',
    },
    width: {
      type: String,
      default: 'auto',
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    data() {
      this.destroyChart();

      this.createChart();
    },
  },
  mounted() {
    this.createChart();
  },
  beforeUnmount() {
    this.destroyChart;
  },
  methods: {
    createChart() {
      this.chart = new Chart(this.$refs['chart-canvas'], {
        type: this.type,
        data: this.data,
        options: this.options,
        plugins: this.plugins,
      });
    },
    destroyChart() {
      if (this.chart) {
        const chart = Chart.getChart(this.chart);

        console.log(chart);

        if (chart) {
          chart.destroy();
        }
      }
    },
  },
};
</script>
