<template>
  <div class="row items-center q-gutter-x-sm q-mb-md">
    <div class="q-mr-auto">x: {{ x }}, y: {{ y }}</div>

    <q-btn
      :outline="selectedTool === 'vertical'"
      :flat="selectedTool !== 'vertical'"
      color="primary"
      icon="border_horizontal"
      round
      @click="useVerticalTool"
    >
      <q-tooltip
        :offset="[0, 4]"
        anchor="top middle"
        self="bottom middle"
        class="bg-primary"
      >
        {{ $t('components.silhouette.useVerticalTool') }}
      </q-tooltip>
    </q-btn>

    <q-btn
      :outline="selectedTool === 'free'"
      :flat="selectedTool !== 'free'"
      color="primary"
      icon="straighten"
      round
      @click="useFreeTool"
    >
      <q-tooltip
        :offset="[0, 4]"
        anchor="top middle"
        self="bottom middle"
        class="bg-primary"
      >
        {{ $t('components.silhouette.useFreeTool') }}
      </q-tooltip>
    </q-btn>

    <q-btn color="primary" icon="download" flat round @click="downloadImage">
      <q-tooltip
        :offset="[0, 4]"
        anchor="top middle"
        self="bottom middle"
        class="bg-primary"
      >
        {{ $t('components.silhouette.downloadImage') }}
      </q-tooltip>
    </q-btn>

    <q-btn color="primary" icon="cached" flat round @click="clearLines">
      <q-tooltip
        :offset="[0, 4]"
        anchor="top middle"
        self="bottom middle"
        class="bg-primary"
      >
        {{ $t('components.silhouette.clearLines') }}
      </q-tooltip>
    </q-btn>
  </div>

  <canvas
    id="canvas-silhouette"
    style="width: 360px; height: 720px"
    @mousemove="getCoordinates"
    @mouseleave="resetCoordinates"
  ></canvas>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['exam'],
  data() {
    return {
      x: 0,
      y: 0,
      selectedTool: 'vertical',
    };
  },
  computed: {
    ...mapGetters({
      accountInfo: 'account/info',
    }),
  },
  mounted() {
    if (window.init_tool) {
      this.initSilhouette();
    } else {
      this.loadScript();
    }
  },
  watch: {
    exam() {
      this.clearLines();

      this.selectedTool = 'vertical';

      this.initSilhouette();
    },
  },
  methods: {
    loadScript() {
      const path = process.env.BASE_URL + 'silhouette.js';
      const script = document.createElement('script');

      script.async = true;
      script.src = path;
      script.onload = () => {
        this.initSilhouette();
      };

      document.head.appendChild(script);
    },
    initSilhouette() {
      window.init_tool(
        'canvas-silhouette',
        this.exam.silhouetteNoLines,
        this.exam.metricParams.height,
        this.exam.params.eyePos,
        this.exam.params.anklePos,
        1,
        '12px Arial',
        360,
        720
      );

      window.setTimeout(() => {
        if (this.accountInfo?.user.measurement === 'imperial') {
          window.set_imperial();
        } else {
          window.set_metrics();
        }
      }, 500);
    },
    useVerticalTool() {
      this.selectedTool = 'vertical';

      window.set_vertical_tool();
    },
    useFreeTool() {
      this.selectedTool = 'free';

      window.set_free_tool();
    },
    downloadImage() {
      window.download_image();
    },
    clearLines() {
      window.clear_lines();
    },
    getCoordinates() {
      const { x, y } = window.get_mouse_xy();

      this.x = x;
      this.y = y;
    },
    resetCoordinates() {
      this.x = 0;
      this.y = 0;
    },
  },
};
</script>
