import { render } from "./PatientsShow.vue?vue&type=template&id=016fa6a5"
import script from "./PatientsShow.vue?vue&type=script&lang=js"
export * from "./PatientsShow.vue?vue&type=script&lang=js"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QCardActions,QBtn,QSpinnerDots,QIcon,QTabs,QTab,QSeparator,QBanner,QTabPanels,QTabPanel});
