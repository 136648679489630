import { render } from "./SearchBox.vue?vue&type=template&id=a7165716"
import script from "./SearchBox.vue?vue&type=script&lang=js"
export * from "./SearchBox.vue?vue&type=script&lang=js"
script.render = render

export default script
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QInput,QIcon,QMenu,QList,QItem,QItemSection,QAvatar,QItemLabel,QBadge});qInstall(script, 'directives', {ClosePopup});
