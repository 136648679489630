<template>
  <div class="q-mb-lg">
    <q-banner v-if="error" class="text-white bg-red q-mb-md" rounded>
      {{ error.message }}
    </q-banner>

    <q-table
      v-model:pagination="pagination"
      :columns="tableColumns"
      :rows="exams"
      :rows-per-page-options="[10, 20, 50, 100]"
      :loading="isLoading"
      :loading-label="$t('pages.patient.loading')"
      :no-data-label="$t('pages.patient.noItems')"
      row-key="id"
      binary-state-sort
      flat
      @request="onRequest"
      @row-click="onRowClick"
      class="q-ma-none"
    >
      <template v-slot:top>
        <div class="col-3">
          <q-tabs v-model="view" dense stretch active-color="primary">
            <q-tab icon="las la-calculator" name="basic"></q-tab>
            <q-tab icon="las la-ruler-combined" name="measures"></q-tab>
            <q-tab icon="las la-burn" name="energies"></q-tab>
          </q-tabs>
        </div>
        <div class="col justify-end text-right">
          <q-btn
            v-if="$isAllowed('doctor/patients/export')"
            :label="$t('pages.patients.exportButtonTitle')"
            color="primary"
            class="q-mb-md q-ml-sm"
            outline
            @click="onExportPatient"
          />

          <q-btn
            v-if="$isAllowed('doctor/exams/compare')"
            :disabled="isComparisonDisabled"
            :label="$t('pages.exam.compareButtonTitle')"
            icon="compare_arrows"
            color="primary"
            class="q-mb-md q-ml-sm"
            outline
            @click="compareExams"
          >
            <q-badge
              v-if="selectedExams.length"
              color="red"
              floating
              rounded
              class="q-pl-sm"
            >
              {{ selectedExams.length }}
            </q-badge>
          </q-btn>

          <q-btn
            v-if="$isAllowed('doctor/reports/follow-up')"
            :to="
              $getLocalizedPath(
                `/folders/${$route.params.folderId}/patients/${$route.params.patientId}/reports/follow-up`
              )
            "
            :label="$t('pages.patient.followUpReportButtonTitle')"
            icon="las la-chart-line"
            color="primary"
            class="q-mb-md q-ml-sm"
            outline
          />

          <q-btn
            v-if="
              $isAllowed('doctor/photos/upload') &&
              patient &&
              patient.folder.accessLevel !== 'read-only'
            "
            :label="$t('pages.patient.addButtonTitle')"
            icon="las la-plus"
            color="primary"
            class="q-mb-md q-ml-sm"
            outline
            @click="openUploadModal"
          />
        </div>
      </template>

      <template v-slot:body v-if="isLoading">
        <q-tr>
          <q-td v-for="column in tableColumns" :key="column.field">
            <q-skeleton type="text" style="min-width: 20px" />
          </q-td>
        </q-tr>
      </template>

      <template v-slot:body-cell="props">
        <q-td :props="props">
          {{ props.value }}
          <q-icon
            v-if="
              props.row.difference?.[props.col.name] &&
              props.row.difference[props.col.name] !== defaultHeight
            "
            :name="
              (
                typeof props.row.difference[props.col.name] === 'string'
                  ? !props.row.difference[props.col.name].startsWith('-')
                  : props.row.difference[props.col.name] > 0
              )
                ? 'fas fa-caret-up'
                : 'fas fa-caret-down'
            "
            size="xs"
            class="q-mb-xs"
          />
        </q-td>
      </template>

      <!--suppress HtmlUnknownAttribute -->
      <template v-slot:body-cell-selection="props">
        <q-td :props="props" @click.stop="">
          <q-btn
            :color="buttonStyleFor(props.row).color"
            :text-color="buttonStyleFor(props.row).textColor"
            :flat="buttonStyleFor(props.row).flat"
            icon="compare_arrows"
            size="sm"
            round
            @click="selectExamForComparison(props.row.id)"
          />
        </q-td>
      </template>

      <!--suppress HtmlUnknownAttribute -->
      <template v-slot:body-cell-created-at="props">
        <q-td :props="props">
          <q-icon
            v-if="props.row.warnings?.length"
            color="warning"
            name="las la-exclamation-triangle"
            size="xs"
            tooltip="AAA"
          >
            <q-tooltip>
              {{ props.row.warnings.join(', ') }}
            </q-tooltip>
          </q-icon>
          {{ props.value }}
        </q-td>
      </template>
    </q-table>

    <upload-photo-modal
      v-if="patient"
      :patient-id="patientId"
      :height="patient.height"
      @succeeded="onPhotoUploaded"
      ref="upload"
    ></upload-photo-modal>

    <export-patient-modal ref="export-modal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { getExamsAndCount } from '@/api/exams';
import { getPatientById } from '@/api/patients';
import { updateWidget } from '@/api/intercom';

import UploadPhotoModal from '@/components/UploadPhotoModal';
import ExportPatientModal from '@/components/ExportPatientModal.vue';

export default {
  components: { UploadPhotoModal, ExportPatientModal },
  props: {
    patientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      view: 'basic',
      pagination: null,
      error: null,
      patient: null,
      exams: [],
      examsCount: 0,
      loadingRowsCount: 5, // TODO: to config
      isLoading: false,
      uploadVisible: false,
      defaultHeight: '0\'0"',
    };
  },
  computed: {
    ...mapGetters({
      units: 'account/units',
    }),
    allColumns() {
      return {
        basic: [
          {
            name: 'selection',
            align: 'left',
          },
          {
            name: 'created-at',
            field: 'createdAt',
            format: (value) => this.$dayjs(value).format('L LT'),
            label: this.$t('pages.patient.tableColumns.createdAt'),
            align: 'left',
            sortable: true,
          },
          {
            name: 'fm',
            field: (row) => row.params.fm,
            format: (value) => this.$numeral(value).format('0,0.0'),
            label: this.$t('pages.patient.tableColumns.fm', {
              unit: this.units.fm,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'ffm',
            field: (row) => row.params.ffm,
            format: (value) => this.$numeral(value).format('0,0.0'),
            label: this.$t('pages.patient.tableColumns.ffm', {
              unit: this.units.ffm,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'abdominalFm',
            field: (row) => row.params.abdominalFm,
            format: (value) => this.$numeral(value).format('0,0.0'),
            label: this.$t('pages.patient.tableColumns.abdominalFm', {
              unit: this.units.abdominalFm,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'fmPct',
            field: (row) => row.params.fmPct,
            format: (value) => `${this.$numeral(value).format('0,0.0')}%`,
            label: this.$t('pages.patient.tableColumns.fmPct', {
              unit: this.units.fmPct,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'ffmPct',
            field: (row) => row.params.ffmPct,
            format: (value) => `${this.$numeral(value).format('0,0.0')}%`,
            label: this.$t('pages.patient.tableColumns.ffmPct', {
              unit: this.units.ffmPct,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'fmi',
            field: (row) => row.params.fmi,
            format: (value) => this.$numeral(value).format('0,0.0'),
            label: this.$t('pages.patient.tableColumns.fmi', {
              unit: this.units.fmi,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'ffmi',
            field: (row) => row.params.ffmi,
            format: (value) => this.$numeral(value).format('0,0.0'),
            label: this.$t('pages.patient.tableColumns.ffmi', {
              unit: this.units.ffmi,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'bmi',
            field: (row) => row.params.bmi,
            format: (value) => this.$numeral(value).format('0,0.0'),
            label: this.$t('pages.patient.tableColumns.bmi', {
              unit: this.units.bmi,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'bmr',
            field: (row) => row.params.bmr,
            format: (value) => this.$numeral(value).format('0,0'),
            label: this.$t('pages.patient.tableColumns.bmr', {
              unit: this.units.bmr,
            }),
            align: 'center',
            sortable: true,
          },
        ],
        measures: [
          {
            name: 'selection',
            align: 'left',
          },
          {
            name: 'created-at',
            field: 'createdAt',
            format: (value) => this.$dayjs(value).format('L LT'),
            label: this.$t('pages.patient.tableColumns.createdAt'),
            align: 'left',
            sortable: true,
          },
          {
            name: 'height',
            field: (row) => row.params.height,
            format: (value) => this.formatNumber(value),
            label: this.$t('pages.patient.tableColumns.height', {
              unit: this.units.height,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'weight',
            field: (row) => row.params.weight,
            format: (value) => this.$numeral(value).format('0,0.0'),
            label: this.$t('pages.patient.tableColumns.weight', {
              unit: this.units.weight,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'chest',
            field: (row) => row.params.chest,
            format: (value) => this.formatNumber(value),
            label: this.$t('pages.patient.tableColumns.chest', {
              unit: this.units.chest,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'belly',
            field: (row) => row.params.belly,
            format: (value) => this.formatNumber(value),
            label: this.$t('pages.patient.tableColumns.belly', {
              unit: this.units.belly,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'hip',
            field: (row) => row.params.hip,
            format: (value) => this.formatNumber(value),
            label: this.$t('pages.patient.tableColumns.hip', {
              unit: this.units.hip,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'thigh',
            field: (row) => row.params.thigh,
            format: (value) => this.formatNumber(value),
            label: this.$t('pages.patient.tableColumns.thigh', {
              unit: this.units.thigh,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'calf',
            field: (row) => row.params.calf,
            format: (value) => this.formatNumber(value),
            label: this.$t('pages.patient.tableColumns.calf', {
              unit: this.units.calf,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'waistToHeight',
            field: (row) => row.params.waistToHeight,
            format: (value) => this.$numeral(value).format('0,0.0'),
            label: this.$t('pages.patient.tableColumns.waistToHeight', {
              unit: this.units.waistToHeight,
            }),
            align: 'center',
            sortable: true,
          },
        ],
        energies: [
          {
            name: 'selection',
            align: 'left',
          },
          {
            name: 'created-at',
            field: 'createdAt',
            format: (value) => this.$dayjs(value).format('L LT'),
            label: this.$t('pages.patient.tableColumns.createdAt'),
            align: 'left',
            sortable: true,
          },
          {
            name: 'totalEnergy1',
            field: (row) => row.params.totalEnergy1,
            format: (value) => `${this.$numeral(value).format('0,0.0')}`,
            label: this.$t('pages.patient.tableColumns.tdee1', {
              unit: this.units.totalEnergy1,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'totalEnergy2',
            field: (row) => row.params.totalEnergy2,
            format: (value) => `${this.$numeral(value).format('0,0.0')}`,
            label: this.$t('pages.patient.tableColumns.tdee2', {
              unit: this.units.totalEnergy2,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'totalEnergy3',
            field: (row) => row.params.totalEnergy3,
            format: (value) => `${this.$numeral(value).format('0,0.0')}`,
            label: this.$t('pages.patient.tableColumns.tdee3', {
              unit: this.units.totalEnergy3,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'totalEnergy4',
            field: (row) => row.params.totalEnergy4,
            format: (value) => `${this.$numeral(value).format('0,0.0')}`,
            label: this.$t('pages.patient.tableColumns.tdee4', {
              unit: this.units.totalEnergy4,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'totalEnergy5',
            field: (row) => row.params.totalEnergy5,
            format: (value) => `${this.$numeral(value).format('0,0.0')}`,
            label: this.$t('pages.patient.tableColumns.tdee5', {
              unit: this.units.totalEnergy5,
            }),
            align: 'center',
            sortable: true,
          },
          {
            name: 'totalEnergy6',
            field: (row) => row.params.totalEnergy6,
            format: (value) => `${this.$numeral(value).format('0,0.0')}`,
            label: this.$t('pages.patient.tableColumns.tdee6', {
              unit: this.units.totalEnergy6,
            }),
            align: 'center',
            sortable: true,
          },
        ],
      };
    },
    selectedExams() {
      return this.exams.filter((item) => item.selected);
    },
    tableColumns() {
      return this.allColumns[this.view];
    },
    isComparisonDisabled() {
      return this.selectedExams.length !== 2;
    },
  },
  created() {
    this.pagination = {
      sortBy: 'created-at',
      descending: true,
      page: 1,
      rowsPerPage: 10, //TODO move to config
      rowsNumber: null,
    };

    this.getExams();
    this.getPatient();
  },
  watch: {
    exams: {
      handler() {
        updateWidget({ patient_id: this.$route.params.patientId });
      },
      deep: true,
    },
    '$route.params.patientId'(value) {
      if (value) {
        this.pagination = {
          sortBy: 'created-at',
          descending: true,
          page: 1,
          rowsPerPage: 10, //TODO move to config
          rowsNumber: null,
        };

        this.getExams();
        this.getPatient();
      }
    },
  },
  methods: {
    async onExportPatient() {
      this.$refs['export-modal'].open();
    },
    formatNumber: function (value) {
      return typeof value === 'string'
        ? value
        : this.$numeral(value).format('0,0');
    },
    buttonStyleFor(row) {
      const found =
        this.selectedExams.find((item) => item.id === row.id) !== undefined;

      if (found) {
        return {
          color: 'primary',
          textColor: 'white',
          flat: false,
        };
      }

      return {
        color: 'primary',
        textColor: 'grey-14',
        flat: true,
      };
    },
    async getExams() {
      try {
        this.error = null;
        this.isLoading = true;

        const { exams, count } = await getExamsAndCount({
          filters: {
            query: null,
          },
          patientId: +this.$route.params.patientId,
          sort: `${this.pagination.sortBy}-${
            this.pagination.descending ? 'desc' : 'asc'
          }`,
          offset: (+this.pagination.page - 1) * +this.pagination.rowsPerPage,
          limit: +this.pagination.rowsPerPage,
        });

        this.exams = exams;
        this.examsCount = count;
        this.pagination = { ...this.pagination, rowsNumber: count };
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
    async getPatient() {
      try {
        this.error = null;
        this.isLoading = true;

        const { patient } = await getPatientById(+this.$route.params.patientId);

        this.patient = patient;
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
    onRequest({ pagination: { sortBy, descending, page, rowsPerPage } }) {
      this.pagination.page = page;
      this.pagination.sortBy = sortBy;
      this.pagination.descending = descending;
      this.pagination.rowsPerPage = rowsPerPage;

      this.getExams();
    },
    onRowClick(event, exam) {
      if (this.$isAllowed('doctor/exams/get')) {
        this.$router.push(
          this.$getLocalizedPath(
            `/folders/${this.$route.params.folderId}/patients/${this.$route.params.patientId}/exams/${exam.id}`
          )
        );
      }
    },
    openUploadModal() {
      this.$nextTick(() => {
        this.$refs.upload.open();
      });
    },
    onPhotoUploaded() {
      this.getExams();

      this.$q.notify({
        type: 'positive',
        message: this.$t('pages.patient.tabs.exams.upload.success'),
        position: 'center',
      });
    },
    selectExamForComparison(id) {
      const exam = this.exams.find((item) => item.id === id);
      exam.selected = !exam.selected;
    },
    compareExams() {
      const exams = this.selectedExams.sort((a, b) =>
        this.$dayjs(a.createdAt).diff(this.$dayjs(b.createdAt), 'second', true)
      );

      this.$router.push(
        // TODO: Why not to use the proper route construction?
        this.$getLocalizedPath(
          `/folders/${this.$route.params.folderId}/patients/${this.$route.params.patientId}/exams/compare?first=${exams[0].id}&second=${exams[1].id}`
        )
      );
    },
  },
};
</script>

<style lang="sass" scoped></style>
