import axios from '@/helpers/axios';

export function searchPatients(query) {
  return axios
    .post('/doctor/patients/list', {
      filters: {
        query,
      },
      offset: 0,
      limit: 10,
    })
    .then(({ data }) => data.results);
}

export function getPatientsAndCount(options) {
  return axios.post('/doctor/patients/list', options).then(({ data }) => {
    return {
      patients: data.results,
      count: data.count,
    };
  });
}

export function getPatientById(id) {
  return axios
    .post('/doctor/patients/get', {
      id,
    })
    .then(({ data }) => {
      return {
        patient: data.patient,
        accountBalance: data.balance,
      };
    });
}

export function addPatient(patient) {
  return axios
    .post('/doctor/patients/create', {
      patient: {
        ...patient,
        folderId: patient.folderId,
      },
    })
    .then(({ data }) => {
      return data.patient;
    });
}

export function updatePatient(patient, resetPassword = false) {
  return axios
    .post('/doctor/patients/update', {
      id: patient.userId,
      patient: {
        ...patient,
        folderId: patient.folderId,
      },
      resetPassword,
    })
    .then(({ data }) => {
      return data.patient;
    });
}

export function deletePatientById(id) {
  return axios.post('/doctor/patients/delete', {
    id,
  });
}

export function importPatients(data, folderId) {
  return axios
    .post('/doctor/patients/import', {
      data,
      folderId,
    })
    .then(({ data }) => {
      return data.results;
    });
}

export function exportPatient(id, includePersonalInfo) {
  return axios
    .post('/doctor/patients/export', {
      id,
      includePersonalInfo,
    })
    .then(({ data }) => {
      return data;
    });
}
