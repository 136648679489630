import { render } from "./AccountCreditsHistory.vue?vue&type=template&id=1c7b78ce"
import script from "./AccountCreditsHistory.vue?vue&type=script&lang=js"
export * from "./AccountCreditsHistory.vue?vue&type=script&lang=js"
script.render = render

export default script
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QTable from 'quasar/src/components/table/QTable.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBanner,QTable});
