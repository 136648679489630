import dayjs from '@/helpers/dayjs';
import { getPatientById } from '@/api/patients';

export function activateWidget(account) {
  window.intercomSettings = {
    api_base: process.env.VUE_APP_INTERCOM_API_BASE,
    app_id: process.env.VUE_APP_INTERCOM_APP_ID,
    language_override: account.user.locale,
    country: account.user.country,
    name: account.user.fullName,
    user_id: account.user.id,
    email: account.user.email,
    dealer: account.dealer.userId,
    credits: account.balance,
    exams: account.stats.examsCount,
    pending_photos: account.stats.pendingPhotos,
    subjects: account.stats.patientsCount,
    latest_exam: dayjs(account.stats.recentExamAt).unix(),
    created_at: dayjs(account.user.createdAt).unix(),
  };

  let w = window;
  let ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    let d = document;
    let i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    let l = function () {
      let s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/rp687lcz';
      let x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
}

export async function updateWidget(settings) {
  if (settings.patient_id === undefined) {
    delete window.intercomSettings.patient_id;
    delete window.intercomSettings.patient_exams;
    delete window.intercomSettings.patient_latest_exam;
  }

  let newVal = { ...window.intercomSettings, ...settings };

  if (settings.patient_id) {
    const { patient } = await getPatientById(settings.patient_id);

    newVal = {
      ...newVal,
      patient_id: settings.patient_id,
      patient_exams: patient.examsCount,
      patient_latest_exam: dayjs(patient.lastExamAt).unix() || undefined,
    };
  }

  await window.Intercom('update', newVal);
}
