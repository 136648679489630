import axios from '@/helpers/axios';
import i18n from '@/helpers/i18n';

export function getGlossary() {
  return axios
    .post('/doctor/glossary/list', {
      language: i18n.locale,
    })
    .then(({ data }) => {
      return {
        results: data.results,
        contactEmail: data.contactEmail,
      };
    });
}
