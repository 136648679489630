<template>
  <q-dialog v-model="isVisible">
    <q-card style="width: 400px; max-width: 80vw">
      <q-form
        autocorrect="off"
        autocapitalize="off"
        autocomplete="off"
        spellcheck="false"
        @submit="onSubmit"
      >
        <q-card-section
          class="row items-center justify-between bg-primary text-white"
        >
          <span class="text-h6">
            {{
              $t('components.shareModal.title', {
                folder: this.folder.title,
              })
            }}
          </span>

          <q-btn
            :disable="isLoading"
            icon="close"
            flat
            round
            dense
            v-close-popup
          />
        </q-card-section>

        <q-separator />

        <q-card-section v-if="error">
          <q-banner class="text-white bg-red" rounded>
            {{ error.message }}
          </q-banner>
        </q-card-section>

        <q-card-section class="q-pt-none q-mt-sm">
          <div class="q-mb-sm">
            {{ $t('components.shareModal.description') }}
          </div>

          <div class="row q-col-gutter-md">
            <div class="col-8">
              <q-input
                v-model.trim="email"
                :label="$t('components.shareModal.labels.email')"
                :error="v$.email.$error"
                :error-message="$t('components.shareModal.errors.email')"
                hide-bottom-space
                autofocus
              />
            </div>

            <div class="col-4">
              <q-select
                v-model="level"
                :options="[
                  {
                    value: 'full',
                    label: $t('components.shareModal.levels.full'),
                  },
                  {
                    value: 'read-only',
                    label: $t('components.shareModal.levels.read-only'),
                  },
                ]"
                :label="$t('components.shareModal.labels.level')"
                :error="v$.level.$error"
                :error-message="$t('components.shareModal.errors.level')"
                emit-value
                map-options
                hide-bottom-space
              />
            </div>
          </div>

          <div class="row q-mt-md">
            <q-btn
              :loading="isLoading"
              :label="$t('components.shareModal.grantButtonTitle')"
              type="submit"
              color="primary"
              class="q-ml-auto"
            />
          </div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="row items-center">
            <div class="text-subtitle2">
              {{ $t('components.shareModal.users') }}
            </div>

            <div class="text-subtitle2 q-ml-auto" style="width: 167px">
              {{ $t('components.shareModal.level') }}
            </div>
          </div>

          <div v-if="accesses && accesses.length === 0" class="q-mt-sm">
            {{ $t('components.shareModal.noUsers') }}
          </div>

          <q-list>
            <q-item
              v-for="access of accesses"
              :key="access.id"
              class="q-px-none"
            >
              <q-item-section>{{ access.doctor.user.fullName }}</q-item-section>
              <q-item-section side>
                <div class="row items-center">
                  <q-btn-dropdown
                    :label="$t(`components.shareModal.levels.${access.level}`)"
                    color="black"
                    flat
                    no-caps
                  >
                    <q-list>
                      <q-item
                        v-close-popup
                        clickable
                        @click="changeLevel(access, 'full')"
                      >
                        <q-item-section>
                          <q-item-label>
                            {{ $t('components.shareModal.levels.full') }}
                          </q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-item
                        v-close-popup
                        clickable
                        @click="changeLevel(access, 'read-only')"
                      >
                        <q-item-section>
                          <q-item-label>
                            {{ $t('components.shareModal.levels.read-only') }}
                          </q-item-label>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-btn-dropdown>

                  <q-btn
                    icon="close"
                    color="negative"
                    size="12px"
                    class="q-ml-sm"
                    flat
                    round
                    @click="removeAccess(access)"
                  />
                </div>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            :loading="isLoading"
            :label="$t('components.shareModal.doneButtonTitle')"
            type="button"
            color="primary"
            flat
            @click="close"
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import _ from 'lodash';
import useValidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import { getList, grant, update, remove } from '@/api/accesses';

export default {
  data() {
    return {
      v$: useValidate(),
      isVisible: false,
      isLoading: false,
      error: null,
      email: null,
      level: 'read-only',
      folder: null,
      accesses: null,
    };
  },
  validations: {
    email: {
      required,
    },
    level: {
      required,
    },
  },
  watch: {
    isVisible(newValue) {
      if (!newValue) {
        this.$emit('done');
      }
    },
  },
  methods: {
    async open(folder) {
      this.error = null;
      this.email = null;
      this.level = 'read-only';

      this.folder = _.cloneDeep(folder);

      this.v$.$reset();

      this.isLoading = false;
      this.isVisible = true;

      await this.getAccesses();
    },
    close() {
      this.isVisible = false;
    },
    async getAccesses() {
      try {
        this.error = null;
        this.isLoading = true;

        this.accesses = await getList(this.folder.id);
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
    async onSubmit() {
      this.error = null;

      this.v$.$touch();

      if (!this.v$.$invalid) {
        try {
          this.isLoading = true;

          const access = await grant({
            access: {
              level: this.level,
              folderId: this.folder.id,
            },
            email: this.email,
          });

          this.accesses.push(access);

          this.email = null;
          this.level = 'read-only';
          this.v$.$reset();
        } catch (error) {
          this.error = error;
        } finally {
          this.isLoading = false;
        }
      }
    },
    async removeAccess(access) {
      try {
        this.isLoading = true;

        await remove(access.id);

        const index = this.accesses.findIndex((a) => a.id === access.id);

        if (index !== -1) {
          this.accesses.splice(index, 1);
        }
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
    async changeLevel({ id }, newLevel) {
      try {
        this.isLoading = true;

        const access = await update({
          id,
          access: {
            level: newLevel,
          },
        });

        const index = this.accesses.findIndex((a) => a.id === id);

        if (index !== -1) {
          this.accesses.splice(index, 1, access);
        }
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
