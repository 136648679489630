import { render } from "./Contracts.vue?vue&type=template&id=778ea1a6"
import script from "./Contracts.vue?vue&type=script&lang=js"
export * from "./Contracts.vue?vue&type=script&lang=js"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QIcon,QTabs,QTab,QTabPanels,QTabPanel,QSeparator});
