<template>
  <breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" />

  <q-card flat bordered>
    <q-card-section class="row items-center wrap q-pb-none">
      <span class="text-h5 q-mb-md">
        {{ $t('pages.glossary.title') }}
      </span>
    </q-card-section>

    <q-separator />

    <q-card-section v-if="error">
      <q-banner class="text-white bg-red q-mb-md" rounded>
        {{ error.message }}
      </q-banner>
    </q-card-section>

    <q-card-section class="q-gutter-y-lg">
      <div v-for="term of glossary" :key="term.id">
        <div class="text-subtitle2 q-mb-sm text-grey-10">{{ term.term }}</div>

        <div class="text-body2">{{ term.meaning }}</div>
      </div>
    </q-card-section>
    <q-separator />

    <div class="column items-center q-mb-xl q-mt-lg q-gutter-y-lg">
      <div class="text-subtitle2">
        {{ $t('pages.glossary.contactUsText') }}
      </div>

      <contact-us-btn :subject="$t('pages.glossary.subject')" />
    </div>
  </q-card>
</template>

<script>
import { getGlossary } from '@/api/glossary';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ContactUsBtn from '@/components/inputs/ContactUsBtn';

export default {
  components: {
    ContactUsBtn,
    Breadcrumbs,
  },
  meta() {
    return {
      title: this.$t('pages.glossary.documentTitle'),
    };
  },
  data() {
    return {
      glossary: null,
      contactEmail: null,
      error: null,
    };
  },
  created() {
    this.fetchGlossary();
  },
  computed: {
    breadcrumbs() {
      return [
        {
          to: this.$getLocalizedPath({ name: 'Dashboard' }),
          label: this.$t('breadcrumbs.dashboard'),
        },
        {
          to: null,
          label: this.$t('pages.glossary.documentTitle'),
        },
      ];
    },
  },
  methods: {
    async fetchGlossary() {
      try {
        const { results, contactEmail } = await getGlossary();
        this.glossary = results;
        this.contactEmail = contactEmail;
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>
