<template>
  <q-layout view="hHh lpr fFf">
    <q-page-container
      class="column items-center justify-center"
      style="min-height: inherit"
    >
      <router-view />
    </q-page-container>
  </q-layout>
</template>
