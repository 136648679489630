import store from '@/store';

export default function isAllowed(endpoint) {
  const accountPermissions = store.getters['account/permissions'];

  if (accountPermissions) {
    let isAllowed = false;

    outer: for (let i = 0; i < accountPermissions.length; i++) {
      const accountPermission = accountPermissions[i].split('/');
      const currentPermission = endpoint.split('/');

      for (let j = 0; j < currentPermission.length; j++) {
        if (
          currentPermission[j] === accountPermission[j] ||
          accountPermission[j] === '*'
        ) {
          if (
            j === currentPermission.length - 1 ||
            j === accountPermission.length - 1
          ) {
            isAllowed = true;
            break outer;
          }
        } else {
          break;
        }
      }
    }

    return isAllowed;
  }

  return false;
}
