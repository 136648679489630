import axios from '@/helpers/axios';

export function getCreditsHistory(data) {
  return axios.post('/doctor/transactions/list', data).then(({ data }) => {
    return {
      history: data.results,
      count: data.count,
    };
  });
}
