<template>
  <div class="gt-xs">
    <div class="text-h6 q-mb-md">
      {{ $t('components.percentiles.title') }}
    </div>

    <q-markup-table flat bordered class="print-page-break-inside">
      <thead>
        <tr>
          <th colspan="15" class="text-left">
            {{ $t('components.percentiles.ffm', { unit: units.ffm }) }}
          </th>
        </tr>

        <tr>
          <th></th>
          <th
            v-for="(percent, index) of [5, 10, 25, 50, 75, 90, 95]"
            :key="percent"
            colspan="2"
            class="text-center ffm"
          >
            <div class="row items-center justify-center text-white text-body2">
              <img
                v-if="index === 0"
                src="@/assets/percentage-bg-red.png"
                class="ffm gradient-bg"
                :class="`ffm-${index}`"
              />
              <img
                v-if="index === 3"
                src="@/assets/percentage-bg-green.png"
                class="ffm gradient-bg"
                :class="`ffm-${index}`"
              />
              <img
                v-if="index === 4"
                src="@/assets/percentage-bg-blue.png"
                class="ffm gradient-bg"
                :class="`ffm-${index}`"
              />

              <img
                width="20"
                height="20"
                class="q-mr-sm"
                :src="require(`@/assets/${ffmIcons[index]}`)"
              />
              {{
                $t('components.percentiles.header.percent', { value: percent })
              }}
            </div>
          </th>
        </tr>

        <tr>
          <th>{{ $t('components.percentiles.header.age') }}</th>
          <template
            v-for="percent of [5, 10, 25, 50, 75, 90, 95]"
            :key="percent"
          >
            <th class="text-center">
              {{ $t('components.percentiles.header.male') }}
            </th>
            <th class="text-center">
              {{ $t('components.percentiles.header.female') }}
            </th>
          </template>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(row, rowIndex) of ffmPercentilesData"
          :key="`ffm${rowIndex}`"
        >
          <td
            v-for="(cell, columnIndex) of row"
            :key="`ffm${rowIndex}${columnIndex}`"
            :class="{ 'text-center': columnIndex !== 0 }"
          >
            {{ cell }}
          </td>
        </tr>
      </tbody>
    </q-markup-table>

    <q-markup-table flat bordered>
      <thead>
        <tr>
          <th colspan="15" class="text-left">
            {{ $t('components.percentiles.fm', { unit: units.fm }) }}
          </th>
        </tr>

        <tr>
          <th></th>
          <th
            v-for="(percent, index) of [5, 10, 25, 50, 75, 90, 95]"
            :key="percent"
            colspan="2"
            class="fm text-center"
          >
            <div class="row items-center justify-center text-white text-body2">
              <img
                v-if="index === 0"
                src="@/assets/percentage-bg-red.png"
                class="ffm gradient-bg"
                :class="`fm-${index}`"
              />
              <img
                v-if="index === 3"
                src="@/assets/percentage-bg-green.png"
                class="ffm gradient-bg"
                :class="`fm-${index}`"
              />
              <img
                v-if="index === 4"
                src="@/assets/percentage-bg-red.png"
                class="ffm gradient-bg"
                :class="`fm-${index}`"
              />
              <img
                width="20"
                height="20"
                class="q-mr-sm"
                :src="require(`@/assets/${fmIcons[index]}`)"
              />
              {{
                $t('components.percentiles.header.percent', { value: percent })
              }}
            </div>
          </th>
        </tr>

        <tr>
          <th>{{ $t('components.percentiles.header.age') }}</th>
          <template
            v-for="percent of [5, 10, 25, 50, 75, 90, 95]"
            :key="percent"
          >
            <th class="text-center">
              {{ $t('components.percentiles.header.male') }}
            </th>
            <th class="text-center">
              {{ $t('components.percentiles.header.female') }}
            </th>
          </template>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(row, rowIndex) of fmPercentilesData" :key="`fm${rowIndex}`">
          <td
            v-for="(cell, columnIndex) of row"
            :key="`fm${rowIndex}${columnIndex}`"
            :class="{ 'text-center': columnIndex !== 0 }"
          >
            {{ cell }}
          </td>
        </tr>
      </tbody>
    </q-markup-table>
  </div>
</template>

<script>
export default {
  props: {
    units: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fmIcons: [
        'emoji-sad-1.svg',
        'emoji-sad-2.svg',
        'emoji-sad-3.svg',
        'emoji-happy-1.svg',
        'emoji-sad-3.svg',
        'emoji-sad-2.svg',
        'emoji-sad-1.svg',
      ],
      ffmIcons: [
        'emoji-sad-1.svg',
        'emoji-sad-2.svg',
        'emoji-sad-3.svg',
        'emoji-happy-1.svg',
        'emoji-happy-2.svg',
        'emoji-happy-3.svg',
        'emoji-happy-4.svg',
      ],
      ffmPercentilesData: [
        [
          '18-34',
          '16.8',
          '13.8',
          '17.2',
          '14.1',
          '18.0',
          '14.7',
          '18.9',
          '15.4',
          '19.8',
          '16.2',
          '20.5',
          '17.1',
          '21.1',
          '17.6',
        ],
        [
          '35-54',
          '17.2',
          '14.4',
          '17.6',
          '14.7',
          '18.3',
          '15.3',
          '19.2',
          '15.9',
          '20.1',
          '16.7',
          '21.1',
          '17.5',
          '21.7',
          '18.0',
        ],
        [
          '55-74',
          '17.0',
          '14.1',
          '17.6',
          '14.6',
          '18.4',
          '15.4',
          '19.4',
          '16.2',
          '20.3',
          '17.4',
          '21.1',
          '18.4',
          '22.1',
          '19.0',
        ],
        [
          '>75',
          '16.6',
          '12.9',
          '16.9',
          '13.7',
          '17.6',
          '14.7',
          '18.5',
          '15.9',
          '19.4',
          '17.0',
          '20.9',
          '18.1',
          '21.2',
          '18.7',
        ],
      ],
      fmPercentilesData: [
        [
          '18-34',
          '2.2',
          '3.5',
          '2.5',
          '3.9',
          '3.2',
          '4.6',
          '4.0',
          '5.5',
          '5.0',
          '6.6',
          '6.1',
          '7.8',
          '7.0',
          '8.7',
        ],
        [
          '35-54',
          '2.5',
          '3.4',
          '2.9',
          '3.9',
          '3.7',
          '4.8',
          '4.8',
          '5.9',
          '6.0',
          '7.3',
          '7.2',
          '8.8',
          '7.9',
          '9.9',
        ],
        [
          '55-74',
          '2.8',
          '4.5',
          '3.4',
          '5.4',
          '4.3',
          '6.5',
          '5.7',
          '8.3',
          '7.2',
          '10.3',
          '8.4',
          '12.0',
          '9.3',
          '13.5',
        ],
        [
          '>75',
          '3.7',
          '4.9',
          '4.3',
          '5.6',
          '5.2',
          '7.5',
          '6.4',
          '9.3',
          '7.6',
          '11.4',
          '9.0',
          '13.5',
          '10.1',
          '14.3',
        ],
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.fm,
.ffm {
  position: relative;
  background: transparent;
  z-index: 1;
}

.ffm-0,
.fm-0,
.fm-4 {
  height: 100%;
  width: calc(300% + 2px);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.fm-4 {
  transform: rotate(180deg);
}

.fm-3,
.ffm-3 {
  height: 100%;
  width: calc(100% + 2px);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.ffm-4 {
  height: 100%;
  width: calc(300% + 2px);
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}
</style>
