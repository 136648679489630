import { render } from "./LegalAndPrivacy.vue?vue&type=template&id=443654c6"
import script from "./LegalAndPrivacy.vue?vue&type=script&lang=js"
export * from "./LegalAndPrivacy.vue?vue&type=script&lang=js"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QTable,QTr,QTd});
