<template>
  <q-btn flat stretch icon="las la-envelope">
    <q-badge
      v-if="totalCount"
      color="secondary"
      :label="totalCount"
      rounded
      floating
      class="q-my-md q-mr-sm"
    />

    <q-menu anchor="bottom right" self="top right">
      <q-list separator>
        <q-item v-if="recent().length === 0">
          <q-item-section top avatar> No messages yet. </q-item-section>
        </q-item>
        <q-item
          v-for="(message, index) of recent()"
          :key="index"
          :to="{
            name: 'PatientsShow',
            params: {
              folderId: message.patient.folder.id,
              patientId: message.senderId,
            },
            hash: '#messages',
          }"
          clickable
          v-close-popup
        >
          <q-item-section top avatar>
            <q-avatar
              text-color="white"
              :style="{ backgroundColor: message.patient.user.avatar.color }"
            >
              {{ message.patient.user.avatar.abbr }}
            </q-avatar>
          </q-item-section>

          <q-item-section style="width: 200px">
            <q-item-label>{{ message.patient.user.fullName }}</q-item-label>
            <q-item-label caption>
              {{ message.patient.folder.title }}
            </q-item-label>
          </q-item-section>

          <q-item-section side>
            <q-item-label caption>
              {{ $dayjs(message.lastMessage).fromNow() }}
            </q-item-label>
            <q-badge
              color="secondary"
              :label="message.count"
              class="q-ma-xs"
              v-if="message.count"
            />
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  props: {},
  data() {
    return {
      timerId: null,
    };
  },
  computed: {
    ...mapGetters({
      totalCount: 'stats/totalMessagesCount',
      recent: 'stats/recent',
    }),
  },
  watch() {},
};
</script>

<style lang="sass" scoped></style>
