<template>
  <breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" />

  <q-card flat bordered>
    <q-card-section class="row items-center justify-between wrap q-pb-none">
      <span class="text-h5 q-mb-md">{{ $t('pages.pending.title') }}</span>
    </q-card-section>

    <q-card-section v-if="error">
      <q-banner class="text-white bg-red q-mb-md" rounded>
        {{ error.message }}
      </q-banner>
    </q-card-section>

    <q-table
      :columns="tableColumns"
      :rows="results"
      :rows-per-page-options="[0]"
      :loading="isLoading"
      :loading-label="$t('pages.pending.loading')"
      :no-data-label="$t('pages.pending.noItems')"
      row-key="id"
      class="q-pa-md"
      bordered
      @row-click="onRowClick"
    >
      <template v-slot:body-cell-photo="props">
        <q-td :props="props" @click.stop="">
          <div class="row items-center no-wrap">
            <img
              :src="props.row.file.sm"
              alt=""
              height="80"
              @click="openModalToApprovePhoto(props.row)"
            />
          </div>
        </q-td>
      </template>

      <template v-slot:body-cell-actions="props">
        <q-td :props="props" @click.stop="">
          <q-btn
            :label="$t('pages.pending.approveButtonTitle')"
            class="q-mr-md"
            color="primary"
            outline
            @click="openModalToApprovePhoto(props.row)"
          />

          <q-btn
            :label="$t('pages.pending.rejectButtonTitle')"
            color="negative"
            outline
            @click="openModalToRejectPhoto(props.row)"
          />
        </q-td>
      </template>
    </q-table>
  </q-card>

  <approve-photo-modal
    ref="approve"
    v-if="currentPatient"
    :photo="currentPatient"
    @approved="onPhotoStatusChanged"
  />

  <reject-photo-modal
    ref="reject"
    v-if="currentPatient"
    :photo="currentPatient"
    @rejected="onPhotoStatusChanged"
  />
</template>

<script>
import { getPendingPhotosAndCount } from '@/api/photos';

import ApprovePhotoModal from '@/components/ApprovePhotoModal';
import RejectPhotoModal from '@/components/RejectPhotoModal';
import Breadcrumbs from '@/components/Breadcrumbs.vue';

let initialData = {};

export default {
  components: {
    ApprovePhotoModal,
    RejectPhotoModal,
    Breadcrumbs,
  },
  meta() {
    return {
      title: this.$t('pages.pending.documentTitle'),
    };
  },
  data() {
    return {
      error: initialData.error,
      results: initialData.results,
      resultsCount: initialData.resultsCount,
      isLoading: false,
      currentPatient: null,
      timerId: null,
    };
  },
  created() {
    this.getPhotos();

    this.timerId = setInterval(() => {
      this.getPhotos();
    }, process.env.VUE_APP_REFRESH_INTERVAL);
  },
  beforeUnmount() {
    clearInterval(this.timerId);
  },
  computed: {
    tableColumns() {
      return [
        {
          name: 'photo',
          field: (row) => row.file.sm,
          label: this.$t('pages.pending.tableColumns.photo'),
          align: 'left',
        },
        {
          name: 'fullName',
          field: (row) => row.patient.user.fullName,
          label: this.$t('pages.pending.tableColumns.fullName'),
          align: 'left',
        },
        {
          name: 'date',
          field: (row) => row.createdAt,
          format: (value) => this.$dayjs(value).format('L LT'),
          label: this.$t('pages.pending.tableColumns.date'),
          align: 'left',
        },
        {
          name: 'date',
          field: (row) => `${row.weight} kg`,
          label: this.$t('pages.pending.tableColumns.weight'),
          align: 'left',
        },
        {
          name: 'actions',
          label: this.$t('pages.pending.tableColumns.actions'),
          align: 'right',
        },
      ];
    },
    breadcrumbs() {
      return [
        {
          to: this.$getLocalizedPath({ name: 'Dashboard' }),
          label: this.$t('breadcrumbs.dashboard'),
        },
        {
          to: this.$getLocalizedPath({ name: 'PendingIndex' }),
          label: this.$t('breadcrumbs.pending'),
        },
      ];
    },
  },
  methods: {
    async getPhotos() {
      try {
        if (!this.results) {
          this.isLoading = true;
        }

        const { results } = await getPendingPhotosAndCount();

        this.results = results;
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
    onRowClick(event, row) {
      this.openModalToApprovePhoto(row);
    },
    async openModalToApprovePhoto(row) {
      this.currentPatient = row;

      await this.$nextTick();

      this.$refs.approve.open();
    },
    async openModalToRejectPhoto(row) {
      this.currentPatient = row;

      await this.$nextTick();

      this.$refs.reject.open();
    },
    async onPhotoStatusChanged() {
      this.currentPatient = null;

      this.getPhotos();
    },
  },
};
</script>
