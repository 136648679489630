import axios from '@/helpers/axios';

const chat = {
  send(options) {
    return axios
      .post('/doctor/chat/send', options)
      .then(({ data }) => data.message);
  },

  recent(options) {
    return axios.post('/doctor/chat/recent', options).then(({ data }) => data);
  },

  read(options) {
    return axios.post('/doctor/chat/read', options).then(({ data }) => data);
  },

  feed(options) {
    return axios
      .post('/doctor/chat/feed', options)
      .then(({ data }) => data.results);
  },
};

export { chat };
