<template>
  <q-card flat bordered>
    <q-card-section class="row items-center justify-between wrap q-pb-none">
      <span class="text-h5 q-mb-md">{{ title }}</span>

      <div class="row items-center wrap overflow-auto">
        <q-tabs
          v-model="tab"
          class="q-mb-md"
          dense
          narrow-indicator
          stretch
          active-color="primary"
        >
          <q-tab :label="$t('pages.account.tabs.account')" name="account" />
          <q-tab :label="$t('pages.account.tabs.billing')" name="billing" />
          <q-tab
            :label="$t('pages.account.tabs.preferences')"
            name="preferences"
          />
          <q-tab :label="$t('pages.account.tabs.export')" name="export" />
        </q-tabs>
      </div>
    </q-card-section>

    <q-separator />

    <q-card-section v-if="error">
      <q-banner class="text-white bg-red q-mb-md" rounded>
        {{ error.message }}
      </q-banner>
    </q-card-section>

    <q-tab-panels v-if="account" v-model="tab" animated>
      <q-tab-panel name="account">
        <account-profile-tab
          :account="account"
          @update:title="onTitleUpdate"
          @update:account="onAccountUpdate"
        />
      </q-tab-panel>

      <q-tab-panel name="billing">
        <account-billing-tab
          :account="account"
          @update:title="onTitleUpdate"
          @update:account="onAccountUpdate"
        />
      </q-tab-panel>
      <q-tab-panel name="preferences">
        <account-preferences-tab
          :account="account"
          @update:title="onTitleUpdate"
          @update:account="onAccountUpdate"
        />
      </q-tab-panel>
      <q-tab-panel name="export">
        <account-import-export-tab
          :account="account"
          @update:title="onTitleUpdate"
          @update:account="onAccountUpdate"
        />
      </q-tab-panel>
    </q-tab-panels>
  </q-card>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { getAccount } from '@/api/account';
import AccountProfileTab from '@/components/AccountProfileTab.vue';
import AccountBillingTab from '@/components/AccountBillingTab.vue';
import AccountPreferencesTab from '@/components/AccountPreferencesTab';
import AccountImportExportTab from '@/components/AccountImportExportTab';

export default {
  components: {
    AccountImportExportTab,
    AccountPreferencesTab,
    AccountProfileTab,
    AccountBillingTab,
  },
  data() {
    return {
      title: null,
      tab: 'account',
      error: null,
      account: null,
    };
  },
  meta() {
    return {
      title: this.$t('pages.account.documentTitle', {
        title: this.account?.user.fullName,
      }),
    };
  },
  computed: {
    ...mapGetters({
      accountInfo: 'account/info',
    }),
  },
  created() {
    return this.fetchAccount();
  },
  methods: {
    ...mapMutations({
      setAccount: 'account/setAccount',
    }),
    async fetchAccount() {
      this.account = await getAccount();
    },
    onTitleUpdate(title) {
      this.title = title;
    },
    onAccountUpdate(account) {
      this.account = account;

      this.setAccount({
        ...this.accountInfo,
        ...account,
        user: {
          ...this.accountInfo.user,
          ...account.user,
        },
      });
    },
  },
};
</script>
