<template>
  <q-input
    v-model="value"
    :type="passwordInputType"
    :label="$t('pages.signIn.labels.password')"
    @update:model-value="$emit('update:modelValue', $event)"
    hide-bottom-space
  >
    <template v-slot:append>
      <q-btn
        flat
        dense
        :icon="passwordIcon"
        size="md"
        @click="this.passwordVisible = !this.passwordVisible"
      ></q-btn>
    </template>

    <template v-slot:hint>
      <slot name="hint" />
    </template>
  </q-input>
</template>

<script>
export default {
  components: {},
  props: {
    label: String,
    modelValue: String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue,
      passwordVisible: false,
    };
  },
  computed: {
    passwordInputType: function () {
      return ['password', 'text'][+this.passwordVisible];
    },
    passwordIcon: function () {
      return ['far fa-eye', 'far fa-eye-slash'][+this.passwordVisible];
    },
  },
  created() {},
  watch() {},
  methods: {},
};
</script>

<style lang="sass" scoped></style>
