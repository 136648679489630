<template>
  <q-layout view="hHh LpR fFf">
    <q-header class="bg-white text-dark" bordered>
      <q-toolbar class="q-mx-auto">
        <img
          src="@/assets/logo.png"
          class="q-my-sm q-mr-lg"
          style="width: 48px; height: 40px"
          alt=""
        />

        <q-tabs class="gt-sm" indicator-color="transparent" stretch>
          <q-route-tab
            v-for="(menuItem, index) of allowedMenuItems"
            :key="index"
            :to="menuItem.path"
            no-caps
            active-class="text-primary bg-blue-1"
          >
            <template v-slot:default>
              <div class="q-tab__label relative-position">
                {{ menuItem.label }}

                <q-badge
                  v-if="totalCount && menuItem.isBagdeOn"
                  color="secondary"
                  :label="totalCount"
                  rounded
                  floating
                  style="top: -7px; right: -16px"
                />
              </div>
            </template>
          </q-route-tab>
        </q-tabs>

        <div class="row q-ml-xl self-stretch gt-sm" style="width: 100%">
          <q-space />

          <search-box />

          <messages-btn />

          <q-btn v-if="accountInfo" flat stretch>
            <q-avatar color="primary" text-color="white">
              <q-icon name="las la-cog" size="sm" />
            </q-avatar>

            <q-menu anchor="bottom right" self="top right">
              <q-list style="min-width: 100px">
                <q-item
                  v-if="$isAllowed('doctor/account/get')"
                  :to="$getLocalizedPath({ name: 'Account' })"
                  clickable
                  v-close-popup
                >
                  <q-item-section>
                    {{ accountInfo.user.fullName }}
                  </q-item-section>
                </q-item>

                <q-separator />

                <q-item
                  v-if="$isAllowed('doctor/signatures/list')"
                  :to="$getLocalizedPath({ name: 'Legal&Privacy' })"
                  clickable
                  v-close-popup
                >
                  <q-item-section>
                    {{ $t('layouts.default.drawer.menu.agreements') }}
                  </q-item-section>
                </q-item>

                <q-separator />
                <q-item clickable v-close-popup @click="confirmSignOut">
                  <q-item-section>
                    {{ $t('layouts.default.drawer.menu.signOut') }}
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>

        <q-space />

        <q-btn
          aria-label="Menu"
          icon="menu"
          class="lt-md"
          flat
          stretch
          @click="leftDrawerOpen = !leftDrawerOpen"
        />
      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      behavior="mobile"
      show-if-above
      bordered
      class="bg-grey-2"
    >
      <q-list class="full-height column">
        <q-item
          v-for="(menuItem, index) of allowedMenuItems"
          :key="index"
          :to="menuItem.path"
          tag="a"
          clickable
          exact
        >
          <q-item-section avatar>
            <q-icon :name="menuItem.icon" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ menuItem.label }}</q-item-label>
          </q-item-section>
        </q-item>

        <div class="q-mt-auto">
          <q-item
            v-if="$isAllowed('doctor/account/get')"
            :to="$getLocalizedPath({ name: 'Account' })"
            tag="a"
            clickable
            exact
          >
            <q-item-section avatar>
              <q-icon name="person" />
            </q-item-section>
            <q-item-section>
              <q-item-label>
                {{ accountInfo.user.fullName }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item
            v-if="$isAllowed('doctor/signatures/list')"
            :to="$getLocalizedPath({ name: 'Legal&Privacy' })"
            tag="a"
            clickable
            exact
          >
            <q-item-section avatar>
              <q-icon name="description" />
            </q-item-section>
            <q-item-section>
              <q-item-label>
                {{ $t('layouts.default.drawer.menu.agreements') }}
              </q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable @click="confirmSignOut">
            <q-item-section avatar>
              <q-icon name="logout" />
            </q-item-section>
            <q-item-section>
              <q-item-label>
                {{ $t('layouts.default.drawer.menu.signOut') }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </div>
      </q-list>
    </q-drawer>

    <q-page-container>
      <q-page class="q-mx-auto q-py-lg q-px-md" style="max-width: 1200px">
        <router-view ref="view" />
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { getStats } from '@/api/dashboard';
import { chat } from '@/api/chat';
import { activateWidget, updateWidget } from '@/api/intercom';

import SearchBox from '@/components/SearchBox.vue';
import MessagesBtn from '@/components/inputs/MessagesBtn.vue';

export default {
  components: {
    SearchBox,
    MessagesBtn,
  },
  data() {
    return {
      leftDrawerOpen: false,
      breadcrumbs: null,
      timerId: null,
    };
  },
  computed: {
    ...mapGetters({
      accountInfo: 'account/info',
      totalCount: 'stats/pendingPhotosCount',
    }),
    menuItems() {
      return [
        {
          path: this.$getLocalizedPath({ name: 'Dashboard' }),
          icon: 'dashboard',
          label: this.$t('layouts.default.drawer.menu.dashboard'),
          permissions: 'doctor/dashboard/index',
        },
        {
          path: this.$getLocalizedPath({ name: 'FoldersIndex' }),
          icon: 'fas fa-diagnoses',
          label: this.$t('layouts.default.drawer.menu.patients'),
          permissions: 'doctor/folders/list',
        },
        {
          path: this.$getLocalizedPath({ name: 'PendingIndex' }),
          icon: 'las la-camera-retro',
          label: this.$t('layouts.default.drawer.menu.pending'),
          permissions: 'doctor/photos/pending',
          isBagdeOn: true,
        },
        {
          path: this.$getLocalizedPath({ name: 'AppointmentsIndex' }),
          icon: 'event',
          label: this.$t('layouts.default.drawer.menu.appointments'),
          permissions: 'doctor/appointments/list',
        },
        {
          path: this.$getLocalizedPath({ name: 'Glossary' }),
          icon: 'list',
          label: this.$t('layouts.default.drawer.menu.glossary'),
          permissions: 'doctor/glossary/list',
        },
      ];
    },
    allowedMenuItems() {
      return this.menuItems.filter((menuItem) =>
        this.$isAllowed(menuItem.permissions)
      );
    },
  },
  async created() {
    this.getAccountInfo();

    this.fetchStats();

    this.fetchMessages();

    await this.$nextTick();

    activateWidget(this.accountInfo);

    if (this.$route.params.patientId) {
      await this.$nextTick();

      updateWidget({ patient_id: this.$route.params.patientId });
    }

    this.timerId = setInterval(() => {
      this.fetchStats();
      this.fetchMessages();
    }, process.env.VUE_APP_REFRESH_INTERVAL);
  },
  beforeUnmount() {
    clearInterval(this.timerId);
  },
  watch: {
    accountInfo: {
      handler(val) {
        updateWidget({
          language_override: val.user.locale,
          country: val.user.country,
          name: val.user.fullName,
          user_id: val.user.id,
          email: val.user.email,
          dealer: val.dealer.userId,
          dealer_name: val.dealer.user.fullName,
          credits: val.balance,
          exams: val.stats.examsCount,
          pending_photos: val.stats.pendingPhotos,
          subjects: val.stats.patientsCount,
          latest_exam: this.$dayjs(val.stats.recentExamAt).unix(),
          created_at: this.$dayjs(val.user.createdAt).unix(),
        });
      },
      deep: true,
    },
    $route: {
      handler(val, oldVal) {
        this.getAccountInfo();

        if (val?.params?.patientId === oldVal?.params?.patientId) return;

        updateWidget({ patient_id: val.params.patientId });
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      signOut: 'account/signOut',
      getAccountInfo: 'account/getAccountInfo',
    }),
    ...mapMutations({
      setRecent: 'stats/setRecent',
      setPendingPhotos: 'stats/setPendingPhotos',
    }),
    async fetchStats() {
      try {
        const { pendingPhotos } = await getStats();

        this.setPendingPhotos(pendingPhotos);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchMessages() {
      const data = await chat.recent();

      this.setRecent(data);
    },
    confirmSignOut() {
      this.$q
        .dialog({
          title: this.$t('layouts.default.signOutModal.title'),
          message: this.$t('layouts.default.signOutModal.message'),
          ok: this.$t('layouts.default.signOutModal.okButtonTitle'),
          cancel: this.$t('layouts.default.signOutModal.cancelButtonTitle'),
        })
        .onOk(() => this.signOut());
    },
  },
};
</script>
