import { render } from "./Dashboard.vue?vue&type=template&id=ebd63be8"
import script from "./Dashboard.vue?vue&type=script&lang=js"
export * from "./Dashboard.vue?vue&type=script&lang=js"
script.render = render

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QCardSection,QIcon});
