import { render } from "./ExamNotes.vue?vue&type=template&id=231f7ad4"
import script from "./ExamNotes.vue?vue&type=script&lang=js"
export * from "./ExamNotes.vue?vue&type=script&lang=js"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QInput});
