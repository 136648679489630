<template>
  <div>
    <div class="q-pl-xs justify-center">
      <q-virtual-scroll
        :items="messages"
        style="max-height: 500px"
        class="full-width q-pr-lg"
        ref="scroll"
      >
        <template v-slot="{ item }">
          <q-chat-message
            v-if="item.senderId === account.user.id"
            :key="item.id"
            name="me"
            :text="[item.content]"
            :stamp="$dayjs(item.createdAt).fromNow()"
            sent
            bg-color="secondary"
            class="q-mb-none q-pb-sm"
          >
            <template v-slot:avatar>
              <q-avatar
                class="q-mx-sm"
                text-color="white"
                :style="{ backgroundColor: account.user.avatar.color }"
              >
                {{ account.user.avatar.abbr }}
              </q-avatar>
            </template>
          </q-chat-message>

          <q-chat-message
            v-if="item.recipientId === account.user.id"
            :key="item.id"
            :name="patient.user.fullName"
            :text="[item.content]"
            :stamp="$dayjs(item.createdAt).fromNow()"
            text-color="white"
            bg-color="primary"
            class="q-mb-none q-pb-sm"
          >
            <template v-slot:avatar>
              <q-avatar
                class="q-mx-sm"
                text-color="white"
                :style="{ backgroundColor: account.user.avatar.color }"
              >
                {{ patient.user.avatar.abbr }}
              </q-avatar>
            </template>
          </q-chat-message>
        </template>
      </q-virtual-scroll>
    </div>
    <q-separator />
    <div class="full-width">
      <div class="row items-center">
        <q-input
          ref="input"
          v-model.trim="message"
          :placeholder="$t('components.chatFab.typeMessage')"
          :disable="isSending"
          autofocus
          dense
          borderless
          class="col q-my-xs q-mx-sm"
          @keyup.enter="send"
        />
        <q-separator vertical />
        <q-btn
          dense
          text-color="primary"
          :disable="!message?.length || isSending"
          flat
          icon="las la-paper-plane"
          class="q-my-xs"
          @click="send"
        ></q-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from '@vuelidate/validators';
import { mapGetters, mapMutations } from 'vuex';
import { chat } from '@/api/chat';
import _ from 'lodash';

export default {
  components: {},
  props: {
    patient: {
      required,
      Object,
    },
  },
  data() {
    return {
      visible: false,
      error: null,
      isLoading: false,
      messages: [],
      message: null,
      timerId: null,
      scrollTo: 0,
      isSending: false,
    };
  },
  computed: {
    ...mapGetters({
      account: 'account/info',
      recent: 'chat/recent',
    }),
  },
  created() {
    this.fetchFeed(true);

    this.timerId = setInterval(() => {
      this.fetchFeed();
    }, process.env.VUE_APP_REFRESH_INTERVAL);

    this.visible = true;
  },
  beforeUnmount() {
    clearInterval(this.timerId);
  },
  watch: {
    patient() {
      this.messages = [];

      clearInterval(this.timerId);

      this.fetchFeed(true);

      this.timerId = setInterval(() => {
        this.fetchFeed();
      }, process.env.VUE_APP_REFRESH_INTERVAL);
    },
    visible(value) {
      if (value) {
        this.read();
        this.$refs.input.focus();

        this.$refs.scroll.scrollTo(this.scrollTo);
      }
    },
    scrollTo(value) {
      if (this.visible) {
        this.$refs.scroll.scrollTo(value);
      }
    },
  },
  methods: {
    ...mapMutations({
      setRecent: 'chat/setRecent',
    }),
    show() {
      this.visible = true;
    },
    async fetchFeed(scroll = false) {
      try {
        this.isLoading = true;
        const since = _.last(this.messages)?.createdAt;
        const fetched = await chat.feed({
          userId: this.patient.userId,
          filters: {
            since: since,
          },
          offset: 0,
          limit: -1,
        });

        this.messages.push(
          ...fetched
            .filter(
              (item) =>
                this.messages.findIndex((message) => message.id === item.id) ===
                -1
            )
            .reverse()
        );

        if (scroll) {
          this.scrollTo = this.messages.length - 1;
        }

        if (this.visible) {
          await this.read();
        }
      } catch (e) {
        this.error = e;
      } finally {
        this.isLoading = false;
      }
    },
    async read() {
      this.scrollTo = this.messages.length - 1;

      this.setRecent(
        await chat.read({
          patientId: this.patient.userId,
        })
      );
    },
    async send() {
      try {
        this.isSending = true;

        const sent = await chat.send({
          message: {
            recipientId: this.patient.userId,
            content: this.message,
          },
        });

        if (
          this.messages.findIndex((message) => message.id === sent.id) === -1
        ) {
          this.messages.push(sent);
        }

        this.message = null;
        this.$refs.input.focus();
      } catch (error) {
        console.log(error);
      } finally {
        this.isSending = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.q-virtual-scroll__content) {
  max-width: 70%;
  margin: 0 auto;

  @media (max-width: 840px) {
    max-width: 100%;
  }
}
</style>
