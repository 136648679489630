<template>
  <q-dialog v-model="isVisible">
    <q-card style="width: 400px; max-width: 80vw">
      <q-form
        autocorrect="off"
        autocapitalize="off"
        autocomplete="off"
        spellcheck="false"
        @submit="onSubmit"
      >
        <q-card-section
          class="row items-center justify-between bg-primary text-white"
        >
          <span class="text-h6">
            {{
              isEditing
                ? $t('components.appointmentModal.updateTitle')
                : $t('components.appointmentModal.addTitle')
            }}
          </span>

          <q-btn
            :disable="isLoading"
            icon="close"
            flat
            round
            dense
            v-close-popup
          />
        </q-card-section>

        <q-separator />

        <q-card-section v-if="error" class="q-pt-none">
          <q-banner class="text-white bg-red" rounded>
            {{ error.message }}
          </q-banner>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <div class="row q-col-gutter-md">
            <div class="col-12">
              <q-input
                v-model.trim="appointment.appointedFor"
                :label="$t('components.appointmentModal.labels.appointedFor')"
                :error="v$.appointment.appointedFor.$error"
                :error-message="
                  $t('components.appointmentModal.errors.appointedFor')
                "
                mask="####-##-## ##:##:##"
                autofocus
                fill-mask
                hide-bottom-space
              >
                <template v-slot:prepend>
                  <q-btn icon="event" class="cursor-pointer" round flat>
                    <q-popup-proxy
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date
                        v-model="appointment.appointedFor"
                        mask="YYYY-MM-DD HH:mm:ss"
                      >
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            :label="
                              $t('components.datePicker.closeButtonTitle')
                            "
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-btn>
                </template>

                <template v-slot:append>
                  <q-btn icon="access_time" class="cursor-pointer" round flat>
                    <q-popup-proxy
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-time
                        v-model="appointment.appointedFor"
                        mask="YYYY-MM-DD HH:mm:ss"
                        format24h
                      >
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            :label="
                              $t('components.datePicker.closeButtonTitle')
                            "
                            color="primary"
                            flat
                          />
                        </div>
                      </q-time>
                    </q-popup-proxy>
                  </q-btn>
                </template>
              </q-input>
            </div>

            <div class="col-12">
              <q-input
                v-model.trim="appointment.notes"
                :label="$t('components.appointmentModal.labels.notes')"
                :error="v$.appointment.notes.$error"
                :error-message="$t('components.appointmentModal.errors.notes')"
                type="textarea"
              />
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn :disable="isLoading" flat @click="close">
            {{ $t('components.appointmentModal.cancelButtonTitle') }}
          </q-btn>

          <q-btn
            :loading="isLoading"
            :label="
              isEditing
                ? $t('components.appointmentModal.updateButtonTitle')
                : $t('components.appointmentModal.addButtonTitle')
            "
            type="submit"
            color="primary"
            flat
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import _ from 'lodash';
import useValidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import { addAppointment, updateAppointment } from '@/api/appointments';

export default {
  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      v$: useValidate(),
      isVisible: false,
      isLoading: false,
      error: null,
      appointment: {
        patientId: this.patientId,
        appointedFor: null,
        notes: null,
      },
    };
  },
  validations() {
    return {
      appointment: {
        patientId: {
          required,
        },
        appointedFor: {
          required,
        },
        notes: {
          required,
        },
      },
    };
  },
  computed: {
    isEditing() {
      return typeof this.appointment?.id === 'number';
    },
  },
  methods: {
    async open(appointment) {
      this.error = null;

      if (appointment) {
        this.appointment = _.cloneDeep(appointment);
      } else {
        this.clearAppointment();
      }

      this.v$.$reset();

      this.isLoading = false;
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    clearAppointment() {
      this.appointment = {
        patientId: this.patientId,
        appointedFor: null,
        notes: null,
      };
    },
    async onSubmit() {
      this.error = null;

      this.v$.$touch();

      if (!this.v$.$invalid) {
        try {
          this.isLoading = true;

          if (this.isEditing) {
            const appointment = await updateAppointment(this.appointment);

            this.$emit('appointment-updated', appointment);
          } else {
            const appointment = await addAppointment(this.appointment);

            this.$emit('appointment-added', appointment);
          }

          this.close();
        } catch (error) {
          this.error = error;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
