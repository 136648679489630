<template>
  <q-btn :disable="!email" outline color="primary" @click="onContactUs">
    {{ $t('components.contactUsButton.buttonTitle') }}
  </q-btn>
</template>

<script>
import { getAccount } from '@/api/account';

export default {
  components: {},
  props: {
    subject: String,
  },
  data() {
    return {
      email: null,
    };
  },
  computed: {},
  async created() {
    const doctor = await getAccount();
    this.email = doctor.dealer?.user.contactEmail ?? doctor.dealer?.user.email;
  },
  watch() {},
  methods: {
    onContactUs() {
      window.Intercom('showSpace', 'messages');
    },
  },
};
</script>

<style lang="sass" scoped></style>
