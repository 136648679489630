import { render } from "./AccountProfileTab.vue?vue&type=template&id=4cd102ca"
import script from "./AccountProfileTab.vue?vue&type=script&lang=js"
export * from "./AccountProfileTab.vue?vue&type=script&lang=js"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QBanner,QInput,QSelect});
