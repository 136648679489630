<template>
  <q-dialog v-model="isVisible">
    <q-card style="width: 400px; max-width: 80vw">
      <q-form
        autocorrect="off"
        autocapitalize="off"
        autocomplete="off"
        spellcheck="false"
        @submit="onSubmit"
      >
        <q-card-section
          class="row items-center justify-between bg-primary text-white"
        >
          <span class="text-h6">
            {{
              $t('components.moveModal.title', {
                patient: this.patient.user.fullName,
              })
            }}
          </span>

          <q-btn
            :disable="isLoading"
            icon="close"
            flat
            round
            dense
            v-close-popup
          />
        </q-card-section>

        <q-separator />

        <q-card-section v-if="error">
          <q-banner class="text-white bg-red" rounded>
            {{ error.message }}
          </q-banner>
        </q-card-section>

        <q-card-section class="q-pt-none q-mt-sm">
          <div class="row q-col-gutter-md">
            <div class="col-12">
              <q-select
                v-model="folderId"
                :options="folders"
                :label="$t('components.moveModal.labels.folder')"
                :hint="$t('components.moveModal.hints.folder')"
                :error="v$.folderId.$error"
                :error-message="$t('components.moveModal.errors.folder')"
                input-debounce="0"
                use-input
                hide-selected
                fill-input
                emit-value
                map-options
                hide-bottom-space
                autofocus
                @filter="searchFolders"
              >
                <template v-slot:no-option="{ inputValue }">
                  <q-item
                    v-if="
                      typeof inputValue === 'string' && inputValue.length >= 1
                    "
                  >
                    <q-item-section class="text-grey">
                      {{ $t('components.moveModal.noResults') }}
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
          </div>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn
            :loading="isLoading"
            :label="$t('components.moveModal.moveButtonTitle')"
            type="submit"
            color="primary"
            flat
          />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>
import _ from 'lodash';
import useValidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

import { updatePatient } from '@/api/patients';
import { getFoldersAndCount } from '@/api/folders';

export default {
  data() {
    return {
      v$: useValidate(),
      isVisible: false,
      isLoading: false,
      error: null,
      folderId: null,
      patient: null,
      folders: null,
    };
  },
  validations: {
    folderId: {
      required,
    },
  },
  methods: {
    open(patient) {
      this.error = null;
      this.folderId = null;

      this.patient = _.cloneDeep(patient);

      this.v$.$reset();

      this.isLoading = false;
      this.isVisible = true;
    },
    close() {
      this.isVisible = false;
    },
    async onSubmit() {
      this.error = null;

      this.v$.$touch();

      if (!this.v$.$invalid) {
        try {
          this.isLoading = true;

          const patient = await updatePatient({
            ...this.patient,
            folderId: this.folderId,
          });

          this.$emit('moved', patient);

          this.close();
        } catch (error) {
          this.error = error;
        } finally {
          this.isLoading = false;
        }
      }
    },
    async searchFolders(value, update) {
      if (typeof value === 'string' && value.length >= 1) {
        try {
          const { folders } = await getFoldersAndCount({
            filters: {
              query: value,
            },
          });

          update(() => {
            this.folders = folders.map((folder) => {
              return {
                value: folder.id,
                label: folder.title,
              };
            });
          });
        } catch (error) {
          this.error = error;
        }
      } else {
        update(() => {
          this.folders = [];
        });
      }
    },
  },
};
</script>
