import { render } from "./RecentPatients.vue?vue&type=template&id=803dbe6a"
import script from "./RecentPatients.vue?vue&type=script&lang=js"
export * from "./RecentPatients.vue?vue&type=script&lang=js"
script.render = render

export default script
import QTable from 'quasar/src/components/table/QTable.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTable,QTd,QAvatar});
