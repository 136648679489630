import axios from '@/helpers/axios';
import i18n from '@/helpers/i18n';

export function formulas() {
  return axios
    .post('/doctor/misc/formulas', {
      language: i18n.locale,
    })
    .then(({ data }) => {
      return data.results;
    });
}
