export default {
  install: (app) => {
    app.mixin({
      created() {
        if (typeof this.$options.meta === 'function') {
          const meta = this.$options.meta.call(this);

          if (typeof meta.title === 'string') {
            document.title =
              meta.title +
              (meta.hideAppName ? ' - Pixelcando' : ' - Doctor | Pixelcando');
          }
        }
      },
    });
  },
};
