import { render } from "./PatientChat.vue?vue&type=template&id=0122fe54&scoped=true"
import script from "./PatientChat.vue?vue&type=script&lang=js"
export * from "./PatientChat.vue?vue&type=script&lang=js"

import "./PatientChat.vue?vue&type=style&index=0&id=0122fe54&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-0122fe54"

export default script
import QVirtualScroll from 'quasar/src/components/virtual-scroll/QVirtualScroll.js';
import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QVirtualScroll,QChatMessage,QAvatar,QSeparator,QInput,QBtn});
