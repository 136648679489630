import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import customParse from 'dayjs/plugin/customParseFormat';

import 'dayjs/locale/en';
import 'dayjs/locale/it';
import 'dayjs/locale/es';
import 'dayjs/locale/de';

dayjs.extend(localizedFormat);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(customParse);
dayjs.locale('it');

const config = {
  formats: {
    LT: 'HH:mm',
    L: 'DD/MM/YY',
  },
};

dayjs.updateLocale('en', config);
dayjs.updateLocale('it', config);
dayjs.updateLocale('es', config);
dayjs.updateLocale('de', config);

export default dayjs;
