const chat = {
  namespaced: true,
  state: {
    totalCount: 0,
    messages: [],
  },
  getters: {
    totalCount: (state) => state.totalCount,
    recent:
      (state) =>
      (id = null) => {
        if (id) {
          return state.messages.find((item) => +item.senderId === +id);
        }
        return state.messages;
      },
  },
  mutations: {
    setRecent(state, payload) {
      state.totalCount = payload.totalCount;
      state.messages = payload.messages;
    },
  },
};

export { chat };
