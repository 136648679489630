<template>
  <div class="row justify-center q-mb-lg">
    <swiper
      :slides-per-view="1"
      :space-between="16"
      :breakpoints="{
        600: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        1440: {
          slidesPerView: 4,
        },
      }"
      class="col-12 q-px-xl q-py-md cursor-pointer"
    >
      <swiper-slide v-for="exam of exams" :key="exam.id">
        <q-card
          :class="{
            'bg-blue-grey-1': exam.id === currentExamId,
          }"
          flat
          bordered
          @click="openExamPage(exam.id)"
        >
          <q-card-section class="row items-center q-pt-sm">
            <div class="text-h5">
              {{ exam.no }}
            </div>

            <q-space />

            <q-btn
              :color="
                modelValue.find((item) => item.id === exam.id)
                  ? 'primary'
                  : 'grey-14'
              "
              icon="compare_arrows"
              :flat="!modelValue.find((item) => item.id === exam.id)"
              round
              @click.stop="selectExamIdForComparison(exam)"
            />
          </q-card-section>

          <q-card-section>
            <div class="text-weight-medium">
              {{ $dayjs(exam.createdAt).format('L LT') }}
            </div>
            <div>
              {{ $dayjs(exam.createdAt).fromNow() }}
            </div>
          </q-card-section>
        </q-card>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper.min.css';

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    exams: {
      type: Array,
      required: true,
    },
    currentExamId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    selectExamIdForComparison(exam) {
      if (
        this.modelValue.length < 2 &&
        !this.modelValue.find((item) => item.id === exam.id)
      ) {
        this.$emit('update:modelValue', [...this.modelValue, exam]);
      } else if (this.modelValue.find((item) => item.id === exam.id)) {
        this.$emit(
          'update:modelValue',
          this.modelValue.filter((item) => item.id !== exam.id)
        );
      }
    },
    openExamPage(examId) {
      if (this.$isAllowed('doctor/exams/get')) {
        this.$router.push(
          this.$getLocalizedPath(
            `/folders/${this.$route.params.folderId}/patients/${this.$route.params.patientId}/exams/${examId}`
          )
        );
      }
    },
  },
};
</script>
