<template>
  <breadcrumbs v-if="breadcrumbs" :items="breadcrumbs" />

  <q-card flat bordered>
    <q-card-section class="row items-center justify-between wrap q-pb-none">
      <span class="text-h5 q-mb-md">
        {{ $t('pages.examsCompare.title') }}
      </span>
    </q-card-section>

    <q-card-section v-if="error">
      <q-banner className="text-white bg-red q-mb-md" rounded>
        {{ error.message }}
      </q-banner>
    </q-card-section>

    <q-separator />

    <q-card-section v-if="exams?.length">
      <div class="row q-col-gutter-x-md q-mb-md items-start text-subtitle1">
        <div
          v-for="exam of exams"
          :key="exam.id"
          class="col-12 col-sm-4 q-mb-md"
        >
          <div>
            <div class="text-weight-bold">
              {{ $t('pages.examsCompare.silhouettes.exam.title') }} #{{
                exam?.no
              }}
            </div>
            <div>
              {{ $dayjs(exam?.createdAt).format('L LT') }}
            </div>
            <div>
              {{
                $t('pages.examsCompare.silhouettes.exam.abdominalFm', {
                  value: $numeral(exam.params?.abdominalFm).format('0,0.0'),
                  unit: units.abdominalFm,
                })
              }}
            </div>
            <div>
              {{
                $t('pages.examsCompare.silhouettes.exam.waistToHeight', {
                  value: $numeral(exam.params?.waistToHeight).format('0,0.0'),
                  unit: units.waistToHeight,
                })
              }}
            </div>
          </div>
          <q-img :src="exam?.silhouette"></q-img>
        </div>

        <div class="col-12 col-sm-4 q-mb-md">
          <div>
            <div class="text-weight-bold">
              {{ $t('pages.examsCompare.silhouettes.comparative.title') }}
            </div>
            <div class="row">
              <div class="col-6">
                <q-badge label="" color="silhouette-before" />
                {{ $t('pages.examsCompare.silhouettes.before') }}
              </div>
              <div class="col-6">
                <q-badge label="" color="silhouette-after" />
                {{ $t('pages.examsCompare.silhouettes.after') }}
              </div>
            </div>
            <div>
              {{
                $t('pages.examsCompare.silhouettes.exam.abdominalFm', {
                  value: $numeral(
                    exams[1].params?.abdominalFm - exams[0].params?.abdominalFm
                  ).format('0,0.0'),
                  unit: units.abdominalFm,
                })
              }}
            </div>
            <div>
              {{
                $t('pages.examsCompare.silhouettes.exam.waistToHeight', {
                  value: $numeral(
                    exams[1].params?.waistToHeight -
                      exams[0].params?.waistToHeight
                  ).format('0,0.0'),
                  unit: units.waistToHeight,
                })
              }}
            </div>
          </div>
          <div v-if="comparisonImage" class="col-12 col-sm-4 q-mb-md items-end">
            <q-img :src="comparisonImage"></q-img>
          </div>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import { mapGetters } from 'vuex';

import { getPatientById } from '@/api/patients';
import { getComparisonImage, getExamById } from '@/api/exams';
import Breadcrumbs from '@/components/Breadcrumbs.vue';

export default {
  components: {
    Breadcrumbs,
  },
  meta() {
    return {
      title: this.$t('pages.examsCompare.documentTitle'),
    };
  },
  data() {
    return {
      error: null,
      patient: null,
      exams: [{}, {}],
      comparisonImage: null,
      isLoading: null,
    };
  },
  computed: {
    ...mapGetters({
      units: 'account/units',
    }),
    breadcrumbs() {
      return [
        {
          to: this.$getLocalizedPath({ name: 'Dashboard' }),
          label: this.$t('breadcrumbs.dashboard'),
        },
        {
          to: this.$getLocalizedPath({ name: 'FoldersIndex' }),
          label: this.$t('breadcrumbs.folders'),
        },
        {
          to: this.$getLocalizedPath({
            name: 'PatientsIndex',
            params: { ':folderId': this.patient?.folderId },
          }),
          label: this.patient?.folder.title,
        },
        {
          to: this.$getLocalizedPath({
            name: 'PatientsShow',
            params: {
              ':folderId': this.patient?.folderId,
              ':patientId': this.patient?.userId,
            },
          }),
          label: this.patient?.user.fullName,
        },
        {
          label: this.$t('breadcrumbs.examsCompare'),
        },
      ];
    },
  },
  mounted() {
    this.fetchData();
    this.getComparisonImage();
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true;
        this.error = null;

        this.patient = (
          await getPatientById(+this.$route.params.patientId)
        ).patient;
        this.exams = [
          await getExamById(+this.$route.query.first),
          await getExamById(+this.$route.query.second),
        ];
      } catch (error) {
        this.error = error;
      } finally {
        this.isLoading = false;
      }
    },
    async getComparisonImage() {
      try {
        this.comparisonImage = await getComparisonImage(
          +this.$route.query.first,
          +this.$route.query.second
        );
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>
