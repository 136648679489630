import axios from '@/helpers/axios';

export function uploadPhoto(options) {
  return axios.post('/doctor/photos/upload', options).then(({ data }) => {
    return data.exam;
  });
}

export function approvePhotoById(id) {
  return axios.post('/doctor/photos/confirm', {
    id,
  });
}

export function rejectPhoto({ id, reason }) {
  return axios.post('/doctor/photos/reject', {
    id,
    reason,
  });
}

export function getPendingPhotosAndCount() {
  return axios.post('/doctor/photos/pending').then((data) => data.data);
}
