<template>
  <q-table
    :columns="tableColumns"
    :rows="patients"
    :no-data-label="$t('components.recentPatients.noItems')"
    :pagination="{ rowsPerPage: 0 }"
    row-key="userId"
    hide-bottom
    @row-click="onRowClick"
  >
    <template v-slot:body-cell-name="props">
      <q-td>
        <div class="row items-center">
          <q-avatar
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :style="{ backgroundColor: props.row.user.avatar.color }"
          >
            {{ props.row.user.avatar.abbr }}
          </q-avatar>

          <span> {{ props.row.user.fullName }}</span>
        </div>
      </q-td>
    </template>
  </q-table>
</template>

<script>
export default {
  props: {
    patients: {
      type: Array,
      required: true,
    },
  },
  computed: {
    tableColumns() {
      return [
        {
          name: 'name',
          field: (row) => row.user.fullName,
          label: this.$t('components.recentPatients.tableColumns.name'),
          align: 'left',
        },
        {
          name: 'lastSeenAt',
          field: 'viewedAt',
          format: (value) => this.$dayjs(value).format('L LT'),
          label: this.$t('components.recentPatients.tableColumns.lastSeenAt'),
          align: 'right',
        },
      ];
    },
  },
  methods: {
    onRowClick(event, patient) {
      this.openPatientExamsPage(patient);
    },
    openPatientExamsPage(patient) {
      if (
        this.$isAllowed('doctor/patients/get') &&
        this.$isAllowed('doctor/exams/list')
      ) {
        this.$router.push(
          this.$getLocalizedPath(
            `/folders/${patient.folderId}/patients/${patient.userId}`
          )
        );
      }
    },
  },
};
</script>
