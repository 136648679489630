import router from '@/router';
import getLocalizedPath from '@/helpers/getLocalizedPath';

import { signIn, getAccount } from '@/api/account';

const state = {
  account: JSON.parse(window.localStorage.getItem('pixelcando-doctor')),
};

const getters = {
  info: (state) => state.account,
  units: (state) => state.account?.units ?? {},
  countries: (state) =>
    state.account?.user.country ? [state.account.user.country] : null,
  accessToken: (state) => state.account?.user?.accessToken,
  permissions: (state) => state.account?.permissions,
};

const mutations = {
  setAccount(state, account) {
    window.localStorage.setItem('pixelcando-doctor', JSON.stringify(account));

    state.account = account;
  },
  updateAccount(state, account) {
    window.localStorage.setItem(
      'pixelcando-doctor',
      JSON.stringify({
        ...state.account,
        ...account,
        user: {
          ...state.account.user,
          ...account.user,
        },
      })
    );

    state.account = {
      ...state.account,
      ...account,
      user: {
        ...state.account.user,
        ...account.user,
      },
    };
  },
};

const actions = {
  signIn({ commit }, { email, password }) {
    return signIn({ email, password }).then((account) => {
      commit('setAccount', account);

      router.push(getLocalizedPath({ name: 'Dashboard' }));
    });
  },
  signOut({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        commit('setAccount', null);

        if (window.Intercom) {
          window.Intercom('shutdown');
        }

        router.push(getLocalizedPath({ name: 'SignIn' }));

        resolve();
      } catch (error) {
        reject(error);
      }
    });
  },
  getAccountInfo({ commit }) {
    return getAccount().then((account) => {
      commit('updateAccount', account);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
