import { createApp } from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';;

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import quasarUserOptions from '@/quasar-user-options';
import i18n from '@/helpers/i18n';
import getLocalizedPath from '@/plugins/getLocalizedPath';
import numeral from '@/plugins/numeral';
import dayjs from '@/plugins/dayjs';
import isAllowed from '@/plugins/isAllowed';
import meta from '@/plugins/meta';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import BugsnagPerformance from '@bugsnag/browser-performance';
import VuePersianDatetimePicker from 'vue3-persian-datetime-picker';
import getCssVar from 'quasar/src/utils/get-css-var.js';;

Bugsnag.start({
  apiKey: '697ea5934e3b153aa7b6ba67586bb541',
  plugins: [new BugsnagPluginVue()],
  releaseStage: process.env.BUGSNAG_RELEASE_STAGE,
});

BugsnagPerformance.start({ apiKey: '697ea5934e3b153aa7b6ba67586bb541' });
const bugsnagVue = Bugsnag.getPlugin('vue');

createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(VuePersianDatetimePicker, {
    name: 'custom-date-picker',
    props: {
      format: 'YYYY',
      displayFormat: 'YYYY',
      clearable: true,
      altFormat: 'YYYY',
      color: getCssVar('primary'),
      autoSubmit: true,
    },
  })
  .use(store)
  .use(router)
  .use(i18n)
  .use(getLocalizedPath)
  .use(numeral)
  .use(dayjs)
  .use(isAllowed)
  .use(meta)
  .use(bugsnagVue)
  .mount('#app');
