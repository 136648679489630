<template>
  <component :is="currentLayoutName" />
</template>

<script>
import DefaultLayout from '@/layouts/Default.vue';
import SignInLayout from '@/layouts/SignIn.vue';
import PrintLayout from '@/layouts/Print.vue';
import '@/styles/quasar.scss';

export default {
  components: {
    DefaultLayout,
    SignInLayout,
    PrintLayout,
  },
  computed: {
    currentLayoutName() {
      if (typeof this.$route.href === 'string') {
        if (typeof this.$route.meta?.layoutName === 'string') {
          return `${this.$route.meta.layoutName}Layout`;
        }

        return 'DefaultLayout';
      }

      return null;
    },
  },
};
</script>
